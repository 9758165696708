import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

//components
import StickyVerticalSliderCard from "./StickyVerticalSliderCard";
import { StickySliderMobileCard } from "../../components/NewsCards";
import Container from "../../components/containers/Container";

// Helpers
import { useWindowSize } from "../../components/hooks/useWindowSize";
import { BodyLockContext } from "../../components/contexts/BodyLockContext";
import { withVisibility } from "../../components/hooks/withVisibility";
import { useScroll } from "@use-gesture/react";
import { cardContent } from "../../../marbet/src/utils/content/stickySliderContent";

const SliderWrapper = styled.div`
  height: 90vh;
  position: relative;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const CardCounterContainer = styled.div`
  position: sticky;
  margin-left: 20px;
  max-height: 450px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StickyVerticalSlider = ({ content, isVisible }) => {
  const [dir, setDirection] = useState(1);
  const [slideNumber, setSlideNumber] = useState(0);
  const [bodyLock, setBodyLock] = useContext(BodyLockContext);
  const size = useWindowSize();
  const { verticalSlider } = content;
  const stickySlider = useRef();

  const bind = useScroll(({ direction }) =>
    direction[1] !== dir ? setDirection(direction[1]) : null
  );

  const onSlide = (isVisible, index) => {
    if (isVisible) {
      setSlideNumber(index);
    }

    if (
      isVisible &&
      bodyLock &&
      index === cardContent.length - 1 &&
      dir === 1
    ) {
      setTimeout(() => setBodyLock(false), 200);
    }

    if (isVisible && bodyLock && index === 0 && dir === -1) {
      setTimeout(() => setBodyLock(false), 200);
    }
  };

  const handleScroll = (index) => {
    var sliderHeight = stickySlider.current.scrollHeight;
    var slideHeight = sliderHeight / 3;
    var scrollAims = [0, slideHeight, slideHeight * 2];

    stickySlider.current.scrollTo({
      top: scrollAims[index],
      behavior: "smooth",
    });
  };

  useEffect(() => {
    return () => setBodyLock(false);
  }, [isVisible]);

  return (
    <Container type="wide" id="sticked" className="relative py-10">
      {size.width <= 768 ? (
        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-y-28 lg:gap-x-20 xl:gap-x-40">
          <div className="flex flex-col gap-y-14 lg:gap-y-40">
            {verticalSlider.map((card, index) => {
              return <StickySliderMobileCard data={card} key={index} />;
            })}
          </div>
        </div>
      ) : (
        <SliderWrapper
          {...bind()}
          ref={stickySlider}
          className="overflow-hidden rounded-lg"
        >
          <CardCounterContainer
            className="top-1/2 -translate-y-1/2 max-w-min h-2/3"
            size={size}
          >
            {verticalSlider.map((card, index) => (
              <div
                className={`border-b-3 border-highlight${
                  slideNumber === index ? "/100" : "/0"
                } -rotate-90 pb-1 max-w-min cursor-pointer`}
                key={index}
                onClick={() => handleScroll(index)}
              >
                <p
                  className={
                    "text-p2 text-white font-bold uppercase transition " +
                    (slideNumber !== index ? "opacity-50" : "")
                  }
                >
                  {card.headline}
                </p>
              </div>
            ))}
          </CardCounterContainer>
          {verticalSlider.map((card, index) => {
            return (
              <StickyVerticalSliderCard
                setBodyLock={setBodyLock}
                data={card}
                onSlide={onSlide}
                index={index}
                key={index}
              />
            );
          })}
        </SliderWrapper>
      )}
    </Container>
  );
};

StickyVerticalSlider.propTypes = {
  content: PropTypes.shape({
    verticalSlider: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string,
        subline: PropTypes.string,
        linkName: PropTypes.string,
        linkAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        background: PropTypes.array,
      })
    ),
  }),
};

export default withVisibility(StickyVerticalSlider, 0, false);
