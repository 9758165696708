import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useWindowSize } from "../../components/hooks/useWindowSize"

// Components
import Container from "../../components/containers/Container"
import TextLink from "../../components/TextLink"
import Button from "../../components/buttons/Button"
import ArrowRight from "../../components/icons/ArrowRight"
import LocationIcon from "../../components/icons/LocationIcon"
import ClockIcon from "../../components/icons/ClockIcon"
import { BarSpace } from "../../components/BarSpace"
import RichTextMedia from "../RichTextMedia/RichTextMedia"
import { navigateTo } from "../../components/LinkChoice"

const JobContent = styled.div`
  h6 {
    color: #262626;
    font-weight: 700;
  }

  p,
  li {
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`

const JobHeader = ({ job }) => {
  const { title, jobOffices, jobType, linkName, linkAddress, redactor } = job
  const size = useWindowSize()

  return (
    <Container type={size.width < 1024 ? "wide" : "normal"}>
      <BarSpace />

      <Container type={size.width < 1024 ? "wide" : "normal"} fullMobile fullMidscreen>
        <div className="flex justify-between items-center mb-5 md:mb-20">
          <TextLink className="text-highlight" text="Zurück zu allen Jobs" link={"/career/jobs"} goBack />
          {size.width > 1024 && <Button label={linkName} action={() => navigateTo(linkAddress)} iconSuffix={<ArrowRight />} />}
        </div>
        <div className="flex flex-col gap-x-4 mb-6">
          <p className="text-highlight text-base md:text-h7 font-serif italic">(m/w/d)</p>
          <h1 className="text-h5 md:text-h2 xl:text-h1">{title}</h1>
        </div>

        <div className="flex flex-col gap-y-3">
          <div className="flex">
            <LocationIcon className="text-highlight mr-2" width={20} height={20} />
            <p className="text-p3 text-gray-g3">{jobOffices.map((office, i) => (i + 1 === jobOffices.length ? office.title : office.title + ", "))}</p>
          </div>
          {jobType[0] !== " " && (
            <div className="flex">
              <ClockIcon className="text-highlight mr-2" width={20} height={20} />
              <p className="text-p3 text-gray-g3">{jobType.join(" | ")}</p>
            </div>
          )}
          {size.width < 1024 && (
            <Button
              className="mt-6 justify-self-start sm:max-w-max"
              label={linkName}
              action={() => navigateTo(linkAddress)}
              iconSuffix={<ArrowRight />}
            />
          )}
        </div>
        <BarSpace />
        {/* <p className="text-base md:text-h7 font-bold mb-10 md:mb-20">{descriptionHeader}</p> */}
      </Container>
      <JobContent>
        <RichTextMedia fullMobile={size.width < 768} fullMidscreen content={redactor} />
      </JobContent>
    </Container>
  )
}

JobHeader.propTypes = {
  job: PropTypes.object.isRequired,
}

export default JobHeader
