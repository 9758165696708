import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';

export const navigateTo = (path) => {
  const customPath = typeof path === 'string' ? path : path?.url;

  const isExternal =
    customPath?.startsWith('http') || customPath?.startsWith('mailto');

  if (customPath) {
    isExternal
      ? (window.location.href = customPath)
      : navigate('/' + location.pathname.split('/')[1] + customPath);
  }
};

const LinkChoice = ({ to, children, ...props }) => {
  const location = useLocation();
  const linkTo = typeof to === 'string' ? to : to?.url;
  if (!linkTo) return null;

  const isExternal = linkTo.startsWith('http') || linkTo.startsWith('mailto');

  if (isExternal) {
    return (
      <a href={linkTo} target='_blank' rel='noreferrer' {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={'/' + location.pathname.split('/')[1] + linkTo} {...props}>
        {children}
      </Link>
    );
  }
};

export default LinkChoice;
