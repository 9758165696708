import React from "react"
import PropTypes from "prop-types"

function ClockIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M10 17.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm.417-6.034V5h-.834v5.2L5.998 8.656l-.33.765 4.749 2.046z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

ClockIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

ClockIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default ClockIcon
