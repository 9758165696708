import React from "react"
import PropTypes from "prop-types"

function LinkedIn({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M1.917 1h20.166c.55 0 .917.367.917.917v20.166c0 .55-.367.917-.917.917H1.917c-.55 0-.917-.367-.917-.917V1.917c0-.55.367-.917.917-.917zM4.3 19.792H7.6V9.25H4.3v10.542zM5.858 7.783c-1.008 0-1.925-.825-1.925-1.925 0-1.008.825-1.925 1.925-1.925 1.009 0 1.925.825 1.925 1.925s-.825 1.925-1.925 1.925zm10.634 12.009h3.3v-5.684c0-2.841-.642-5.041-3.942-5.041-1.65 0-2.658.825-3.117 1.65V9.25H9.617v10.542h3.3v-5.225c0-1.375.183-2.659 1.925-2.659 1.65 0 1.65 1.559 1.65 2.75v5.134z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

LinkedIn.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LinkedIn.defaultProps = {
  color: "#767171",
  width: 24,
  height: 24,
}

export default LinkedIn;
