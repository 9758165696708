import React from "react";
import PropTypes from "prop-types";

function Email({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M13.436 11.1779C12.9952 11.4185 12.5011 11.5445 11.999 11.5445C11.4969 11.5445 11.0028 11.4185 10.562 11.1779L0 4.87207V17.0001C0 17.7957 0.31607 18.5588 0.87868 19.1214C1.44129 19.684 2.20435 20.0001 3 20.0001H21C21.7956 20.0001 22.5587 19.684 23.1213 19.1214C23.6839 18.5588 24 17.7957 24 17.0001V4.87207L13.436 11.1779Z"
      />

      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M12 10C11.833 9.99989 11.6686 9.95795 11.522 9.878C9.70401 9.21691 0 3 0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H21C21.7956 0 22.5587 0.316071 23.1213 0.87868C23.6839 1.44129 24 2.20435 24 3C24 3 14.296 9.21691 12.478 9.878C12.3314 9.95795 12.167 9.99989 12 10Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

Email.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Email.defaultProps = {
  color: "#767171",
  width: 24,
  height: 24,
};

export default Email;
