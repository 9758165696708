// Dependencies
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import Container from '../../components/containers/Container';
import Overline from '../../components/Overline';
import TypingHeader from '../../components/TypingHeader';

// helpers
import { withVisibility } from '../../components/hooks/withVisibility';
import RevealText from '../../components/RevealText';
import gsap from 'gsap';

// Style
const MetaIntroWrapper = styled.div`
  max-width: 1340px;

  & strong {
    color: #ff4619;
  }

  & h1 p {
    font-size: inherit;
    line-height: inherit;
  }
`;

const MetaIntroText = ({ content, viewed }) => {
  let { current: textEl } = useRef([]);

  const { metaIntroText } = content;

  const { overline, textContent, isTyping, words } = metaIntroText[0];
  const wordsArray = words ? words.split(',') : [];

  useEffect(() => {
    gsap.set(textEl, { y: '100%' });
  }, []);

  useEffect(() => {
    if (viewed) {
      gsap.to(textEl, {
        duration: 1.5,
        ease: 'expo.inOut',
        y: '0%',
        rotation: 0.001,
        stagger: 0.1
      });
    }
  }, [viewed]);

  return (
    <Container>
      <MetaIntroWrapper
        className={
          isTyping ? 'mt-12 md:mt-16 lg:mt-20' : 'my-12 md:my-16 lg:my-20'
        }
      >
        <RevealText forwardedRef={textEl}>
          <Overline
            text={overline}
            dotColor='bg-highlight'
            textColor='text-highlight'
          />
        </RevealText>
        <h1 className='mt-6'>
          {wordsArray.length ? (
            <RevealText forwardedRef={textEl}>
              <TypingHeader
                fixed={
                  <span
                    className='inline-block'
                    dangerouslySetInnerHTML={{ __html: textContent }}
                  ></span>
                }
                words={wordsArray}
              />
            </RevealText>
          ) : (
            <RevealText forwardedRef={textEl} text={textContent} />
          )}
        </h1>
      </MetaIntroWrapper>
    </Container>
  );
};

MetaIntroText.propTypes = {
  content: PropTypes.shape({
    metaIntroText: PropTypes.arrayOf(
      PropTypes.shape({
        overline: PropTypes.string,
        textContent: PropTypes.string,
        isTyping: PropTypes.bool,
        words: PropTypes.string
      })
    )
  })
};

export default withVisibility(MetaIntroText);
