import React from 'react';
import PropTypes from 'prop-types';
import ArrowRight from './icons/ArrowRight';
import LinkChoice from './LinkChoice';
import { useWindowSize } from './hooks/useWindowSize';

const TextLink = ({ text, className, arrowColor, link, goBack, external }) => {
  const size = useWindowSize();
  let iconSize = size.width < 640 ? 12 : 16;
  const parsedLink = typeof link === 'string' ? link : link.url;

  return external | parsedLink?.startsWith('mailto:') ? (
    <a
      href={parsedLink}
      className={`block max-w-max flex items-center ${className || ''}`}
    >
      <p className='text-b2 sm:text-p2 font-bold tracking-wider'>{text}</p>
      <ArrowRight
        color={arrowColor}
        width={iconSize}
        height={iconSize}
        className={
          'mt-0.5 ' +
          (goBack
            ? 'order-first mr-2.5 rotate-180'
            : 'order-last ml-2.5')
        }
      />
    </a>
  ) : (
    <LinkChoice
      to={parsedLink}
      className={`block max-w-max flex items-center ${className || ''}`}
    >
      <p className='text-b2 sm:text-p2 font-bold tracking-wider'>{text}</p>
      <ArrowRight
        color={arrowColor}
        width={iconSize}
        height={iconSize}
        className={
          'mt-0.5 ' +
          (goBack
            ? 'order-first mr-2.5 rotate-180'
            : 'order-last ml-2.5')
        }
      />
    </LinkChoice>
  );
};

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  arrowColor: PropTypes.string,
  /**
   * If this is a return / go back link, the arrow should be on the left
   */
  goBack: PropTypes.bool,
  external: PropTypes.bool
};

TextLink.defaultProps = {
  arrowColor: '#fff',
  goBack: false
};

export default TextLink;
