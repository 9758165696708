import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

// Components
import { ServicesCard } from "../../components/NewsCards";
import { useWindowSize } from "../../components/hooks/useWindowSize";
import { useVideo } from "../../components/hooks/useVideo";
import ExternalVideo from "../../components/ExternalVideo";
import { withVisibility } from "../../components/hooks/withVisibility";

const BackgroundVideoItem = ({ cardContent, isVisible, ...props }) => {
  const { className, ...addonProps } = props;
  const [hovered, setHovered] = useState(false);
  const size = useWindowSize();
  const videoContainer = useRef();

  if (
    cardContent.media &&
    cardContent.media[0] &&
    cardContent.media[0].mimeType.includes("video")
  ) {
    useVideo(
      videoContainer,
      cardContent.media[0].mimeType.includes("video")
        ? cardContent.media[0].url
        : null,
      size.width < 768 ? isVisible : true,
      "w-full h-full object-cover",
      false
    );
  }

  const updateHoverState = (state) => {
    if (size.width >= 1024) {
      setHovered(state);
    } else if (hovered) {
      setHovered(false);
    }
  };

  return (
    <li
      className={"relative overflow-hidden " + className}
      {...addonProps.index}
    >
      <div
        className="flex items-center py-6 lg:py-40 px-6 sm:px-12 lg:px-32 2xl:px-48 group"
        tabIndex="0"
        onMouseEnter={() => updateHoverState(true)}
        onMouseLeave={() => updateHoverState(false)}
      >
        <div
          ref={videoContainer}
          className={
            "absolute top-0 left-0 w-full h-full transition hidden lg:block " +
            (hovered ? "opacity-100" : "opacity-0")
          }
        >
          {cardContent &&
            cardContent.media[0] &&
            cardContent.media[0].mimeType.includes("image") && (
              <img
                src={cardContent.media[0].url}
                className="object-cover w-full h-full"
              />
            )}
          {cardContent?.media?.[0]?.mimeType === "application/json" && (
            <ExternalVideo
              togglePlayback={size.width < 768 ? isVisible : true}
              media={cardContent?.media[0]}
              className="w-full h-full object-cover"
              asBackground
              fullScreen
            />
          )}
          <div className="absolute top-0 left-0 w-full h-full z-10 bg-gray-g4 opacity-20" />
        </div>
        <ServicesCard
          article={cardContent}
          hovered={hovered}
          className="z-20"
        />
      </div>
    </li>
  );
};

BackgroundVideoItem.propTypes = {
  /**
   * Service card content
   */
  cardContent: PropTypes.object.isRequired,
};

export default withVisibility(BackgroundVideoItem);
