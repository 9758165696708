export function classes(dynamic, classes = '') {
    return Object.entries(dynamic)
      .filter((entry) => entry[1])
      .map((entry) => entry[0])
      .join(' ')
      .concat(' ')
      .concat(classes)
  }
  
  export function uid(id = undefined) {
    return id && id.length ? id : getUid()
  }
  
  const getUid = (a) => {
    return a 
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, getUid)
  }