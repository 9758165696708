import React from 'react';
import PropTypes from 'prop-types';

// Components
import Stats, { StatTitle } from './Stats';
import Container from '../../components/containers/Container';
import useLocationGlobal from '../../hooks/useLocationGlobal';
import translations from '../../intl/translations.json';

const MetaStats = ({ content: { metaStats } }) => {
  const { caseType, shortDescription, caseKpis } = metaStats;
  const locale = useLocationGlobal();
  const { metaInfo } = translations;

  return (
    <Container className='pb-32'>
      <div className='flex flex-col md:flex-row mt-16 xl:mt-28'>
        <div className='flex flex-col md:w-3/5 xl:w-2/5 mb-16 md:mb-0'>
          <StatTitle className='text-gray-g4'>
            {metaInfo.implementation[locale]}
          </StatTitle>
          <h6
            className={
              `font-normal ` +
              (caseType === 'large' ? 'text-gray-g1' : 'text-gray-g3')
            }
            style={{ lineHeight: '36px' }}
          >
            {shortDescription}
          </h6>
        </div>

        <div className='flex flex-col flex-grow xl:flex-row lg:justify-between md:pl-20 gap-y-10'>
          {caseKpis.map((info, index) => {
            return (
              <Stats
                key={index}
                title={info.label}
                subtitle={`${info.number}`}
              />
            );
          })}
        </div>
      </div>
    </Container>
  );
};

MetaStats.propTypes = {
  /**
   * The content coming from Craft
   */
  content: PropTypes.object.isRequired
};

export default MetaStats;
