import React from "react"
import PropTypes from "prop-types"

function HeartIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 120 120">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M60 53.637a1.876 1.876 0 01-1.328-.552L37.138 31.5A15.923 15.923 0 1160 9.437 15.925 15.925 0 1182.78 31.572L61.327 53.085a1.876 1.876 0 01-1.327.552zM45.456 82.956L27.187 64.687a6.795 6.795 0 00-9.375 0 6.637 6.637 0 000 9.376l14.063 14.062a1.873 1.873 0 01-.554 3.068 1.877 1.877 0 01-2.05-.37l-.047-.047L15.16 76.714A10.378 10.378 0 0115 62.234V42.186a6.563 6.563 0 10-13.125 0v36.15c0 2.686.96 5.283 2.707 7.322l19.793 23.091v7.5a1.876 1.876 0 001.875 1.875h20.625a1.877 1.877 0 001.875-1.875V90.91a11.25 11.25 0 00-3.294-7.954zm47.356-18.269L74.544 82.957a11.25 11.25 0 00-3.294 7.953v25.341a1.876 1.876 0 001.875 1.875H93.75a1.877 1.877 0 001.875-1.875v-7.5l19.792-23.09a11.25 11.25 0 002.708-7.322v-36.15a6.561 6.561 0 00-11.203-4.64 6.561 6.561 0 00-1.922 4.64v20.045a10.38 10.38 0 01-.161 14.48L90.776 90.777l-.045.045a1.876 1.876 0 01-3.183-1.378 1.877 1.877 0 01.577-1.318l14.063-14.063a6.638 6.638 0 000-9.374 6.797 6.797 0 00-9.376 0z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="60"
          x2="6.774"
          y1="7.557"
          y2="157.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="fill-current" stopColor={color}></stop>
          <stop offset="1" className="fill-current" stopColor={color} stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

HeartIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

HeartIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default HeartIcon
