export const cardContent = [
  {
    headline: "Cases",
    image: "",
    subline:
      "Die Welt von marbet lebendig, bewegend. Was wir tun und was wir können auf einen Blick!",
    CTA: "Mehr erfahren",
  },
  {
    headline: "This is content",
    image: "",
    subline:
      "This is the second slide This is the second slide This is the second slide This is the second slide",
    CTA: "Mehr erfahren",
  },
  {
    headline: "Last slide",
    image: "",
    subline:
      "This is the third slide This is the third slide This is the third slide This is the third slide",
    CTA: "Mehr erfahren",
  },
];
