/* eslint-disable no-useless-concat */
import React from 'react'
import PropTypes from 'prop-types'
import { classes } from '../../utils/helpers'

const Size = {
  small: 'sm',
  medium: 'base',
  large: 'lg',
  extraLarge: 'xl'
}

const Color = {
  primary: 'primary',
  secondary: 'secondary'
}

const Variant = {
  filled: 'filled',
  ghost: 'ghost'
}

/**
 * Primary Button with variants
 */
const IconButton = ({ icon, disabled, size, color, variant, action, ...props }) => {
  const { className, ...addonProps } = props
  const colorOptions = {
    'bg-highlight text-white hover:bg-highlight-hover active:border active:border-secondary': color === 'primary' && variant === 'filled',
    'bg-secondary text-white hover:bg-secondary-hover active:border active:border-secondary': color === 'secondary' && variant === 'filled',

    'bg-transparent border':variant === 'ghost',
    'border-highlight text-highlight hover:bg-highlight-ghost-50 active:bg-highlight-ghost-150 active:text-highlight-hover active:border-highlight': color === 'primary' && variant === 'ghost' && !disabled,
    'border-white text-white hover:bg-secondary-ghost-50 active:bg-secondary-ghost-150': color === 'secondary' && variant === 'ghost',

    'cursor-not-allowed opacity-20': disabled,

    'w-8 h-8': size === 'sm',
    'w-10 h-10': size === 'base',
    'w-12 h-12': size === 'lg',
    'w-14 h-14': size === 'xl'
  }

  return (
    <button
      {...addonProps}
      onClick={action}
      disabled={disabled}
      className={classes(
        colorOptions,
        className +
          ' ' +
          'inline-flex justify-center items-center cursor-pointer rounded-full transition disabled:opacity-20 disabled:pointer-events-none focus:outline-none focus-visible:ring focus-visible:ring-focus'
      )}
    >
      {icon}
    </button>
  )
}

IconButton.propTypes = {
  /**
   * Main Icon
   */
  icon: PropTypes.node.isRequired,
  /**
   * Toggle button usage
   */
  disabled: PropTypes.bool,
  /**
   * Defines color based on config
   */
  color: PropTypes.oneOf([Color.primary, Color.secondary]),
  /**
   * Defines button type
   */
  variant: PropTypes.oneOf([Variant.filled, Variant.ghost]),
  /**
   * Defines between all sizes
   */
  size: PropTypes.oneOf([Size.small, Size.medium, Size.large, Size.extraLarge]),
  /**
   * Function to call when the button is clicked
   */
   action: PropTypes.func,
}

IconButton.defaultProps = {
  disabled: false,
  size: Size.medium,
  color: Color.primary,
  variant: Variant.filled,
  action: null,
}

export default IconButton
