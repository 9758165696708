import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useLocationGlobal from '../../hooks/useLocationGlobal';
import { useWindowSize } from '../../components/hooks/useWindowSize';
import translations from '../../intl/translations.json';

// Components
import MetaInfo from './MetaInfo';
import Overline from '../../components/Overline';
import Container from '../../components/containers/Container';

const IntroHeader = styled.h3`
  max-width: 928px;

  & > * {
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  @media (max-width: 1279px) {
    line-height: 42px;
    max-width: 85%;
  }
  @media (max-width: 1023px) {
    line-height: 36px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    max-width: none;
  }
`;

const TwoColumnIntro = ({ content: { twoColumnIntro } }) => {
  const { title, headline, client, caseYear, caseLocation, branch, caseType } =
    twoColumnIntro;
  const size = useWindowSize();
  const locale = useLocationGlobal();
  const { metaInfo } = translations;

  return (
    <Container>
      <div className='flex flex-col md:flex-row mt-16 md:mt-40'>
        <div className='flex flex-col xl:pr-40 mb-10 md:mb-0'>
          <Overline
            text={title}
            textColor='text-highlight'
            dotColor='bg-highlight'
            size={size.width < 640 ? 'small' : 'normal'}
          />
          <IntroHeader
            className={
              `font-serif font-normal md:text-h6 lg:text-h5 xl:text-h3 mt-2 md:mt-8 ` +
              (caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4')
            }
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </div>

        <div className='grid grid-cols-3 md:flex md:flex-col md:w-3/5 xl:w-2/5 md:border-l md:border-gray-g3 md:pl-20 py-4 gap-x-14 md:gap-x-0 gap-y-6'>
          <h5
            className={
              `hidden md:block ` +
              (caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4')
            }
          >
            {client}
          </h5>
          {size.width < 768 && (
            <MetaInfo
              title={metaInfo.title[locale]}
              subtitle={client}
              titleColor={
                caseType === 'large' ? 'text-gray-g3' : 'text-gray-g4'
              }
              subtitleColor={
                caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4'
              }
              className=''
            />
          )}
          <MetaInfo
            title={metaInfo.location[locale]}
            subtitle={caseLocation}
            titleColor={caseType === 'large' ? 'text-gray-g3' : 'text-gray-g2'}
            subtitleColor={
              caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4'
            }
            className='col-span-2'
          />
          <MetaInfo
            title={metaInfo.year[locale]}
            subtitle={`${new Date(caseYear).getFullYear()}`}
            titleColor={caseType === 'large' ? 'text-gray-g3' : 'text-gray-g4'}
            subtitleColor={
              caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4'
            }
            className=''
          />
          <MetaInfo
            title={metaInfo.industry[locale]}
            subtitle={branch}
            titleColor={caseType === 'large' ? 'text-gray-g3' : 'text-gray-g4'}
            subtitleColor={
              caseType === 'large' ? 'text-gray-g1' : 'text-gray-g4'
            }
            className='col-span-2 '
          />
        </div>
      </div>
    </Container>
  );
};

TwoColumnIntro.propTypes = {
  content: PropTypes.object.isRequired
};

export default TwoColumnIntro;
