import image from "../../assets/images/sliderCards.jpg";

export const sliderCards = [
  {
    image: image,
    quote: "Besonders bei der marbet ist die Erfahrung, welche wir nach 3 Jahren Abteilungsdurchläufe voller Abwechslung ergattern dürfen. Das tolle Klima unter uns und die Benefits wie den DRV-Auweis",
    name: "Nadine Göbel",
    role: "DHBW-Studium",
  },
  {
    image: image,
    quote: "Besonders bei der marbet ist die Erfahrung, welche wir nach 3 Jahren Abteilungsdurchläufe voller Abwechslung ergattern dürfen. Das tolle Klima unter uns und die Benefits wie den DRV-Auweis",
    name: "Nadine Göbel",
    role: "DHBW-Studium",
  },
  {
    image: image,
    quote: "Besonders bei der marbet ist die Erfahrung, welche wir nach 3 Jahren Abteilungsdurchläufe voller Abwechslung ergattern dürfen. Das tolle Klima unter uns und die Benefits wie den DRV-Auweis",
    name: "Nadine Göbel",
    role: "DHBW-Studium",
  },
]
