import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// components
import TextLink from "../../components/TextLink"

//Helpers
import { useOnScreen } from "../../components/hooks/useOnScreen"

const CardWrapper = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  height: 100%;
`

const StickyVerticalSliderCard = ({ data, onSlide, index }) => {
  const { headline, subline, background, linkName, linkAddress } = data

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    onSlide(isVisible, index)
  }, [isVisible])

  return (
    <CardWrapper>
      <div className="relative flex items-center h-full pl-28 w-full">
        <div className="absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full">
          <img alt="card" src={background[0].url} className="object-cover h-full w-full" />
        </div>
        <div className="w-3/6 relative">
          <h1 ref={ref} className="text-white italic font-serif">
            {headline}
          </h1>
          <p className="text-white font-bold text-2xl my-6">{subline}</p>
          <TextLink className="text-highlight" text={linkName} link={linkAddress} arrowColor="primary" />
        </div>
      </div>
    </CardWrapper>
  )
}

export default StickyVerticalSliderCard

StickyVerticalSliderCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  onSlide: PropTypes.func,
  setBodyLock: PropTypes.func,
}
