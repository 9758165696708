import React from 'react'
import PropTypes from "prop-types"
import MenuIcon from './MenuIcon'
import CasesGridIcon from "./CasesGridIcon"

const MiddleComponent = ({icon}) => {

const components = {
    MenuIcon: <MenuIcon />,
    CasesGridIcon: <CasesGridIcon />,
    NewsGridIcon: <CasesGridIcon news/>
}

    return (
        <div>
            {components[icon]}
        </div>
    )
}

export default MiddleComponent

MiddleComponent.propTypes = {
    icon: PropTypes.string
  }