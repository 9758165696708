import React from "react"
import PropTypes from "prop-types"

function MenuIcon({ color, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32">
      <path
        fill={color}
        fillRule="evenodd"
        d="M29.647 9.333H2.981V6.667h26.666v2.666zm0 8H2.981v-2.666h26.666v2.666zm0 8H2.981v-2.666h26.666v2.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

MenuIcon.propTypes = {
  color: PropTypes.string,
}

MenuIcon.defaultProps = {
  color: "#fff",
}

export default MenuIcon
