import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from '@reach/router';

import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import ArrowRight from '../../components/icons/ArrowRight';
import PalDropdown from '../../components/PalDropdown';

const ContactOverlayForm = ({
  formFields,
  requiredText,
  btnName,
  endpoint,
  root
}) => {
  const [formData, setFormData] = useState({});
  const [contactForm, setContactForm] = useState({});
  const [sent, setSent] = useState(false);
  const [, setServerState] = useState({
    submitting: false,
    status: null
  });
  const location = useLocation();
  const isSpanishSite = location.pathname.includes('/es/');

  const requiredFields = formFields.filter((e) => e.isRequired);

  let disabled = requiredFields.every((field) => {
    return field.fieldName in formData && formData[field.fieldName].length;
  });

  const emailEndpoints = () => {
    const defaultEndpoint =
      'https://getform.io/f/6992da59-aead-456d-9dab-dc6b53b28a54';
    const anniversaryEndpoint =
      'https://getform.io/f/4f715269-c1a4-4cdb-a51b-94f01cd7202b';
    const spanishAnniversaryEndpoint =
      'https://getform.io/f/9c2334bf-b441-4ed7-a8d8-83f1934f4267';

    let finalEndpoint;

    if (root === 'mAway') {
      if (formData.type && formData.type.includes('Urlaubsreisen')) {
        finalEndpoint =
          'https://getform.io/f/157b7d08-e2f4-4710-818d-69e7f9963183';
      } else if (formData.type && formData.type.includes('Geschäftsreisen')) {
        finalEndpoint =
          'https://getform.io/f/13ae1864-0549-47a9-ba53-ad68b794f6b6';
      }
    } else {
      finalEndpoint =
        location.pathname.includes('anniversary') ||
        location.pathname.includes('aniversario')
          ? isSpanishSite
            ? spanishAnniversaryEndpoint
            : anniversaryEndpoint
          : endpoint
          ? endpoint
          : defaultEndpoint;
    }

    return finalEndpoint;
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
      setSent(true);
      setFormData({});
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: emailEndpoints(),
      data: new FormData(form)
    })
      .then(() => {
        handleServerResponse(true, 'Thanks!', form);
        setSent(true);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  const onInputChange = (inputData) => {
    setFormData({ ...formData, ...inputData });
  };

  useEffect(() => {
    setSent(false);
  }, []);

  return (
    <div>
      {sent ? (
        <div>
          <h2 className='text-white'>
            {isSpanishSite ? '¡Muchas gracias!' : 'Vielen Dank!'}
          </h2>
        </div>
      ) : (
        <form
          className='grid grid-cols-2 gap-4'
          method='POST'
          onSubmit={handleOnSubmit}
        >
          {formFields.map((field, index) => {
            if (
              field.__typename === 'craft_contactFormField_dropdown_BlockType'
            )
              return (
                <div className='flex items-center gap-x-4' key={index}>
                  <p className='text-white text-[14px] pb-1'>{field.label}: </p>
                  <PalDropdown
                    root={root}
                    placeholder={'wählen...'}
                    options={field.options.split(',')}
                    onChange={(value) => {
                      setFormData({ ...formData, type: value });
                    }}
                  />
                </div>
              );
            return index !== formFields.length - 1 ? (
              field.fieldSize === 'small' ? (
                <TextInput
                  onInputChange={onInputChange}
                  label={field.fieldName}
                  key={index + field.fieldName + 'b'}
                  root={root}
                />
              ) : (
                <div className='col-span-2' key={index + field.fieldName + 'a'}>
                  <TextInput
                    onInputChange={onInputChange}
                    label={field.fieldName}
                    root={root}
                  />
                </div>
              )
            ) : (
              <div
                className='col-span-2 pt-4'
                key={index + field.fieldName + 'c'}
              >
                <TextArea
                  onInputChange={onInputChange}
                  label={field.fieldName}
                  root={root}
                />
              </div>
            );
          })}
          <div className='col-span-2 sm:mt-10 md:mt-12'>
            <p className='text-white text-p3 mb-4'>
               {requiredText || 'Pflichtfeld'}
            </p>
            <button
              className={
                'xxs:mb-12 w-60 h-14 flex justify-center items-center space-x-2.5 ' +
                (!disabled
                  ? 'opacity-50 cursor-not-allowed pointer-events-none '
                  : '') +
                (root === 'marbet'
                  ? 'bg-secondary text-white'
                  : 'bg-white text-black')
              }
              type='submit'
              value='Submit'
              tabIndex={!disabled ? '-1' : '0'}
            >
              <span className='text-p2 font-bold tracking-wider'>
                {btnName}
              </span>
              <ArrowRight className='mt-0.5' />
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactOverlayForm;

ContactOverlayForm.propTypes = {
  formFields: PropTypes.array,
  requiredText: PropTypes.string,
  btnName: PropTypes.string
};
