/* eslint-disable no-useless-concat */
import React from "react";
import PropTypes from "prop-types";
import { classes } from "../../utils/helpers";

const Size = {
  small: "small",
  large: "large",
};

const Color = {
  primary: "primary",
  secondary: "secondary",
};

const Variant = {
  filled: "filled",
  ghost: "ghost",
};

/**
 * Primary Button with variants
 */
const Button = ({
  label,
  iconPrefix,
  iconSuffix,
  disabled,
  size,
  color,
  block,
  variant,
  action,
  ...props
}) => {
  const { className, ...addonProps } = props;
  const colorOptions = {
    "bg-highlight text-white hover:bg-highlight-hover active:border active:border-secondary":
      color === "primary" && variant === "filled",
    "bg-secondary text-white hover:bg-secondary-hover active:border active:border-secondary":
      color === "secondary" && variant === "filled",

    "bg-transparent border": variant === "ghost",
    "border-highlight text-highlight hover:bg-highlight-ghost-50 active:bg-highlight-ghost-150 active:text-highlight-hover active:border-highlight":
      color === "primary" && variant === "ghost" && !disabled,
    "border-white text-white hover:bg-secondary-ghost-50 active:bg-secondary-ghost-150":
      color === "secondary" && variant === "ghost",

    "cursor-not-allowed opacity-20": disabled,
    "text-secondary border-secondary": disabled && variant === "ghost",

    "flex w-full": block,
    "flex md:inline-flex": !block,
    "h-14 text-p3": size === Size.large,
    "h-10 text-b2": size === Size.small,
  };

  return (
    <button
      {...addonProps}
      onClick={action}
      disabled={disabled}
      className={classes(
        colorOptions,
        className +
          " " +
          "justify-center items-center w-full md:w-auto px-5 my-2 cursor-pointer font-bold uppercase gap-x-3 rounded transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus-visible:ring focus-visible:ring-focus"
      )}
    >
      {iconPrefix}
      {label}
      {iconSuffix}
    </button>
  );
};

Button.propTypes = {
  /**
   * The text for the Button
   */
  label: PropTypes.string.isRequired,
  /**
   * Icon positioned before label
   */
  iconPrefix: PropTypes.node,
  /**
   * Icon positioned after label
   */
  iconSuffix: PropTypes.node,
  /**
   * Toggle button usage
   */
  disabled: PropTypes.bool,
  /**
   * Defines color based on config
   */
  color: PropTypes.oneOf([Color.primary, Color.secondary]),
  /**
   * Defines button type
   */
  variant: PropTypes.oneOf([Variant.filled, Variant.ghost]),
  /**
   * Defines between all sizes
   */
  size: PropTypes.oneOf([Size.small, Size.large]),
  /**
   * Function to call when the button is clicked
   */
  action: PropTypes.func,
  /**
   * Choose between block modes
   */
  block: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  block: false,
  size: Size.small,
  color: Color.primary,
  variant: Variant.filled,
  action: null,
};

export default Button;
