import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RevealText from '../../components/RevealText.new';
import LinkChoice from '../../components/LinkChoice';

const MenuLink = styled.div`
  background-color: rgba(255, 255, 255, 0.3s);
  display: flex;
  align-items: center;
  transform: translateX(-20px);

  & p {
    font-size: 24px;
  }

  & .dot {
    width: 8px;
    height: 8px;
    background: #262626;
    border-radius: 500px;
    opacity: 0;
    margin-right: 20px;

    transition: opacity 0.3s 0.1s;
  }

  &:hover {
    transform: translateX(0px);
    font-weight: bold;
  }

  &:hover .dot {
    opacity: 1;
  }

  transition: 0.3s 0.1s;

  @media (max-width: 1024px) {
    font-size: 17px;
  }
`;

const MenuLinkList = ({ links, title, titleRef, listRef, ...props }) => {
  return (
    <div
      {...props}
      className='flex flex-col h-full mr-12 mb-8 lg:mb-0 lg:mr-16 xl:mr-36 relative'
    >
      <h3 className='font-serif italic text-white mb-6 lg:mb-10'>
        <RevealText forwardedRef={titleRef} text={title} />
      </h3>
      <div ref={listRef}>
        {links.map((link) => {
          return (
            <LinkChoice
              key={link.linkName}
              to={link.linkAddress}
              // state={{ filter: link.filter }}
            >
              <MenuLink className='relative text-white hover:text-secondary mb-3 lg:mb-6'>
                <div className='dot' />
                <p>{link.linkName}</p>
              </MenuLink>
            </LinkChoice>
          );
        })}
      </div>
    </div>
  );
};

MenuLinkList.propTypes = {
  links: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleRef: PropTypes.object,
  listRef: PropTypes.object
};

export default MenuLinkList;
