import React from "react"
import PropTypes from "prop-types"

function PlayIcon({ width, height, ...props }) {
  return (
    <svg {...props} width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="fill-current" d="M19 31V17L31.5 24L19 31Z" />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      />
    </svg>
  )
}

PlayIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

PlayIcon.defaultProps = {
  width: 48,
  height: 48,
}

export default PlayIcon
