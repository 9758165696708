import React from "react";
import PropTypes from "prop-types";
import Slider from "./Slider";

import SliderCard from "./SliderCard";
import { sliderCards } from "../../utils/content/sliderCards";

const setBackgroundColor = ({ root, identifier }) => {
  if (root === "mGuest" && identifier === "home-slider") {
    return "bg-transparent";
  }

  return (
    {
      mGuest: "bg-primary",
      mAway: "bg-white",
      marbet: "bg-primary",
    }[root] || "bg-white"
  );
};

const setTextColor = ({ root, identifier }) => {
  if (root === "mGuest" && identifier === "home-slider") {
    return "text-gray-g4";
  }

  return (
    {
      mGuest: "text-gray-g0",
      mAway: "text-gray-g4",
      marbet: "text-gray-g0",
    }[root] || "text-gray-g0"
  );
};

const options = {
  type: "slider",
  perView: 2,
  focusAt: "center",
  animationDuration: 1000,
  peek: {
    before: 50,
    after: 50,
  },
  gap: 400,
  breakpoints: {
    1540: {
      gap: 100,
    },
    1279: {
      perView: 1,
      gap: 20,
    },
  },
};

const CardsSlider = ({ content }) => {
  const { identifier, headline, slider, root } = content;

  return (
    <section
      id={identifier}
      className={"relative py-24 " + setBackgroundColor({ root, identifier })}
    >
      {headline && (
        <div
          className={"mb-20 text-center " + setTextColor({ root, identifier })}
          dangerouslySetInnerHTML={{ __html: headline }}
        />
      )}
      <Slider
        root={root}
        identifier={identifier}
        totalCards={sliderCards.length}
        options={options}
      >
        {slider.map(({ entry }, index) => (
          <SliderCard
            data={entry[0]}
            key={index}
            accentColor={
              root === "mGuest" && identifier === "home-slider"
                ? "bg-primary"
                : ""
            }
          />
        ))}
      </Slider>
      {/* <div className="invisible lg:visible flex absolute justify-around items-center h-20 " data-glide-el="controls">
        <button className=" flex justify-center items-center w-12 h-12 rounded-full border border-highlight rotate-180" data-glide-dir="<">
          <ArrowRight className="text-highlight" />
        </button>
        <button className=" flex justify-center items-center w-12 h-12 rounded-full border border-highlight" data-glide-dir=">">
          <ArrowRight className="text-highlight" />
        </button>
      </div> */}
    </section>
  );
};

CardsSlider.propTypes = {
  content: PropTypes.shape({
    slider: PropTypes.array,
    plainText: PropTypes.string,
  }),
};

export default CardsSlider;
