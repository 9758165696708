import { useLocation } from "@reach/router";
import { useMemo } from "react";
import fetch from "isomorphic-fetch";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

function usePreviewClient() {
  // References & States
  let { search } = useLocation();

  // Get Right Content
  let client = useMemo(() => {
    if (search) {
      let hashes = search.slice(search.indexOf("?") + 1).split("&");
      let params = {};
      hashes.map((hash) => {
        let [key, val] = hash.split("=");
        params[key] = decodeURIComponent(val);
      });

      if (!params.token && !params["x-craft-live-preview"]) return;

      const httpLink = createHttpLink({
        uri: `${process.env.GATSBY_CRAFT_ENDPOINT}?token=${params.token}&x-craft-live-preview=${params["x-craft-live-preview"]}`, //THIS IS THE URL OF THR GRAPHQL WE WANTO TO QUERY
      });

      const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
          },
        };
      });
      let apollo = new ApolloClient({
        link: authLink.concat(httpLink),
        fetch: fetch,
        cache: new InMemoryCache(),
      });

      return apollo;
    } else return null;
  }, [search]);

  return client;
}

export default usePreviewClient;
