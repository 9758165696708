import React from "react"
import PropTypes from "prop-types"

function SpaceshipIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 120 120">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M106.907 44.25a106.857 106.857 0 009.343-38.518 1.882 1.882 0 00-.542-1.429 1.892 1.892 0 00-1.431-.553 106.343 106.343 0 00-38.647 9.229l31.277 31.271zM55.894 17.813a41.923 41.923 0 00-45.265 19.783 1.876 1.876 0 00.293 2.27l12.187 12.188a124.706 124.706 0 0132.785-34.241zm46.294 46.293a41.93 41.93 0 01-19.784 45.265 1.88 1.88 0 01-2.27-.293L67.946 96.891a124.712 124.712 0 0034.242-32.785zm-81.563 22.15c3.48 0 6.818 1.38 9.281 3.838a13.142 13.142 0 010 18.568c-6.041 6.043-23.392 7.528-24.127 7.588h-.154a1.868 1.868 0 01-1.383-.602 1.875 1.875 0 01-.492-1.427l.002-.025c.092-1.036 1.606-18.118 7.592-24.102a13.142 13.142 0 019.281-3.838zm-.386-17.365c.187-.465 14.82-35.672 51.813-54.187l33.124 33.116C86.617 84.767 51.581 99.57 51.118 99.758a1.875 1.875 0 01-2.036-.411l-28.429-28.43a1.876 1.876 0 01-.414-2.026zm39.136-5.162a11.25 11.25 0 1012.5-18.708 11.25 11.25 0 00-12.5 18.708z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="59.998"
          x2="6.989"
          y1="7.498"
          y2="153.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="fill-current" stopColor={color}></stop>
          <stop offset="1" className="fill-current" stopColor={color} stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

SpaceshipIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

SpaceshipIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default SpaceshipIcon
