import React from "react"
import PropTypes from "prop-types"
import ArrowRight from "./icons/ArrowRight"

const SliderNavigation = ({ current, total, next, previous, color }) => {
  return (
    <div className={"flex items-center space-x-4 cursor-pointer " + color}>
      <ArrowRight className="rotate-180" onClick={previous} />
      <p className="font-bold tracking-wider text-p3 md:text-p2">{"0" + (current + 1) + " / " + "0" + total}</p>
      <ArrowRight onClick={next} />
    </div>
  )
}

SliderNavigation.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  color: PropTypes.string,
}

SliderNavigation.defaultProps = {
  color: "text-white",
}

export default SliderNavigation
