import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import gsap from "gsap";

// Components
import PlusIcon from "../../components/icons/PlusIcon";
import MinusIcon from "../../components/icons/MinusIcon";

const FaqAccordionContent = styled.div`
  max-width: 1280px;
  font-size: 1.125rem;
  padding: 2em 1em 3em 0;

  @media (max-width: 1919.99px) {
    max-width: 1440px;
  }

  @media (max-width: 1439.99px) {
    max-width: 1280px;
  }

  @media (max-width: 1023.99px) {
    max-width: 840px;
  }

  @media (max-width: 767.99px) {
    max-width: 672px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style-type: square !important;
    margin-bottom: 12px !important;
    border: none;
  }

  li::marker {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;

    @media (min-width: 1024px) {
      max-width: 62rem;
      font-size: 28px;
      line-height: 41px;
    }
  }
`;

const FaqAccordionItem = ({
  header,
  body,
  selectedItem,
  index,
  toggleContent,
  ...props
}) => {
  const { className, ...addonProps } = props;
  const [active, setActive] = useState(false);
  const contentRef = useRef();
  const headerRef = useRef();
  const iconSize = 16;

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index;
    setActive(newValue === index);
    toggleContent(newValue);
  };

  const onKeyUpAction = (e) => {
    if (e.key === "Enter" || e.key === "Space") toggleItem();
  };
  const onKeyDownAction = (e) => {
    if (e.key === "Escape") {
      toggleContent(null);
      setActive(false);
    }
  };

  const animate = useCallback(() => {
    const height = active ? "auto" : 0;
    const opacity = active ? 1 : 0;
    gsap.to(contentRef.current, { height, opacity, duration: 0.3 });
  }, [active, contentRef]);

  /** Run on component mount */
  useEffect(() => {
    if (active) animate();
  }, []);

  /** Run whenever the item is updated */
  useEffect(() => {
    animate();
  }, [active, animate]);

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false);
  }, [selectedItem]);

  /**
   * Component
   */
  return (
    <li className={className} {...addonProps}>
      <div
        ref={headerRef}
        className={
          "text-[1.125rem] cursor-pointer flex items-center pt-5 pr-5 pb-5 group focus:outline-none transition border-b-[1px] border-gray-g3 text-gray-g3 hover:text-highlight " +
          (active ? "text-highlight border-highlight " : "")
        }
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <span className="flex-grow pr-5">{header}</span>
        {active ? (
          <MinusIcon width={iconSize} height={iconSize} />
        ) : (
          <PlusIcon width={iconSize} height={iconSize} />
        )}
      </div>
      <div
        ref={contentRef}
        className="h-0 opacity-0 overflow-hidden"
        role="listbox"
      >
        <FaqAccordionContent dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </li>
  );
};

FaqAccordionItem.propTypes = {
  /**
   * Item header's text
   */
  header: PropTypes.string.isRequired,
  /**
   * Item body's text
   */
  body: PropTypes.string.isRequired,
  /**
   * The currently selected item of the accordeon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
};

FaqAccordionItem.defaultProps = {
  selectedItem: null,
};

export default FaqAccordionItem;
