import React from "react";
import PropTypes from "prop-types";
import {
  GridImg,
  GridMediaWrapper,
  GridQuote,
  GridVideo,
} from "./NewsGallery.styles";
import QuoteCard from "../../components/QuoteCard";
import ExternalVideo from "../../components/ExternalVideo";

const NewsGalleryCard = ({ media, className, vertical, stretch }) => {
  const isQuote = media.quote ? true : false;

  return (
    <GridMediaWrapper
      className={className}
      vertical={vertical}
      stretch={stretch}
    >
      {/* Quote content */}
      {isQuote && (
        <GridQuote>
          <QuoteCard
            className="rounded"
            newsGallery
            variant="gray"
            quote={media.quote}
            source={media.title}
            role={media.role}
          />
        </GridQuote>
      )}

      {/* Media content */}
      {!isQuote && media?.mimeType.includes("image") && (
        <GridImg simple src={media?.url} alt={media?.fileName} />
      )}
      {!isQuote && media?.mimeType.includes("video") && (
        <GridVideo src={media?.url} playsInline muted autoPlay loop />
      )}
      {!isQuote && media?.mimeType === "application/json" && (
        <ExternalVideo
          media={media}
          asBackground
          fullScreen
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "0",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      )}
    </GridMediaWrapper>
  );
};

export default NewsGalleryCard;

NewsGalleryCard.propTypes = {
  media: PropTypes.object,
  className: PropTypes.string,
  vertical: PropTypes.bool,
};
