// dependencies
import React, { useRef } from "react";
import PropTypes from "prop-types";

// components
import ArrowRight from "./icons/ArrowRight";

// Helpers
import { useContainerDimensions } from "./hooks/useContainerDimensions";
import LinkChoice from "./LinkChoice";

const JobCard = ({ content }) => {
  const { title, highlightImage, slug, jobOffices } = content;
  const card = useRef();
  const { width } = useContainerDimensions(card);

  return (
    // <div ref={card} className="group cursor-pointer relative" style={{ maxHeight: 480 }}>
    <div
      ref={card}
      className="group cursor-pointer relative w-full h-0 aspect-w-19 aspect-h-12"
      style={{ maxWidth: 760, maxHeight: 480 }}
    >
      <LinkChoice to={"/career/jobs/" + slug} state={{ content }}>
        <div
          className={`rounded absolute w-full h-full ${
            highlightImage ? "bg-secondary" : "bg-highlight"
          } z-0`}
        />
        <div className="opacity-0 transition-all duration-200 group-hover:opacity-100 rounded absolute w-full h-full bg-highlight z-10" />
        <div className="absolute z-10 p-8 h-full flex flex-col justify-between">
          <div>
            <h5 className="text-white break-words opacity-100 pb-2 lg:text-h6 lg:leading-8 xl:leading-10 xl:text-h5">
              {title}
            </h5>
            <p
              style={{ fontSize: `${width <= 560 ? "14px" : "16px"}` }}
              className="text-white opacity-100"
            >
              {jobOffices.map((office, i) =>
                i + 1 === jobOffices.length ? office.title : office.title + ", "
              )}
            </p>
          </div>
          <div className="flex items-center pb-8 md:pb-0 invisible sm:visible">
            <p className="mr-2 text-white text-p2 font-bold tracking-wider">
              Details ansehen
            </p>
            <ArrowRight className="mr-2 text-white text-p2" />
          </div>
        </div>
        {highlightImage && highlightImage.length && (
          <img
            src={highlightImage[0].url}
            alt={title}
            className="z-0 rounded w-full h-full object-cover opacity-60"
          />
        )}
      </LinkChoice>
    </div>
  );
};

export default JobCard;

JobCard.propTypes = {
  content: PropTypes.object,
};
