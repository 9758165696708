// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

// Components
import BackgroundVideoItem from './BackgroundVideoItem';

const ContentWrapper = styled.section`
  li {
    max-width: 1920px;
    list-style-type: none;
    margin: auto;

    @media (min-width: 1024px) {
      border-bottom-width: 1px;
      border-color: #d9d9d9;
    }
  }
`;

const BackgroundVideoContent = ({ content, ...props }) => {
  const { className, ...addonProps } = props;
  const { backgroundMediaList } = content;

  return (
    <ContentWrapper className={className || ''} {...addonProps}>
      {backgroundMediaList[0].listItem.map((item, index) => {
        return (
          <BackgroundVideoItem
            key={item.title + index}
            index={index}
            cardContent={item}
          />
        );
      })}
    </ContentWrapper>
  );
};
BackgroundVideoContent.propTypes = {
  content: PropTypes.shape({
    backgroundMediaList: PropTypes.arrayOf(
      PropTypes.shape({
        listItem: PropTypes.array
      })
    )
  })
};

BackgroundVideoContent.defaultProps = {
  items: []
};

export default BackgroundVideoContent;
