import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextLink from './TextLink';
import Overline from './Overline';
import Button from './buttons/Button';
import ArrowRight from './icons/ArrowRight';

import placeholder from '../assets/images/logo.jpg';
import { useVideo } from './hooks/useVideo';
import ExternalVideo from "./ExternalVideo";
import { parseDate } from '../utils/helpers';
import { navigateTo } from './LinkChoice';
import useLocationGlobal from '../hooks/useLocationGlobal';
import translations from '../intl/translations.json';

import Image from './Image';

const FlexedColumn = styled.div`
  flex: ${({ amount }) => amount};

  @media (max-width: 1280px) {
    flex: none;

    &.full {
      flex: auto;
    }
  }

  @media (max-width: 420px) {
    flex: ${({ amount }) => amount};
  }
`;

const OfficesExtraInfo = styled.span`
  & p {
    font-size: 18px;

    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (min-width: 1280px) {
      line-height: 29px;
      font-size: 18px;
    }
  }
`;

export const StandardNewsCard = ({ article }) => {
  const {
    title,
    newsImageGallery,
    newsSubheadline,
    newsDate,
    slug,
    serviceTags,
    highlightImage,
    media,
    language
  } = article;

  const locale = useLocationGlobal();
  const date = parseDate(newsDate);

  return (
    <div className='flex h-auto md:h-80 border-gray-g1 border-t pt-8 pb-4 md:pb-0'>
      <FlexedColumn amount={1.3}>
        <div className='relative overflow-hidden rounded h-24 w-24 sm:h-40 sm:w-40 md:h-64 md:w-64 2xl:h-64 2xl:w-64 mr-4 sm:mr-10 bg-gray-g1'>
          <Image
            simple
            alt={title}
            src={
              media && media.length && media[0].url
                ? media[0].url
                : (highlightImage &&
                    highlightImage[0] &&
                    highlightImage[0].url) ||
                  ''
            }
            className='object-cover h-full w-full'
          />
        </div>
      </FlexedColumn>
      <FlexedColumn amount={2} className='full'>
        <Overline
          size='small'
          text={`${translations.newsCard.categoryLabel[locale]} / ${date}`}
          textColor='text-gray-g3'
          dotColor='bg-gray-g3'
        />

        <h5
          style={{ overflowWrap: 'break-word' }}
          className='text-secondary my-2 sm:my-4 text-base sm:text-h6 2xl:text-h5 line-clamp-2 w-11/12'
        >
          {title}
        </h5>
        {newsSubheadline && (
          <h6 className='text-gray-g3 mb-4 2xl:mb-8 text-p3 sm:text-base font-medium'>
            {newsSubheadline}
          </h6>
        )}

        <p className='text-gray-g3 mb-4 2xl:mb-8 text-p3 sm:text-base'>
          {serviceTags}
        </p>
        <TextLink
          className='text-highlight flex'
          text={`${translations.newsCard.readNowButton[locale]}`}
          arrowColor='#FF4619'
          link={'/news/' + slug}
        />
      </FlexedColumn>
    </div>
  );
};

StandardNewsCard.propTypes = {
  article: PropTypes.object,
  type: PropTypes.string
};

export const FeaturedGridCard = ({ article, type }) => {
  const {
    title,
    newsImageGallery,
    newsDate,
    slug,
    serviceTags,
    highlightImage,
    media
  } = article;

  const locale = useLocationGlobal();
  const date = parseDate(newsDate);

  const previewImage = () => {
    if (media && media.length) {
      return media[0].url;
    }
    return placeholder;
  };
  return (
    <div className='flex flex-col h-full border-gray-g1 border-t pt-8 pb-4 lg:pb-0'>
      <div className='w-full pb-8' style={{ flex: 3 }}>
        <div className='relative overflow-hidden rounded h-56 md:h-96 xl:h-full xl:w-full bg-gray-g1'>
          <Image
            simple
            alt={title}
            src={previewImage()}
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover h-full w-full'
          />
        </div>
      </div>
      <div className='w-full' style={{ flex: 1 }}>
        <Overline
          size='small'
          text={`${translations.newsCard.categoryLabel[locale]} / ${date}`}
          textColor='text-gray-g3'
          dotColor='bg-gray-g3'
        />
        <h5
          className='text-secondary my-4 text-base sm:text-h6 2xl:text-h5'
          style={{ hyphens: 'auto' }}
        >
          {title}
        </h5>
        <p className='text-gray-g3 mb-4 2xl:mb-8 text-p3 sm:text-base'>
          {serviceTags}
        </p>
        <TextLink
          className='text-highlight flex'
          text={`${translations.newsCard.readNowButton[locale]}`}
          arrowColor='#FF4619'
          link={'/news/' + slug}
        />
      </div>
    </div>
  );
};

FeaturedGridCard.propTypes = {
  article: PropTypes.object,
  type: PropTypes.string
};

export const SmallNewsCard = ({ article }) => {
  const { newsDate, title, newsSubheadline, slug, serviceTags } = article;
  const locale = useLocationGlobal();
  const date = parseDate(newsDate);

  return (
    <div className='flex flex-col' style={{ maxWidth: 436 }}>
      <Overline
        text={`${translations.newsCard.categoryLabel[locale]} / ${date}`}
        textColor='text-gray-g3'
        dotColor='bg-gray-g3'
      />

      <h5 className='text-secondary mb-4'>{title}</h5>
      <p className='text-gray-g3 mb-8'>{newsSubheadline}</p>
      {serviceTags && <p className='text-gray-g3 mb-8'>{serviceTags}</p>}

      <TextLink
        className='text-highlight'
        text={translations.newsCard.readMoreButton[locale]}
        arrowColor='#FF4619'
        link={'/news/' + slug}
      />
    </div>
  );
};

SmallNewsCard.propTypes = {
  article: PropTypes.object,
  category: PropTypes.string
};

export const ImprintCard = ({ article, ...props }) => {
  const { header, content, image, buttonText, buttonLink } = article;
  const { className = '' } = props;

  return (
    <div className={className + ' flex flex-col'} style={{ maxWidth: 375 }}>
      {image && (
        <div className='relative overflow-hidden h-64 w-full mb-4'>
          <img
            alt={header}
            src={image}
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain h-full w-full'
          />
        </div>
      )}

      <h5 className='text-highlight font-serif italic mb-4'>{header}</h5>
      <p
        className='text-gray-g3 lg:text-p3 xl:text-base mb-8'
        dangerouslySetInnerHTML={{ __html: content }}
      />

      {buttonText && buttonLink && (
        <TextLink
          className='text-highlight'
          text={buttonText}
          arrowColor='#FF4619'
          link={buttonLink}
        />
      )}
    </div>
  );
};

ImprintCard.propTypes = {
  article: PropTypes.object
};

export const OfficeCard = ({ office, ...props }) => {
  const {
    title,
    highlightImage,
    linkAddress,
    businessName,
    address,
    postalCode,
    city,
    email,
    phoneOne,
    phoneTwo,
    redactor
  } = office;
  const { className = '' } = props;

  const locale = useLocationGlobal();

  return (
    <div className={className + ' flex flex-col'} style={{ maxWidth: 375 }}>
      {highlightImage && (
        <div className='relative overflow-hidden h-64 w-full mb-4'>
          <img
            alt={title}
            src={highlightImage[0].url}
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain h-full w-full'
          />
        </div>
      )}

      <h5 className='text-highlight font-serif italic mb-4'>{title}</h5>
      <p className='text-gray-g3 lg:text-p3 xl:text-base'>
        marbet
        <br />
        {businessName}
        <br />
        {address}
        <br />
        {postalCode} {city}
        <br />
        {email}
        <br />
        {phoneOne}
        <br />
        {phoneTwo}
        <br />
      </p>
      {redactor && (
        <OfficesExtraInfo
          className='text-gray-g3 mb-6'
          dangerouslySetInnerHTML={{ __html: redactor }}
        />
      )}

      {linkAddress && (
        <TextLink
          className='text-highlight mt-4'
          text={translations.officesGrid.routeLinkName[locale]}
          arrowColor='#FF4619'
          link={linkAddress}
          external
        />
      )}
    </div>
  );
};

OfficeCard.propTypes = {
  office: PropTypes.object
};

export const CareersCard = ({ article, ...props }) => {
  const { headline, subline, media, linkName, linkAddress, description } =
    article;
  const { className = '' } = props;

  return (
    <div className={'flex flex-col h-full ' + className}>
      <div className='overflow-hidden w-full h-0 aspect-w-11 aspect-h-7 mb-2 md:mb-8'>
        <img
          alt={headline}
          src={media[0].url}
          className='w-full h-full object-cover rounded'
        />
      </div>

      <div className='w-full' style={{ flex: 1 }}>
        <h5
          className='text-gray-g4 my-2 md:my-4 text-base md:text-h5 xl:text-h4'
          style={{ hyphens: 'auto' }}
        >
          {headline}
        </h5>
        {subline && (
          <p className='text-p1 font-bold text-gray-g4 mb-2'>{subline}</p>
        )}
        <p className='text-gray-g4 font-normal text-p2 md:text-base mb-4 2xl:mb-8'>
          {description}
        </p>
        {linkName && (
          <Button
            label={linkName}
            iconSuffix={<ArrowRight />}
            action={() => navigateTo(linkAddress)}
            size='large'
          />
        )}
      </div>
    </div>
  );
};

CareersCard.propTypes = {
  article: PropTypes.object.isRequired,
  buttonAction: PropTypes.func.isRequired
};

export const StickySliderMobileCard = ({ data }) => {
  const { headline, subline, background, linkName, linkAddress } = data;

  return (
    <div className='flex flex-col h-full pb-4 lg:pb-0'>
      <div className='overflow-hidden w-full mb-2 md:mb-8'>
        <img
          alt={headline}
          src={background[0].url}
          className='w-screen object-contain'
        />
      </div>

      <div className='w-full' style={{ flex: 1 }}>
        <h5
          className='text-secondary my-2 md:my-4 text-base md:text-h5 xl:text-h4'
          style={{ hyphens: 'auto' }}
        >
          {headline}
        </h5>
        <p className='text-gray-g3 text-p2 md:text-base mb-4 2xl:mb-8'>
          {subline}
        </p>
        <TextLink
          className='text-highlight'
          text={linkName}
          link={linkAddress}
          arrowColor='primary'
        />
      </div>
    </div>
  );
};

StickySliderMobileCard.propTypes = {
  data: PropTypes.object.isRequired
};

export const ServicesCard = ({ article, hovered, ...props }) => {
  const {
    title,
    serviceHeadline,
    serviceTags,
    serviceSubline,
    serviceDescription,
    linkName,
    linkAddress,
    media
  } = article;
  const { className } = props;

  const videoContainer = useRef();

  if (media.length && media[0].mimeType.includes('video')) {
    useVideo(videoContainer, media[0].url, true);
  }

  return (
    <div
      className={
        'flex flex-col lg:flex-row lg:justify-between w-full pt-8 pb-4 md:pb-0 max-w-7xl lg:mx-auto ' +
        className
      }
    >
      <div
        ref={videoContainer}
        className='mb-6 lg:hidden w-full object-contain'
      >
        {media.length && media[0].mimeType.includes('image') && (
          <img src={media[0].url} className='w-full object-contain' />
        )}
        {media?.[0]?.mimeType === "application/json" && (
          <ExternalVideo media={media[0]} togglePlayback asBackground />
        )}
      </div>
      <div className='flex flex-col lg:w-1/2 lg:max-w-xl'>
        <Overline
          size='small'
          text={title}
          textColor={hovered ? 'text-white' : 'text-gray-g3'}
          dotColor={hovered ? 'bg-white' : 'bg-gray-g3'}
        />
        <h2
          style={{ overflowWrap: 'break-word' }}
          className='text-highlight font-serif italic text-h6 md:text-h5 lg:text-h2 mt-0.5 lg:mt-3 mb-3 lg:mb-6 line-clamp-2 w-11/12'
        >
          {serviceHeadline}
        </h2>
        <p
          className={
            'hidden lg:block mb-4 2xl:mb-8 text-p2 pr-20 ' +
            (hovered ? 'text-white' : 'text-gray-g4')
          }
        >
          {serviceTags}
        </p>
        {linkName && linkAddress && (
          <div className='max-w-1/2'>
            <Button
              label={linkName}
              iconSuffix={<ArrowRight />}
              action={() => navigateTo(linkAddress)}
            />
          </div>
        )}
      </div>
      <div className='flex flex-col lg:w-1/2 lg:max-w-xl'>
        <h5
          style={{ overflowWrap: 'break-word' }}
          className={
            'mb-3 lg:mb-5 text-base sm:text-h6 2xl:text-h5 line-clamp-2 w-11/12 ' +
            (hovered ? 'text-white' : 'text-secondary')
          }
        >
          {serviceSubline}
        </h5>
        <p className={'text-base ' + (hovered ? 'text-white' : 'text-gray-g3')}>
          {serviceDescription}
        </p>
        <p
          className={
            'lg:hidden mt-6 text-p2 md:pr-20 ' +
            (hovered ? 'text-white' : 'text-gray-g4')
          }
        >
          {serviceTags}
        </p>
      </div>
    </div>
  );
};

ServicesCard.propTypes = {
  article: PropTypes.object.isRequired,
  hovered: PropTypes.bool.isRequired
};
