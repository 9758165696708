import React from "react"
import PropTypes from "prop-types"

function Check({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M19.4438 5.7657C19.8765 6.10886 19.9491 6.73784 19.606 7.17058L10.9611 18.072C10.7839 18.2955 10.5197 18.4326 10.235 18.449C9.9503 18.4654 9.67213 18.3594 9.47047 18.1578L4.47047 13.1578C4.07995 12.7672 4.07995 12.1341 4.47048 11.7435C4.861 11.353 5.49416 11.353 5.88469 11.7435L10.0913 15.9501L18.0389 5.92789C18.382 5.49515 19.011 5.42254 19.4438 5.7657Z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

Check.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

Check.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default Check
