import React, { useRef } from "react";
import PropTypes from "prop-types";
import Container from "../../components/containers/Container";
import Image from "../../components/Image";
import { useVideo } from "../../components/hooks/useVideo";
import ExternalVideo from "../../components/ExternalVideo";

const HighlightImage = ({ content, padding, togglePlayback = true }) => {
  const videoContainer = useRef();
  const media = content.__typename?.includes("newsModules")
    ? content.media
    : content.highlightImage;

  if (media?.[0]?.mimeType?.includes("video")) {
    useVideo(
      videoContainer,
      media[0].mimeType.includes("video") ? media[0].url : null,
      togglePlayback,
      "w-screen object-contain rounded",
      false,
      true
    );
  }

  return (
    <Container type="wide" fullMobile>
      <div
        ref={videoContainer}
        className={"overflow-hidden " + (padding ? "py-24" : "")}
      >
        {media?.[0]?.mimeType?.includes("image") && (
          <Image
            simple
            src={media[0].url}
            alt="highlight-image"
            className="object-contain rounded max-h-[900px] mx-auto"
          />
        )}
        {media?.[0]?.mimeType === "application/json" && (
          <ExternalVideo
            media={media[0]}
            togglePlayback={togglePlayback}
            className="w-screen object-contain rounded"
          />
        )}
      </div>
    </Container>
  );
};

HighlightImage.propTypes = {
  content: PropTypes.object,
  padding: PropTypes.bool,
};

HighlightImage.defaultProps = {
  padding: true,
};

export default HighlightImage;
