// Dependencies
import React, { useEffect, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

// Components
import Container from '../../components/containers/Container';
import ContactIcon from '../../components/icons/ContactIcon';
// Helpers
import { setEventListener, fireEvent } from '../../utils/helpers';
import MiddleComponent from '../../components/icons/MiddleComponent';
import LinkChoice from '../../components/LinkChoice';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const NavBar = ({ toggleMenu, Logo, isOpen, root }) => {
  // States & Refs
  const [theme, setTheme] = useState('light');
  const [middleComponent, setMiddleComponent] = useState(null);
  const [forceLogoColor, setForceLogoColor] = useState(null);
  const location = useLocation().pathname;

  // NavBar should only show the logo if currently on anniversary page
  let isAnniversary =
    location.includes('anniversary') || location.includes('aniversario');

  useEffect(() => {
    // Runs only once
    setEventListener('nav-scheme', (e) => setTheme(e.detail));
    setEventListener('middle-component', (e) => setMiddleComponent(e.detail));
    setEventListener('force-logo', (e) => setForceLogoColor(e.detail));

    return () => {
      setEventListener('nav-scheme', (e) => setTheme(e.detail), true);
      setEventListener(
        'middle-component',
        (e) => setMiddleComponent(e.detail),
        true
      );
      setEventListener('force-logo', (e) => setForceLogoColor(e.detail), true);
    };
  }, []);

  return (
    <Container
      fullWidth
      type='wide'
      className='flex items-center h-20 md:h-28 absolute top-0 left-0 z-50 w-screen'
    >
      <div className='flex flex-1 justify-start'>
        <LinkChoice to='/'>
          {Logo && (
            <Logo
              color={
                forceLogoColor
                  ? forceLogoColor
                  : theme === 'light' || isOpen
                  ? 'white'
                  : '#FF4619'
              }
              className='h-8 w-28 md:h-auto md:w-auto'
            />
          )}
        </LinkChoice>
      </div>
      <div className='hidden md:block'>
        <MiddleComponent icon={middleComponent} />
      </div>
      {!isAnniversary && (
        <div className='flex flex-1 items-center justify-end space-x-3 md:space-x-8 -mr-2'>
          {root === 'marbet' && (
            <LanguageSwitcher
              className={`${
                isOpen ? 'block ' : 'hidden sm:block transition'
              } cursor-pointer `}
              theme={isOpen ? 'dark' : theme}
              menuOpen={isOpen}
              toggleMenu={() => toggleMenu(false)}
              root={root}
            />
          )}

          <div onClick={() => fireEvent('contact-overlay', true)}>
            <ContactIcon
              color={theme === 'light' || isOpen ? '#fff' : '#262626'}
              className={
                'sm:block cursor-pointer ' + (isOpen ? 'block' : 'hidden')
              }
            />
          </div>
          <Hamburger
            size={28}
            color={theme === 'light' ? '#fff' : '#262626'}
            toggled={isOpen}
            toggle={toggleMenu}
          />
        </div>
      )}
    </Container>
  );
};

NavBar.propTypes = {
  toggleMenu: PropTypes.func,
  isOpen: PropTypes.bool,
  root: PropTypes.string
};

NavBar.defaultProps = {
  scheme: 'light'
};

export default NavBar;
