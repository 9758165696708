import React from "react"
import PropTypes from "prop-types"
import Container from "../../components/containers/Container"
import ImageQuoteGridCard from "../../components/ImageQuoteGridCard"

const ImageQuoteGrid = ({ content, root }) => {
  return (
    <Container type="wide" className="pb-16 pt-6 lg:pb-28 lg:pt-10">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
        {content.map(card => {
          return (card.imageLink || card.quote) && <ImageQuoteGridCard key={card.id} card={card} root={root} />
        })}
      </div>
    </Container>
  )
}

export default ImageQuoteGrid

ImageQuoteGrid.propTypes = {
  content: PropTypes.array.isRequired,
}
