import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./BenefitSlider.css";
import { useWindowSize } from "../../components/hooks/useWindowSize";

// Components
import Container from "../../components/containers/Container";
import Slider from "../CardsSlider/Slider";
import TextLink from "../../components/TextLink";

const breakpointsLargeCards = {
  1660: {
    perView: 2,
    gap: 80,
    peek: { before: 0, after: 200 },
  },
  1440: {
    perView: 2,
    gap: 80,
    peek: { before: 0, after: 100 },
  },
  1024: {
    perView: 2,
  },
  880: {
    perView: 1,
    peek: { before: 0, after: 240 },
  },
  767: {
    perView: 2,
    gap: 40,
    peek: 0,
  },
  640: {
    perView: 1,
    peek: { before: 0, after: 200 },
  },
  520: {
    perView: 1,
    peek: { before: 0, after: 120 },
  },
  400: {
    perView: 1,
    peek: 0,
  },
};

const breakpointsSmallCards = {
  1620: {
    perView: 4,
    gap: 80,
  },
  1240: {
    perView: 3,
    gap: 80,
  },
  924: {
    perView: 2,
    gap: 80,
  },
  520: {
    perView: 1,
    peek: { before: 0, after: 150 },
  },
  330: {
    perView: 1,
    peek: 0,
  },
};

/**
 * Component
 */
const BenefitSlider = ({ content }) => {
  const { identifier, benefitSlider } = content;
  const { headline, benefits, itemSize } = benefitSlider[0];
  const { width } = useWindowSize();

  let selectedBreakpoints = itemSize
    ? breakpointsLargeCards
    : breakpointsSmallCards;

  const options = useMemo(
    () => ({
      type: "carousel",
      perView: itemSize ? 3 : 5,
      gap: 96,
      bound: true,
      breakpoints: selectedBreakpoints,
    }),
    [selectedBreakpoints, itemSize]
  );

  return (
    <div id={identifier}>
      <Container className="pt-10 pb-32 lg:pt-20 lg:pb-44">
        {headline && (
          <h3 className="text-secondary mb-10 lg:mb-20">{headline}</h3>
        )}
        <div>
          <Slider
            element="benefitSlider"
            subbrand
            lightTheme
            totalCards={benefits.length}
            options={options}
          >
            {benefits.map((card, index) => (
              <div key={index}>
                <div
                  className="flex justify-center items-center mb-8"
                  style={{
                    width: width < 768 ? 118 : 138,
                    height: width < 768 ? 118 : 138,
                  }}
                >
                  <img className="object-contain" src={card.image[0].url} />
                </div>
                <p className="font-bold mb-4">{card.headline}</p>
                {card.description && (
                  <p
                    className="text-p2 mb-4"
                    style={{
                      maxWidth: width < 768 ? 290 : itemSize ? 460 : 216,
                    }}
                  >
                    {card.description}
                  </p>
                )}
                {card.linkText && (
                  <TextLink
                    className="text-highlight mt-5"
                    text={card.linkText}
                    link={card.linkAddress}
                    arrowColor="primary"
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

BenefitSlider.propTypes = {
  content: PropTypes.shape({
    identifier: PropTypes.string,
    benefitSlider: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Module headline
         */
        headline: PropTypes.string,
        /**
         * Array containing benefit cards
         */
        benefits: PropTypes.array,
        /**
         * Determines the size of the cards (true = large)
         */
        itemSize: PropTypes.bool,
      })
    ),
  }),
};

export default BenefitSlider;
