import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../../components/buttons/Button";
import gptw from '../../assets/images/gptw.png';
import Container from "../../components/containers/Container";
import Overline from "../../components/Overline";
import RevealText from "../../components/RevealText";
import TextLink from "../../components/TextLink";

import { parseHeadline } from "../../utils/helpers";
import { Headline } from "../../components/containers/HeadlineContainers";
import { useVideo } from "../../components/hooks/useVideo";
import ExternalVideo from "../../components/ExternalVideo";
import LinkChoice, { navigateTo } from "../../components/LinkChoice";
import ArrowRight from "../../components/icons/ArrowRight";
import {useLocation} from "@reach/router";

const setOverlineColor = (color) => {
  return (
    {
      mGuest: "text-highlight",
      mAway: "text-white",
      marbet: "text-highlight",
    }[color] || "text-highlight"
  );
};

const setDotColor = (color) => {
  return (
    {
      mGuest: "bg-highlight",
      mAway: "bg-white",
      marbet: "bg-highlight",
    }[color] || "bg-highlight"
  );
};

const HeroImage = ({ content }) => {
  const { media, heroMedia } = content;
  const {
    overline,
    headline,
    description,
    linkAddressOne,
    linkAddressTwo,
    linkNameOne,
    linkNameTwo,
    backgroundColor,
    buttonBubble,
    buttonBubbleHeadline,
    buttonbubblesubline,
  } = heroMedia[0];
  const { pathname } = useLocation();
  const videoContainer = useRef();
  const overlineColor = setOverlineColor(content.root);
  const dotColor = setDotColor(content.root);

  const showGPTWBadge = pathname === "/de/career/";

  // depending on the value from the back end, the below would update the background
  const useBgColor = backgroundColor;

  // if the module uses buttonBubble or introHero then the below classes would be applied to the container
  const isIntroHero =
    " flex flex-col justify-center items-start xl:items-center xl:grid xl:grid-cols-[2fr,1fr] lg:space-y-8 lg:px-40 bg-no-repeat px-12 h-[89vh] ";

  if (media[0]?.mimeType.includes("video")) {
    useVideo(
      videoContainer,
      media[0].mimeType.includes("video") ? media[0].url : null,
      true,
      "absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full min-h-screen sm:h-[66vh]",
      false
    );
  }

  return (
    <Container
      forwardedRef={videoContainer}
      className={
        useBgColor +
        (buttonBubble
          ? isIntroHero
          : " min-h-screen flex items-end md:items-center") +
        ' relative'
      }
    >
      {media[0]?.mimeType.includes("image") && (
        <img
          alt="hero"
          src={media[0].url}
          className="absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full "
        />
      )}
      {media?.[0]?.mimeType === "application/json" && (
        <ExternalVideo
          media={media[0]}
          togglePlayback
          className="absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full min-h-screen sm:h-[66vh]"
          asBackground
          fullScreen
        />
      )}
      <div
        className={
          "text-white md:mb-0 max-w-4xl relative z-10 " +
          (buttonBubble ? "mb-4" : "mb-28")
        }
      >
        <RevealText>
          <Overline
            size="normal"
            dotColor={dotColor}
            textColor={overlineColor}
            text={overline}
          />
        </RevealText>
        <Headline className="slides__caption-headline my-6 md:mb-8 font-black text-h6 sm:text-h4 xl:text-h1">
          {parseHeadline(headline).map((line) => (
            <RevealText key={line} text={line} />
          ))}
        </Headline>
        {description && (
          <RevealText>
            <p className="text-white">{description}</p>
          </RevealText>
        )}
        {!buttonBubble && (
          <RevealText>
            {linkNameOne && linkAddressOne && (
              <Button
                className="hidden md:block mr-6"
                label={linkNameOne}
                size="large"
                action={() => navigateTo(linkAddressOne)}
              />
            )}
            {linkNameTwo && linkAddressTwo && (
              <Button
                className="hidden md:block"
                variant="ghost"
                color="secondary"
                label={linkNameTwo}
                size="large"
                action={() => navigateTo(linkAddressTwo)}
              />
            )}
            {linkNameOne && linkAddressOne && (
              <TextLink
                className="md:hidden"
                text={linkNameOne}
                link={linkAddressOne}
              />
            )}
          </RevealText>
        )}
      </div>

      {/* the below is the bubble with text/button inside it, if button bubble is passed it would render */}
      {buttonBubble && buttonBubbleHeadline && linkAddressOne && (
        <LinkChoice
          to={linkAddressOne}
          className="static bottom-[74px] right-[28px] rounded-full flex flex-col justify-center items-center bg-highlight w-[223px] px-12 lg:px-24 h-[224px] lg:w-[416px] lg:h-[416px] z-10 text-white"
        >
          <div class="flex flex-col space-y-4 items-center lg:w-[189px]">
            <h4 className="italic font-serif text-h6 lg:text-h4">
              {buttonBubbleHeadline}
            </h4>
            {buttonbubblesubline && (
              <h5 className="hidden lg:flex">Beratung & Betreuung</h5>
            )}
            <ArrowRight width={24} className="ml-28 mt-3" />
          </div>
        </LinkChoice>
      )}
      {showGPTWBadge && (
        <div className="absolute end-0 bottom-[-56px] md:bottom-[-70px] px-5 md:px-[82px] z-10">
          <a href="https://www.greatplacetowork.de/workplace/item/7974/marbet+marion+%26+bettina+w%C3%BCrth+gmbh+%26+co.+kg" target="_blank">
            <img src={gptw} alt="Great place to work badge" className="max-w-[80px] md:max-w-[100px]" />
          </a>
        </div>
      )}
    </Container>
  );
};

HeroImage.propTypes = {
  content: PropTypes.shape({
    media: PropTypes.array,
    heroMedia: PropTypes.arrayOf(
      PropTypes.shape({
        overline: PropTypes.string,
        headline: PropTypes.string,
        linkAddressOne: PropTypes.shape({
          url: PropTypes.string,
        }),
        linkAddressTwo: PropTypes.shape({
          url: PropTypes.string,
        }),
        linkNameOne: PropTypes.string,
        linkNameTwo: PropTypes.string,
      })
    ),
  }),
};

export default HeroImage;
