import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import useLocationGlobal from '../hooks/useLocationGlobal';
import { useLocation } from '@reach/router';

import { useClickOutside } from './hooks/useClickOutside';

const LanguageSwitcher = ({ className, toggleMenu, menuOpen, theme, root }) => {
  const linkPath = useLocation().pathname;
  const location = useLocationGlobal();
  const container = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    location === '/' ? 'De' : location
  );

  const currentLink =
    linkPath.startsWith('/de/') ||
    linkPath.startsWith('/en/') ||
    linkPath.startsWith('/es/')
      ? linkPath.substring(3)
      : linkPath;

  useClickOutside(container, () => setOpen(false), open);

  return (
    <div ref={container} className={'relative ' + className}>
      <button
        className={
          'relative border h-8 rounded-3xl z-20 ' +
          (theme === 'light' && !open
            ? 'border-white'
            : root === 'marbet'
            ? 'border-secondary bg-white'
            : 'border-gray-g4')
        }
        style={{ width: 50 }}
        onClick={() => setOpen(!open)}
      >
        <span
          className={
            'text-p3 font-bold capitalize ' +
            (theme === 'light' && !open
              ? 'text-white'
              : root === 'marbet'
              ? 'text-secondary'
              : 'text-gray-g4')
          }
        >
          {selectedLanguage}
        </span>
      </button>
      <div
        className={
          'absolute h-auto bg-white border border-secondary rounded-lg px-1 pt-7 pb-1 z-10 shadow-lg top-1 right-1/2 translate-x-1/2 ' +
          (open ? 'block' : 'hidden')
        }
        style={{ width: 50 }}
      >
        <button
          className='relative flex justify-center gap-x-4 items-center rounded-lg hover:bg-gray-g1'
          style={{ width: 42, height: 26 }}
          onClick={() => {
            setOpen(false);
            setSelectedLanguage('De');
            navigate(`/de${currentLink}`);
            if (menuOpen) toggleMenu();
          }}
        >
          <span className='hover:text-blue-informative text-p3 text-secondary'>
            De
          </span>
        </button>
        <button
          className='relative flex justify-center gap-x-4 items-center rounded-lg hover:bg-gray-g1'
          style={{ width: 42, height: 26 }}
          onClick={() => {
            setOpen(false);
            setSelectedLanguage('En');
            navigate(`/en${currentLink}`);
            if (menuOpen) toggleMenu();
          }}
        >
          <span className='hover:text-blue-informative text-p3 text-secondary'>
            En
          </span>
        </button>

        <button
          className='relative flex justify-center gap-x-4 items-center rounded-lg hover:bg-gray-g1'
          style={{ width: 42, height: 26 }}
          onClick={() => {
            setOpen(false);
            setSelectedLanguage('Es');
            navigate(`/es${currentLink}`);
            if (menuOpen) toggleMenu();
          }}
        >
          <span className='hover:text-blue-informative text-p3 text-secondary'>
            Es
          </span>
        </button>
      </div>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  toggleMenu: PropTypes.func,
  menuOpen: PropTypes.bool,
  theme: PropTypes.string
};

LanguageSwitcher.defaultProps = {
  toggleMenu: () => null,
  menuOpen: false,
  theme: 'dark'
};
export default LanguageSwitcher;
