import { useLocation } from '@reach/router';

const useLocationGlobal = () => {
  const { pathname, search } = useLocation();
  const locale = pathname.split('/')[1];

  switch (locale) {
    case 'de':
      return 'de';
    case 'en':
      return 'en';
    case 'es':
      return 'es';
    default:
      const urlParams = new URLSearchParams(search);

      if (urlParams.size > 0) {
        const languageValue = urlParams.get('lang');

        if (languageValue) {
          return languageValue;
        }
      }
      return 'de';
  }
};

export default useLocationGlobal;
