// Dependencies
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Container from '../../components/containers/Container';
import Overline from '../../components/Overline';

// helpers
import { withVisibility } from '../../components/hooks/withVisibility';
import RevealText from '../../components/RevealText';
import gsap from 'gsap';

const NewsIntroText = ({ content, viewed }) => {
  let { current: textEl } = useRef([]);
  const { newsIntroText } = content;
  const { overline, headline, subheadline } = newsIntroText[0];

  useEffect(() => {
    gsap.set(textEl, { y: '100%' });
  }, []);

  useEffect(() => {
    if (viewed) {
      gsap.to(textEl, {
        duration: 1.5,
        ease: 'expo.inOut',
        y: '0%',
        rotation: 0.001,
        stagger: 0.1
      });
    }
  }, [viewed]);

  return (
    <Container
      type='wide'
      className='my-12 md:my-16 lg:my-20 xl:px-0 max-w-[1080px]'
    >
      <RevealText forwardedRef={textEl}>
        <Overline
          text={overline}
          dotColor='bg-highlight'
          textColor='text-highlight'
        />
      </RevealText>
      <h2 className='mt-5'>
        <RevealText forwardedRef={textEl} text={headline} />
      </h2>
      <h3 className='mt-2 font-serif italic text-highlight'>
        <RevealText forwardedRef={textEl} text={subheadline} />
      </h3>
    </Container>
  );
};

NewsIntroText.propTypes = {
  content: PropTypes.shape({
    newsIntroText: PropTypes.arrayOf(
      PropTypes.shape({
        overline: PropTypes.string,
        headline: PropTypes.string,
        subheadline: PropTypes.string
      })
    )
  })
};

export default withVisibility(NewsIntroText);
