import React from "react"
import PropTypes from "prop-types"

function Whatsapp({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M20.4703 3.45C18.2203 1.2 15.3703 0 12.0703 0C5.62031 0 0.220313 5.4 0.220313 11.85C0.220313 13.95 0.820313 16.05 1.87031 17.85L0.0703125 24L6.37031 22.35C8.17031 23.25 10.1203 23.85 12.0703 23.85C18.6703 23.85 23.9203 18.45 23.9203 12C24.0703 8.7 22.7203 5.7 20.4703 3.45ZM17.9203 16.2C17.6203 16.95 16.4203 17.55 15.9703 17.55C15.5203 17.7 14.0203 17.55 12.3703 16.8C9.37031 15.45 7.42031 12.45 7.27031 12.3C7.12031 12.15 6.07031 10.65 6.07031 9.3C6.07031 7.95 6.82031 7.05 7.12031 6.75C7.72031 6.15 8.92031 6.3 9.22031 6.9C9.37031 7.5 9.97031 9 10.1203 9.15C10.2703 9.3 10.2703 9.9 9.82031 10.2C9.67031 10.2 8.92031 10.8 9.22031 11.25C9.37031 11.55 9.97031 12.45 10.8703 13.35C12.0703 14.4 12.9703 14.7 13.2703 14.85C13.5703 15 13.7203 15 13.8703 14.7C14.0203 14.55 14.6203 13.8 14.7703 13.5C14.9203 13.2 15.2203 13.2 15.3703 13.35C15.5203 13.5 17.1703 14.1 17.4703 14.25C17.7703 14.55 18.0703 14.55 18.0703 14.7C18.2203 14.85 18.2203 15.45 17.9203 16.2Z" 
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

Whatsapp.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Whatsapp.defaultProps = {
  color: "#767171",
  width: 24,
  height: 24,
}

export default Whatsapp;
