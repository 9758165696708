import React from 'react'
import PropTypes from 'prop-types'

const MetaInfo = ({ title, subtitleColor, titleColor, subtitle, ...props }) => {
  const { className } = props

  return (
    <div className={className + "flex flex-col gap-y-1"}>
      <p className={`text-b2 font-bold uppercase tracking-widest ${titleColor}`}>{title}</p>
      <p className={`text-p3 md:text-p ${subtitleColor}`}>{subtitle}</p>
    </div>
  )
}

MetaInfo.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
}

export default MetaInfo;