import React, { useEffect, useRef } from "react"
import LazyLoad, { forceCheck } from "react-lazyload"

const Image = ({ Component, simple, children, forceReveal, immediate, ...props }) => {
  const ref = useRef(null)

  const onLoad = e => {
    e.target.style.opacity = 1
  }

  useEffect(() => {
    if (ref.current?.complete) {
      ref.current.style.opacity = 1
    }
  }, [])

  useEffect(() => {
    if (forceReveal) {
      forceCheck()
    }
  }, [forceReveal])

  if (Component) {
    return (
      <LazyLoad once offset={400}>
        <Component {...props} ref={ref} onLoad={e => onLoad(e)} />
      </LazyLoad>
    )
  } else if (simple) {
    return <img {...props} style={{ opacity: 0, transition: "opacity .3s ease-in-out" }} ref={ref} onLoad={e => onLoad(e)} />
  } else {
    if (immediate) {
      return (
        <picture ref={ref} {...props} onLoad={e => onLoad(e)}>
          {children}
        </picture>
      )
    } else
      return (
        <LazyLoad once offset={400}>
          <picture ref={ref} {...props} onLoad={e => onLoad(e)}>
            {children}
          </picture>
        </LazyLoad>
      )
  }
}

export default Image
