import React from "react"
import PropTypes from "prop-types"

function ArrowRight({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 17 12">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M10.47.47a.75.75 0 011.06 0l5 5a.75.75 0 010 1.06l-5 5a.75.75 0 11-1.06-1.06l3.72-3.72H1a.75.75 0 010-1.5h13.19l-3.72-3.72a.75.75 0 010-1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

ArrowRight.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

ArrowRight.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default ArrowRight
