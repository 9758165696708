import React from 'react';
import PropTypes from 'prop-types';
import LinkChoice from '../LinkChoice';

const ListBlock = ({ title, list, forwardedRef, ...props }) => {
  return (
    <div
      ref={forwardedRef}
      className='flex flex-col justify-start items-start'
      {...props}
    >
      <p className='text-base font-bold'>{title}</p>
      <ul>
        {list.map((item, i) => {
          return (
            // <LinkChoice
            //   key={item.linkName || item.name + '-' + i}
            //   to={item.linkAddress}
            // >
            //   <li
            //     className='text-p2 text-gray-g4 my-2'
            //     key={item.linkName || item.name + '-' + i}
            //   >
            //     {item.linkName || item.name}
            //   </li>
            // </LinkChoice>
            <li className='my-2' key={item.linkName || item.name + '-' + i}>
              <LinkChoice
                to={item.linkAddress}
                className='text-p2 text-gray-g4 '
              >
                {item.linkName || item.name}
              </LinkChoice>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  listRef: PropTypes.array,
  darkMode: PropTypes.bool
};

export default ListBlock;
