import React from "react"
import PropTypes from "prop-types"

function MinusIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 50 8">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M0 .667h50v6.666H0V.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

MinusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

MinusIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default MinusIcon
