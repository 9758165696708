import React from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import LinkChoice from "./LinkChoice";

const CasesGridCard = ({ card }) => {
  const { title, caseYear, client, highlightImage, newsImageGallery, slug } =
    card;

  return (
    <div
      className={`group rounded cursor-pointer relative overflow-hidden w-full h-60 sm:h-96 xl:h-full bg-gray-g1`}
    >
      <LinkChoice to={"/cases/" + slug}>
        <div className="rounded absolute w-full h-full p-8 transition-all duration-200 group-hover:bg-highlight z-10">
          <div className="rounded transition-all opacity-0 duration-200 group-hover:opacity-100">
            <h5 className="text-white break-words">{title}</h5>
            <p className="text-white">
              {client + " | " + new Date(caseYear).getFullYear()}
            </p>
          </div>
        </div>
        <div
          className="absolute left-3 top-3 sm:left-6 sm:top-6 z-20 bg-highlight min-w-min w-auto rounded flex justify-center items-center text-white font-bold px-3 py-1 group-hover:opacity-0 transition-opacity"
          style={{ maxWidth: "80%" }}
        >
          <p className="text-p3 sm:text-base">{client}</p>
        </div>
        {(highlightImage.length > 0 || newsImageGallery.length > 0) && (
          <Image
            simple
            src={
              highlightImage && highlightImage[0]?.mimeType.includes("image")
                ? highlightImage[0].url
                : newsImageGallery && newsImageGallery.length
                ? newsImageGallery[0].url
                : null
            }
            alt={title}
            className="absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full"
          />
        )}
      </LinkChoice>
    </div>
  );
};

export default CasesGridCard;

CasesGridCard.propTypes = {
  card: PropTypes.object,
};
