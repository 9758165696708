import React from "react"
import PropTypes from "prop-types"

function CoinIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 117 117">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M25.832 9.796A58.125 58.125 0 0158.126 0a58.189 58.189 0 0158.125 58.125 58.124 58.124 0 11-90.418-48.33zM30 100.218a50.623 50.623 0 0028.126 8.532 50.68 50.68 0 0050.625-50.625A50.623 50.623 0 0048.249 8.473a50.626 50.626 0 00-18.25 91.745zm2.084-81.068a46.875 46.875 0 0126.042-7.9A46.928 46.928 0 01105 58.125 46.874 46.874 0 1132.083 19.15zm45.465 9.052l-4.408 6.065a1.875 1.875 0 103.032 2.205l4.41-6.065a1.875 1.875 0 10-3.034-2.205zm-40.8-.723a1.875 1.875 0 00-1.074 2.928l4.41 6.065a1.875 1.875 0 103.032-2.205l-4.408-6.065a1.875 1.875 0 00-1.96-.723zM24.833 70.916l7.125-2.315a1.873 1.873 0 001.29-2.389 1.878 1.878 0 00-1.703-1.294c-.254-.01-.507.03-.744.12l-7.125 2.316a1.875 1.875 0 001.157 3.562zm34.618 24.16c.352-.352.549-.829.549-1.326v-7.5a1.875 1.875 0 10-3.75 0v7.5a1.875 1.875 0 003.2 1.326zM71.392 62.46l10.546-10.279h-.004a1.875 1.875 0 00-1.039-3.187l-14.572-2.119-6.525-13.213a1.95 1.95 0 00-3.36 0l-6.51 13.213-14.577 2.119a1.875 1.875 0 00-1.038 3.187l10.55 10.279-2.488 14.512a1.876 1.876 0 002.719 1.975l13.031-6.851 13.035 6.85a1.875 1.875 0 002.72-1.974L71.394 62.46zm20.037 8.456c.186.06.381.092.577.092l.01.004a1.875 1.875 0 00.579-3.658l-7.134-2.316a1.875 1.875 0 10-1.157 3.563l7.125 2.315z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="58.125"
          x2="3.348"
          y1="3.875"
          y2="155.205"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="fill-current" stopColor={color}></stop>
          <stop offset="1" className="fill-current" stopColor={color} stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

CoinIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

CoinIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default CoinIcon
