import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import gsap from "gsap"
import { useLocation } from "@reach/router"

// Components
import Container from "../../components/containers/Container"
import RevealText from "../../components/RevealText"
import Button from "../../components/buttons/Button"
import RadioButton from "../../components/buttons/RadioButton"

// Cake images

// German
import AAAde from "../../assets/images/cakes/einmalig-live-national.jpg"
import AABde from "../../assets/images/cakes/einmalig-live-international.jpg"
import ABAde from "../../assets/images/cakes/einmalig-hybrid-national.jpg"
import ABBde from "../../assets/images/cakes/einmalig-hybrid-international.jpg"
import BAAde from "../../assets/images/cakes/mehrstufig-live-national.jpg"
import BABde from "../../assets/images/cakes/mehrstufig-live-international.jpg"
import BBAde from "../../assets/images/cakes/mehrstufig-hybrid-national.jpg"
import BBBde from "../../assets/images/cakes/mehrstufig-hybrid-international.jpg"

// Spanish
import AAAes from "../../assets/images/cakes/unico-live-nacional.jpg"
import AABes from "../../assets/images/cakes/unico-live-internacional.jpg"
import ABAes from "../../assets/images/cakes/unico-digital-nacional.jpg"
import ABBes from "../../assets/images/cakes/unico-digital-internacional.jpg"
import BAAes from "../../assets/images/cakes/varios-live-nacional.jpg"
import BABes from "../../assets/images/cakes/varios-live-internacional.jpg"
import BBAes from "../../assets/images/cakes/varios-digital-nacional.jpg"
import BBBes from "../../assets/images/cakes/varios-digital-internacional.jpg"

const BoldHeader = styled.h1`
  & > * {
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    color: #ff4619;
    cursor: none;
  }
`

const allCakes = {
  german: {
    AAA: AAAde,
    AAB: AABde,
    ABA: ABAde,
    ABB: ABBde,
    BAA: BAAde,
    BAB: BABde,
    BBA: BBAde,
    BBB: BBBde,
  },
  spanish: {
    AAA: AAAes,
    AAB: AABes,
    ABA: ABAes,
    ABB: ABBes,
    BAA: BAAes,
    BAB: BABes,
    BBA: BBAes,
    BBB: BBBes,
  },
}

const Cake = ({ content: { cakeModule } }) => {
  const {
    headline,
    textContent,
    cake,
    questionOne,
    answerOneA,
    answerOneB,
    questionTwo,
    answerTwoA,
    answerTwoB,
    questionThree,
    answerThreeA,
    answerThreeB,
    buttonText,
  } = cakeModule[0]

  const cakeRef = useRef()
  const [times, setTimes] = useState("")
  const [mode, setMode] = useState("")
  const [place, setPlace] = useState("")
  const [selectionA, setSelectionA] = useState("")
  const [selectionB, setSelectionB] = useState("")
  const [selectionC, setSelectionC] = useState("")
  const [finalSelection, setFinalSelection] = useState("")
  const [selectedCake, setSelectedCake] = useState(null)
  const location = useLocation().pathname

  let selectionDone = finalSelection.length === 3

  const updateSelection = question => {
    switch (question) {
      case 1:
        if (times) setSelectionA(times)
        else setSelectionA("")
        break
      case 2:
        if (mode) setSelectionB(mode)
        else setSelectionB("")
        break
      case 3:
        if (place) setSelectionC(place)
        else setSelectionC("")
        break
    }
  }

  const triggerCake = () => {
    setSelectedCake(getCakeImg(finalSelection))
  }

  const getCakeImg = selectedCake => {
    const cakes = location.includes("es") ? allCakes.spanish : allCakes.german

    return cakes[selectedCake]
  }

  useEffect(() => {
    updateSelection(1)
  }, [times])

  useEffect(() => {
    updateSelection(2)
  }, [mode])

  useEffect(() => {
    updateSelection(3)
  }, [place])

  useEffect(() => {
    setFinalSelection(`${selectionA}${selectionB}${selectionC}`)
  }, [selectionA, selectionB, selectionC])

  useEffect(() => {
    gsap.to(cakeRef.current, { height: "auto", opacity: 1, duration: 0.4, delay: 0.15 })
  }, [selectedCake])

  return (
    <div className="bg-white py-16 lg:py-28">
      <Container fullWidth className="max-w-screen-2xl">
        <RevealText>
          <BoldHeader className="text-h6 md:text-h3 xl:text-h2 2xl:text-h1 cursor-default" dangerouslySetInnerHTML={{ __html: headline }} />
        </RevealText>
        <h6 className="text-secondary font-bold mt-8 mb-14 md:mt-14">{textContent}</h6>
        {cake && (
          <div className="flex flex-col w-full space-y-12">
            {/* Quiz grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
              <p className="font-bold">{questionOne}</p>
              <div>
                <RadioButton
                  className="mb-2"
                  label={answerOneA}
                  modelValue={times}
                  radioValue="A"
                  onChange={() => setTimes(times === "A" ? "" : "A")}
                />
                <RadioButton label={answerOneB} modelValue={times} radioValue="B" onChange={() => setTimes(times === "B" ? "" : "B")} />
              </div>
              <p className="font-bold">{questionTwo}</p>
              <div>
                <RadioButton className="mb-2" label={answerTwoA} modelValue={mode} radioValue="A" onChange={() => setMode(mode === "A" ? "" : "A")} />
                <RadioButton label={answerTwoB} modelValue={mode} radioValue="B" onChange={() => setMode(mode === "B" ? "" : "B")} />
              </div>
              <p className="font-bold">{questionThree}</p>
              <div>
                <RadioButton
                  className="mb-2"
                  label={answerThreeA}
                  modelValue={place}
                  radioValue="A"
                  onChange={() => setPlace(place === "A" ? "" : "A")}
                />
                <RadioButton label={answerThreeB} modelValue={place} radioValue="B" onChange={() => setPlace(place === "B" ? "" : "B")} />
              </div>
            </div>

            <div className="flex flex-col items-center">
              <Button label={buttonText} size="large" action={() => triggerCake()} disabled={!selectionDone} />
              {selectedCake && (
                <div ref={cakeRef} className="h-0 opacity-0">
                  <img src={selectedCake} />
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

Cake.propTypes = {
  content: PropTypes.object.isRequired,
}

export default Cake
