import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionItem from './AccordionItem';
import Container from '../../components/containers/Container';

const AccordionWrapper = styled.ul`
  li {
    max-width: 1920px;
    list-style-type: none;
    border-bottom-width: 1px;
    border-color: #d9d9d9;
    margin: auto;
  }
`;

const Accordion = ({ content: { plainText, accordion, root }, ...props }) => {
  const { className, ...addonProps } = props;
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className='py-16 lg:py-28'>
      <Container>
        <h3 className='mb-10 lg:mb-20'>{plainText}</h3>
      </Container>
      <AccordionWrapper className={className || ''} {...addonProps}>
        {accordion.map((item, index) => {
          return (
            <AccordionItem
              key={item.header + index}
              header={item.header}
              headerHover={item.headerHover}
              body={item.body}
              icon={item.icon}
              selectedItem={activeItem}
              toggleContent={setActiveItem}
              index={index}
              root={root}
            />
          );
        })}
      </AccordionWrapper>
    </div>
  );
};
Accordion.propTypes = {
  content: PropTypes.shape({
    accordion: PropTypes.array,
    plainText: PropTypes.string
  })
};

Accordion.defaultProps = {
  items: []
};

export default Accordion;
