import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OverlayHeader = styled.div`
  color: white;

  strong {
    font-style: italic;
    font-family: 'Orbikular';
    color: #262626;
  }
`;

const ContactOverlayText = ({ headline, imprint }) => {
  return (
    <div>
      <OverlayHeader dangerouslySetInnerHTML={{ __html: headline }} />
      <div
        className='text-white'
        dangerouslySetInnerHTML={{ __html: imprint }}
      ></div>
    </div>
  );
};
export default ContactOverlayText;

ContactOverlayText.propTypes = {
  headline: PropTypes.string,
  imprint: PropTypes.string
};
