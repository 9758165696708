import React from "react"
import PropTypes from "prop-types"

function HandHeartIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 120 120">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M72.407 53.401a1.876 1.876 0 002.046-.408L95.906 31.47a15.924 15.924 0 10-22.78-22.125 15.922 15.922 0 10-22.863 22.057l21.535 21.591c.174.175.38.313.609.408zm39.942 17.41a8.273 8.273 0 014.882 4.034 8.534 8.534 0 01-2.537 10.644l-20.91 16.191a11.24 11.24 0 01-4.159 2.017l-35.747 8.936a18.747 18.747 0 01-13.312-1.62L19.152 99.679a18.75 18.75 0 00-8.774-2.179H3.816a1.875 1.875 0 01-1.875-1.875V63.608a1.875 1.875 0 011.72-1.875l16.687-1.391a18.75 18.75 0 017.965 1.065l14.552 5.29c1.465.534 2.991.882 4.543 1.038l27.68 2.765a8.308 8.308 0 015.35 13.829 8.312 8.312 0 01-5.306 2.705L46.947 90l-.197.023a1.875 1.875 0 00.187 3.739c.066 0 .132-.004.197-.012l28.554-3.01a11.787 11.787 0 009.713-7.246l20.269-11.869a8.274 8.274 0 016.679-.814z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="60.033"
          x2="10.706"
          y1="7.269"
          y2="151.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="fill-current" stopColor={color}></stop>
          <stop offset="1" className="fill-current" stopColor={color} stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

HandHeartIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

HandHeartIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default HandHeartIcon
