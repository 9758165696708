import React from "react"
import PropTypes from "prop-types"

function IngredientsIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="fill-current"
        fill={color}
        d="M18.7826 39.4285H19.8261V38.5714C19.8261 37.8895 19.4963 37.2351 18.9093 36.7531C18.3223 36.271 17.5257 36 16.6957 36H15.6522V36.8571C15.6522 37.539 15.982 38.1934 16.569 38.6755C17.1559 39.1575 17.9526 39.4285 18.7826 39.4285Z"
      />
      <path className="fill-current" fill={color} d="M19.8261 41.1428H8.3479V48H39.6521V41.1428H19.8261Z" />
      <path
        className="fill-current"
        fill={color}
        d="M45.3536 29.9998C44.6521 29.9983 43.9785 30.2275 43.4828 30.6358L32.7797 39.4284H40.2634L47.2244 33.7097H47.2249C47.721 33.3021 48 32.7492 48 32.1729C48 31.5962 47.721 31.0437 47.2249 30.6361C46.7287 30.2282 46.0556 29.9994 45.3536 29.9998L45.3536 29.9998ZM41.7392 36.857H39.6522V35.1427H41.7392V36.857ZM45.9131 33.4284H43.8261V31.7141H45.9131V33.4284Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M6.26092 33.931C6.25999 34.4794 5.99446 35.0055 5.52211 35.3932C5.05022 35.7812 4.40969 35.9993 3.74212 36.0001H2.51881C1.85126 35.9993 1.21071 35.7812 0.738821 35.3932C0.266467 35.0055 0.000935662 34.4794 5.47922e-06 33.931V33.7833C-0.00139206 33.2342 0.264606 32.7073 0.738821 32.3204C1.60481 31.6121 2.09021 30.649 2.08699 29.6452C2.08745 29.3678 2.3609 29.1432 2.69863 29.1428H3.56276H3.56229C3.90002 29.1432 4.17347 29.3678 4.17394 29.6452C4.17068 30.6489 4.65608 31.6121 5.52211 32.3204C5.99633 32.7073 6.26232 33.2342 6.26092 33.7833V33.931Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M3.90567 11.3202C4.40225 10.9127 5.07492 10.6838 5.77688 10.6838C6.47843 10.6838 7.15105 10.9127 7.64774 11.3202L8.47507 11.9998H9.95084C10.781 11.9998 11.5771 12.2707 12.1641 12.7528C12.7515 13.235 13.0813 13.8894 13.0813 14.5712V15.4283H15.3467C15.8526 15.4283 16.338 15.5932 16.6957 15.8871C17.0539 16.181 17.2547 16.5797 17.2547 16.9952C17.2547 17.4108 17.0534 17.8095 16.6957 18.1033L15.1668 19.3592L11.7317 16.5368L10.2564 17.7486L13.6924 20.571L12.1635 21.8269H12.164C11.8058 22.1204 11.3208 22.2849 10.815 22.2849C10.3091 22.2845 9.82416 22.1196 9.46635 21.8257C9.10858 21.5322 8.90781 21.1339 8.90734 20.7183V18.8567H7.86387C7.03329 18.8567 6.23712 18.5858 5.65027 18.1036C5.06342 17.6215 4.73351 16.9671 4.73351 16.2853V15.0734L3.90572 14.3935C3.41007 13.9855 3.13196 13.433 3.13196 12.8567C3.13196 12.2804 3.41006 11.7279 3.90572 11.3199L3.90567 11.3202Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M31.3043 32.5715H28.1738V30.8572H31.3043V32.5715ZM33.9129 26.5715C34.7775 26.5715 35.4782 27.147 35.4782 27.8572C35.4782 28.5674 34.7775 29.143 33.9129 29.143H25.5652C24.7006 29.143 23.9999 28.5674 23.9999 27.8572C23.9999 27.147 24.7006 26.5715 25.5652 26.5715H28.1738V24.8572C28.1738 24.147 28.8745 23.5715 29.739 23.5715C30.6036 23.5715 31.3043 24.147 31.3043 24.8572V26.5715H33.9129Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M15.6522 28.2856C15.6522 28.9675 15.3224 29.6219 14.7354 30.1039C14.1484 30.586 13.3518 30.857 12.5218 30.857H11.4783V29.9998C11.4783 29.318 11.8081 28.6636 12.3951 28.1815C12.9821 27.6995 13.7787 27.4285 14.6087 27.4285H15.6522V28.2856Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M40.6956 20.5716C40.6961 19.597 41.1009 18.6514 41.8434 17.8912C42.586 17.1309 43.6215 16.6012 44.7796 16.3896C44.6505 16.9483 44.3859 17.4802 44.0012 17.9532C43.2064 18.9312 42.782 20.0811 42.7825 21.2573V22.2859C42.7825 22.7592 42.3153 23.143 41.739 23.143C41.1628 23.143 40.6956 22.7592 40.6956 22.2859L40.6956 20.5716Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M17.7391 0.857142C18.6433 0.302292 19.7408 0.00152816 20.8696 0H22.9565C23.5328 0 24 0.383804 24 0.857142C24 1.33048 23.5328 1.71428 22.9565 1.71428H21.7043C20.2714 1.71314 18.8702 2.06174 17.6786 2.71533C17.1028 3.0314 16.4557 3.24875 15.7751 3.35474C16.0481 2.35712 16.7441 1.47167 17.7391 0.857172V0.857142Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M30.7617 9.2856L30.3215 12.0001H28.174V13.7144H30.0429L29.5025 17.0454C29.4145 17.593 28.8462 18.0021 28.1739 18.0021C27.5017 18.0021 26.9335 17.593 26.8453 17.0454L25.7487 10.2859H28.1739V8.57158H25.6309C25.8135 7.84033 26.4471 7.23992 27.3024 6.98777C28.1581 6.73599 29.1117 6.86838 29.8184 7.3379C30.5251 7.80742 30.8829 8.54554 30.7617 9.28562L30.7617 9.2856Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M37.5651 12H38.6086C39.1849 12 39.6521 12.3838 39.6521 12.8571C39.6521 13.3305 39.1849 13.7143 38.6086 13.7143H37.5651C36.9889 13.7143 36.5217 13.3305 36.5217 12.8571C36.5217 12.3838 36.9889 12 37.5651 12Z"
      />
      <path
        className="fill-current"
        fill={color}
        d="M18.7826 10.2858H17.7392C17.1629 10.2858 16.6957 9.90201 16.6957 9.42868C16.6957 8.95534 17.1629 8.57153 17.7392 8.57153H18.7826C19.3589 8.57153 19.8261 8.95534 19.8261 9.42868C19.8261 9.90201 19.3589 10.2858 18.7826 10.2858Z"
      />
      <path className="fill-current" fill={color} d="M13.6201 20.6126L15.0773 19.3855L20.0715 23.3861L18.6144 24.6133L13.6201 20.6126Z" />
      <path className="fill-current" fill={color} d="M40.6956 24.8572H42.7825V26.5715H40.6956V24.8572Z" />
      <path className="fill-current" fill={color} d="M26.0869 0H28.1739V1.71428H26.0869V0Z" />
      <path className="fill-current" fill={color} d="M27.1304 3.42847H29.2174V5.14275H27.1304V3.42847Z" />
    </svg>
  )
}

IngredientsIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

IngredientsIcon.defaultProps = {
  color: "#fff",
  width: 48,
  height: 48,
}

export default IngredientsIcon
