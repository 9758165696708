import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TypingHeader = ({ fixed, words }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);

  // typeWriter
  useEffect(() => {
    if (
      subIndex === words[index].length + 1 &&
      index !== words.length - 1 &&
      !reverse
    ) {
      setReverse(true);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prev) => prev + 1);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + (reverse ? -1 : 1));
    }, 75);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  // blinker
  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return (
    <div className='w-full lg:h-60'>
      {fixed} <br />{' '}
      <span className='font-serif italic text-highlight'>{`${words[
        index
      ].substring(0, subIndex)}${blink ? '|' : ''}`}</span>
    </div>
  );
};

export default TypingHeader;

TypingHeader.propTypes = {
  fixed: PropTypes.node,
  words: PropTypes.array
};
