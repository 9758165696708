import React, { useState } from 'react';
import Facebook from '../components/icons/social/Facebook';
import LinkedIn from '../components/icons/social/LinkedIn';
import Whatsapp from '../components/icons/social/Whatsapp';
import Twitter from '../components/icons/social/Twitter';
import Email from '../components/icons/social/Email';
import Link from '../components/icons/social/Link';
import Check from '../components/icons/Check';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

const StickyShareBar = ({ shareLink, serviceTags }) => {
  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement('input');
    el.value = shareLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <div className='md:sticky md:bottom-[200px] md:h-24 flex md:justify-end w-full md:w-auto md:mr-12 2xl:mr-32 items-center lg:mt-36'>
      <div className='flex md:flex-col justify-around md:space-y-6 text-gray-g3 w-full md:w-auto'>
        <WhatsappShareButton
          url={shareLink}
          title={'Check out this article I found!'}
        >
          <Whatsapp />
        </WhatsappShareButton>
        <EmailShareButton
          url={shareLink}
          body='Check out this article I found!'
        >
          <Email />
        </EmailShareButton>
        <button
          onClick={copy}
          className='flex flex-col space-x-4'
          title='Copy to clipboard'
        >
          {!copied ? <Link /> : <Check width={24} height={24} />}
        </button>
        <LinkedinShareButton
          url={shareLink}
          summary='Check out this article I found!'
        >
          <LinkedIn />
        </LinkedinShareButton>
        <FacebookShareButton
          url={shareLink}
          // deprecated
          // quote='Check out this article I found!'
          hashtag='#marbet'
        >
          <Facebook />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareLink}
          title='Check out this article I found!'
          hashtags={['#marbet']}
        >
          <Twitter />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default StickyShareBar;
