import React from 'react';
import PropTypes from 'prop-types';

import LinkChoice from '../../components/LinkChoice';

export const ImprintBar = ({ className }) => {
  const handleClick = (event) => {
    event.preventDefault();
    UC_UI.showSecondLayer();
  };
  return (
    <div
      className={
        className +
        ' flex lg:flex-col xl:flex-row space-x-3 mt-4 xl:mt-16 w-full justify-start items-start xl:items-center'
      }
      style={{ fontSize: 10 }}
    >
      <LinkChoice to={'/imprint/'}>imprint</LinkChoice>
      <LinkChoice to={'/privacy-policy/'}>privacy</LinkChoice>
      <a href='#' onClick={handleClick}>
        cookies
      </a>
      <LinkChoice to={'/gender-disclaimer/'}>gender disclaimer</LinkChoice>
      {/* <LinkChoice to={"/terms-conditions/"}>terms</LinkChoice> */}
    </div>
  );
};

export const SocialBar = ({ content, className }) => {
  return (
    <div className={className + ' flex gap-x-6 gap-y-3 mt-2'}>
      {content.map((social, index) => {
        return (
          <a
            key={index}
            className='w-max'
            target='_blank'
            rel='noreferrer'
            href={social.socialLink}
          >
            <img
              src={social.icon[0].url}
              alt={social.socialName}
              className='w-6 h-6'
            />
          </a>
        );
      })}
    </div>
  );
};

SocialBar.propTypes = {
  content: PropTypes.array
};
