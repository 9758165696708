import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from '../../components/containers/Container';
import { SmallNewsCard } from '../../components/NewsCards';
import Button from '../../components/buttons/Button';
import ArrowRight from '../../components/icons/ArrowRight';
import { navigateTo } from '../../components/LinkChoice';

const TeaserQuote = styled.h5`
  max-width: 470px;
  & > * {
    font-size: inherit;
    line-height: inherit;
  }

  & strong {
    color: #ff4619;
  }
  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const News = ({ content }) => {
  const { newsSection } = content;
  const { header, buttonLabel, buttonLink, boldText, latestNews } =
    newsSection[0];

  return (
    <Container className='bg-background py-20'>
      <h3 className='text-highlight text-h6 md:text-h3 font-black text-left md:text-center mb-12 md:mb-20 pr-14 md:pr-0'>
        {header}
      </h3>

      <div className='flex flex-col lg:flex-row'>
        {/* Teaser column */}
        <div className='mb-16 lg:mb-0 md:mr-20'>
          <TeaserQuote
            className='mb-10'
            dangerouslySetInnerHTML={{ __html: boldText }}
          />
          <Button
            label={buttonLabel}
            size='large'
            iconSuffix={<ArrowRight />}
            action={() => navigateTo(buttonLink?.url)}
            className='ml-0'
          />
        </div>

        {/* News columns */}
        <div className='flex-grow space-y-16 lg:space-y-0 lg:grid grid-cols-1 xl:grid-cols-2 md:gap-x-8 gap-y-20'>
          {latestNews.map((article) => (
            <SmallNewsCard key={article.id} article={article} />
          ))}
        </div>
      </div>
    </Container>
  );
};

News.propTypes = {
  content: PropTypes.shape({
    newsSection: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        buttonLabel: PropTypes.string,
        buttonLink: PropTypes.object,
        boldText: PropTypes.string,
        latestNews: PropTypes.array
      })
    )
  })
};

export default News;
