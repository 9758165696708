import React from "react";
import PropTypes from "prop-types";
import "./LogoSlider.css";

// Components
import Container from "../../components/containers/Container";
import Slider from "../CardsSlider/Slider";

const setBackgroundColor = (color) => {
  return (
    {
      mGuest: "bg-primary",
      mAway: "bg-primary",
      marbet: "bg-highlight",
      gray: "bg-gray-g2",
    }[color] || "bg-gray-g2"
  );
};

const options = {
  type: "carousel",
  perView: 4,
  gap: 80,
  bullets: false,
  breakpoints: {
    1299: {
      perView: 3,
    },
    889: {
      perView: 3,
      gap: 60,
    },
    630: {
      perView: 2,
      peek: { before: 0, after: 150 },
    },
    500: {
      perView: 1,
      gap: 40,
      peek: { before: 0, after: 150 },
    },
  },
};

/**
 * Component
 */
const LogoSlider = ({ content }) => {
  const { identifier, logoSlider } = content;
  const { headline, clientLogos, backgroundColor } = logoSlider[0];
  let bgColor = setBackgroundColor(content.root);

  return (
    <div id={identifier} className={bgColor}>
      <Container className="pt-10 pb-32 lg:pt-20 lg:pb-44">
        <h2 className="font-serif italic text-white mb-8 lg:mb-14">
          {headline}
        </h2>
        <div>
          <Slider
            element="logoSlider"
            subbrand
            totalCards={clientLogos.length}
            options={options}
          >
            {clientLogos.map((logo, index) => (
              <div key={index}>
                <img className="object-contain" src={logo.url} />
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

LogoSlider.propTypes = {
  content: PropTypes.shape({
    identifier: PropTypes.string,
    logoSlider: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Module headline
         */
        headline: PropTypes.string,
        /**
         * Array containing logo images
         */
        clientLogos: PropTypes.array,
        /**
         * Color of the background continer
         */
        backgroundColor: PropTypes.string,
      })
    ),
  }),
};

export default LogoSlider;
