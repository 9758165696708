import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from '../../components/containers/Container';
import { useHighlightText } from '../../utils/helpers';

export const RichTextMediaWrapper = styled.div`
  h1 {
    word-wrap: break-word;
    margin: 120px 0;
    @media (max-width: 768px) {
      margin: 40px 0;
    }
  }

  h2 {
    word-wrap: break-word;
    margin: 80px 0;
    @media (max-width: 768px) {
      margin: 32px 0;
    }
  }

  h3 {
    word-wrap: break-word;
    color: --color-highlight;
    font-style: italic;
    font-family: 'Orbikular';
    margin-bottom: 32px;
  }

  h5 {
    word-wrap: break-word;
    color: #ff4619;
    font-style: italic;
    font-family: 'Orbikular';
    margin-bottom: 16px;
  }

  h6 {
    word-wrap: break-word;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: #767171;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    word-wrap: break-word;
    color: #262626;
    margin-bottom: 32px;
  }

  a {
    color: #6c9cde;
  }

  ul {
    list-style-position: outside;
    padding-left: 24px;
  }

  li {
    list-style-type: disc;
    color: #767171;
  }
  li::marker {
    font-size: 12px;
  }

  blockquote strong {
    font-size: 32px;
    font-weight: 700;
  }

  figcaption {
    font-size: 12px;
    color: #767171;
  }
`;

const RichTextMedia = ({
  content,
  containerType,
  fullMobile,
  fullMidscreen,
  className,
  ...props
}) => {
  console.log({ content, type: typeof content });
  const filteredContent = useHighlightText(
    content && typeof content === 'object' ? content.richTextMedia : content
  );

  return (
    <Container
      id={content?.identifier || 'rich text'}
      className={'md:px-0 ' + className}
      type={containerType}
      fullMobile={fullMobile}
      fullMidscreen={fullMidscreen}
      {...props}
    >
      <RichTextMediaWrapper
        className='max-w-6xl mx-auto'
        dangerouslySetInnerHTML={{ __html: filteredContent }}
      />
    </Container>
  );
};

RichTextMedia.propTypes = {
  content: PropTypes.any.isRequired,
  containerType: PropTypes.string,
  fullMobile: PropTypes.bool,
  fullMidscreen: PropTypes.bool
};

RichTextMedia.defaultProps = {
  containerType: 'normal',
  fullMobile: false,
  fullMidscreen: false
};

export default RichTextMedia;
