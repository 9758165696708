// Dependencies
import React, { createRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";

// Components
import { backgroundStates, MenuContainer } from "./Menu.styles";
import Container from "../../components/containers/Container";
import ListBlock from "../../components/containers/ListBlock";
import MenuLinkList from "./MenuLinkList";
import MenuAccordion from "../../modules/Accordion/MenuAccordion";

// Helpers
import { BodyLockContext } from "../../components/contexts/BodyLockContext";
import { useMediaQuery } from "../../components/hooks/useMediaQuery";

const Menu = ({
  isOpen,
  isAnimating,
  toggleMenu,
  content,
  backgroundColor,
}) => {
  const [, setBodyLock] = useContext(BodyLockContext);
  const [initialized, setInitialized] = useState(false);
  const containerRef = useRef(null);
  const accordionRef = useRef(null);
  const headlineRefs = useRef(Array(5).fill().map(() => createRef()));
  const listRefs = useRef(Array(5).fill().map(() => createRef()));
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  const has1024px = useMediaQuery("(min-width: 1024px)");

  useEffect(() => {
    // Runs only once
    setIntialState(containerRef.current, isAnimating);
  }, []);

  useEffect(() => {
    // Toggle animation state
    if (!initialized) return;

    if (isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [isOpen]);

  // Animations
  const setIntialState = (element) => {
    gsap.set(element, { background: backgroundStates.initial });
    setBodyLock(false);

    if (!initialized) setInitialized(true);
  };

  const openMenu = () => {
    setBodyLock(true);
    animateContent();
    gsap.to(containerRef.current, {
      background: backgroundStates.open,
      ease: "elastic.out(0.55, 1)",
      duration: 1,
    });
  };

  const closeMenu = () => {
    if (has1024px) {
      const headlineElements = headlineRefs.current.map(
        (headline) => headline.current
      );
      const listElements = listRefs.current.map(
        (headline) => headline.current
      );

      timeline
        .clear()
        .addLabel("start", 0)
        .to(
          headlineElements,
          { y: "100%", duration: 0.5, ease: "power3", stagger: 0.1 },
          "start"
        )
        .set(headlineElements, { opacity: 0 })
        .to(
          listElements,
          {
            rotateY: "90deg",
            x: "50px",
            duration: 0.5,
            ease: "power3",
            stagger: 0.1,
          },
          "start+=0.1"
        )
        .set(listElements, { opacity: 0 })
        .to(
          containerRef.current,
          {
            background: backgroundStates.closed,
            duration: 0.75,
            delay: 0.5,
            onComplete: (containerEl) => {
              setIntialState(containerEl);
            },
            onCompleteParams: [containerRef.current],
          },
          "start"
        )
        .play();

      return;
    }

    timeline
      .clear()
      .addLabel("start", 0)
      .to(
        accordionRef.current,
        {
          x: "50%",
          opacity: 0,
          duration: 0.4,
          ease: "power3",
          stagger: 0.1,
        },
        "start"
      )
      .to(
        containerRef.current,
        {
          background: backgroundStates.closed,
          duration: 0.75,
          delay: 0.5,
          onComplete: (containerEl) => {
            setIntialState(containerEl);
          },
          onCompleteParams: [containerRef.current],
        },
        "start"
      )
      .play();
  };

  const animateContent = () => {
    if (has1024px) {
      const headlineElements = headlineRefs.current.map(
        (headline) => headline.current
      );
      const listElements = listRefs.current.map(
        (headline) => headline.current
      );

      timeline
        .clear()
        .addLabel("start", 0.5)
        .set(headlineElements, { y: "100%", opacity: 1 })
        .set(listElements, { rotateY: "90deg", x: "50px", opacity: 1 })
        .to(
          headlineElements,
          { y: "0%", duration: 1, ease: "power3", stagger: 0.1 },
          "start"
        )
        .to(
          listElements,
          {
            rotateY: "0deg",
            x: "0px",
            duration: 1,
            ease: "power3",
            stagger: 0.1,
          },
          "start+=0.1"
        )
        .play();

      return;
    }

    timeline
      .to("*", { clearProps: true })
      .clear()
      .addLabel("start", 0.5)
      .set(accordionRef.current, { x: "50%", opacity: 0 })
      .to(
        accordionRef.current,
        {
          x: "0%",
          opacity: 1,
          duration: 0.8,
          ease: "power3",
          stagger: 0.1,
        },
        "start"
      )
      .play();
  };

  return (
    <MenuContainer
      ref={containerRef}
      className={
        "fixed left-0 top-0 z-30 w-screen h-screen " +
        (isOpen ? "pointer-events-auto show" : "pointer-events-none")
      }
      style={{ "--color": backgroundColor }}
    >
      <Container className="h-screen pt-24 md:pt-40 xl:pt-0 pb-24 flex justify-start items-start xl:items-end overflow-y-scroll">
        <div className="flex flex-wrap xl:flex-nowrap items-start xl:items-end h-auto xl:h-1/2">
          {has1024px ? (
            <>
              {content.slice(0, 3).map((list, index) => (
                <MenuLinkList
                  onClick={toggleMenu}
                  title={list.plainText}
                  links={list.links}
                  titleRef={headlineRefs.current[index]}
                  listRef={listRefs.current[index]}
                  key={list.plainText}
                />
              ))}

              <div className="small-list flex flex-col justify-start items-start h-full text-white space-y-8 pt-4">
                {content.length > 3 &&
                  content
                    .slice(3, 5)
                    .map((list, index) => (
                      <ListBlock
                        onClick={toggleMenu}
                        title={list.plainText}
                        list={list.links}
                        forwardedRef={listRefs.current[index + 3]}
                        key={list.plainText}
                      />
                    ))}
              </div>
            </>
          ) : (
            <div className="w-full z-20" ref={accordionRef}>
              <MenuAccordion
                className="w-full"
                items={content}
                close={toggleMenu}
              />
            </div>
          )}
        </div>
      </Container>
    </MenuContainer>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  isAnimating: PropTypes.bool,
  toggleMenu: PropTypes.func,
  content: PropTypes.array,
};

export default Menu;
