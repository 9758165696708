import { useEffect, useRef } from 'react';

export const useVideo = (
  ref,
  source,
  togglePlayback,
  classes,
  autoplay = true,
  controls
) => {
  const video = useRef();

  useEffect(() => {
    if (ref.current && source && !video.current) {
      const vid = document.createElement('video');
      vid.id = source;
      vid.className += classes;
      if (autoplay) vid.autoplay = true;
      if (controls) vid.controls = true;
      vid.loop = true;
      vid.defaultMuted = true;
      vid.muted = true; // fixes autoplay in chrome
      vid.setAttribute('playsinline', ''); // fixes autoplay in webkit (ie. mobile safari)

      const sourceEl = document.createElement('source');
      sourceEl.src = source;
      sourceEl.type = 'video/mp4';
      vid.appendChild(sourceEl);
      ref.current.appendChild(vid);
      video.current = vid;
    }
  }, [source, ref]);

  useEffect(() => {
    if (video.current) {
      if (togglePlayback) {
        video.current.play().catch((err) => console.log(err));
      } else {
        video.current.pause();
      }
    }
  }, [togglePlayback, video]);

  return {
    video
  };
};
