import React from "react";
import PropTypes from "prop-types";
import Container from "../../components/containers/Container";
import { CareersCard } from "../../components/NewsCards";
import { navigateTo } from "../../components/LinkChoice";

const TwoColumnCards = ({ content: { twoColumnCards, root } }) => {
  return (
    <Container className="py-16 lg:py-28">
      <h3 className="mb-10 lg:mb-20">
        {root === "mAway" ? "Wo gehören Sie hin?" : "Wo gehörst Du hin?"}
      </h3>
      <div className="w-full flex flex-col lg:flex-row gap-x-6">
        {twoColumnCards.map((item, index) => {
          return (
            <CareersCard
              article={item}
              buttonAction={() => navigateTo(item.buttonLink)}
              key={index}
              className={
                "lg:w-1/2 " +
                (index < twoColumnCards.length - 1 ? "mb-20 lg:mb-0" : "")
              }
            />
          );
        })}
      </div>
    </Container>
  );
};

TwoColumnCards.propTypes = {
  content: PropTypes.shape({
    twoColumnCards: PropTypes.array,
  }),
};

export default TwoColumnCards;
