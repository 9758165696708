// Dependencies
import React, { useEffect, useState } from "react"

// Components
import { Navbar, Menu, ContactOverlay } from "gatsby-shared-ui"

// Helpers
import { BodyLockContextProvider } from "gatsby-shared-ui/components/contexts/BodyLockContext"
import { graphql, useStaticQuery } from "gatsby"
import { setEventListener } from "gatsby-shared-ui/utils/helpers"
import Logo from "../assets/Logo"
import palette from "../../palette"
import useLocationGlobal from "gatsby-shared-ui/hooks/useLocationGlobal"

const Layout = ({ children }) => {
  const locale = useLocationGlobal()
  const [menuOpen, setMenuOpen] = useState()
  const [contactOpen, setContactOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState()

  useEffect(() => {
    // Runs only once
    setEventListener("contact-overlay", e => {
      setContactOpen(e.detail)
    })

    return () => {
      setEventListener("contact-overlay", () => {
        setContactOpen(false)
      })
    }
  }, [])

  const { menu, contact } = useStaticQuery(graphql`
    query MenuQuery {
      menu: craft {
        de: globalSets(siteId: "1", handle: "menuContent") {
          ... on craft_menuContent_GlobalSet {
            menuContent {
              ... on craft_menuContent_linkList_BlockType {
                links {
                  ... on craft_links_link_BlockType {
                    linkName
                    linkAddress {
                      url
                    }
                  }
                }
                plainText
              }
            }
          }
        }
        en: globalSets(siteId: "2", handle: "menuContent") {
          ... on craft_menuContent_GlobalSet {
            menuContent {
              ... on craft_menuContent_linkList_BlockType {
                links {
                  ... on craft_links_link_BlockType {
                    linkName
                    linkAddress {
                      url
                    }
                  }
                }
                plainText
              }
            }
          }
        }
        es: globalSets(siteId: "3", handle: "menuContent") {
          ... on craft_menuContent_GlobalSet {
            menuContent {
              ... on craft_menuContent_linkList_BlockType {
                links {
                  ... on craft_links_link_BlockType {
                    linkName
                    linkAddress {
                      url
                    }
                  }
                }
                plainText
              }
            }
          }
        }
      }
      contact: craft {
        de: globalSets(siteId: "1", handle: "contactOverlay") {
          ... on craft_contactOverlay_GlobalSet {
            language
            address
            contactFormContent {
              ... on craft_contactFormContent_info_BlockType {
                headline
                redactor
                linkName
                linkAddress {
                  url
                }
                plainText
              }
              ... on craft_contactFormContent_formFields_BlockType {
                contactFormField {
                  ... on craft_contactFormField_formField_BlockType {
                    fieldName
                    fieldSize
                    isRequired
                  }
                  ... on craft_contactFormField_dropdown_BlockType {
                    label
                    options
                    isRequired
                  }
                }
              }
            }
          }
        }
        en: globalSets(siteId: "2", handle: "contactOverlay") {
          ... on craft_contactOverlay_GlobalSet {
            language
            address
            contactFormContent {
              ... on craft_contactFormContent_info_BlockType {
                headline
                redactor
                linkName
                linkAddress {
                  url
                }
                plainText
              }
              ... on craft_contactFormContent_formFields_BlockType {
                contactFormField {
                  ... on craft_contactFormField_formField_BlockType {
                    fieldName
                    fieldSize
                    isRequired
                  }
                  ... on craft_contactFormField_dropdown_BlockType {
                    label
                    options
                    isRequired
                  }
                }
              }
            }
          }
        }
        es: globalSets(siteId: "3", handle: "contactOverlay") {
          ... on craft_contactOverlay_GlobalSet {
            language
            address
            contactFormContent {
              ... on craft_contactFormContent_info_BlockType {
                headline
                redactor
                linkName
                linkAddress {
                  url
                }
                plainText
              }
              ... on craft_contactFormContent_formFields_BlockType {
                contactFormField {
                  ... on craft_contactFormField_formField_BlockType {
                    fieldName
                    fieldSize
                    isRequired
                  }
                  ... on craft_contactFormField_dropdown_BlockType {
                    label
                    options
                    isRequired
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const menuContent = menu[locale][0].menuContent
  const contactContent = contact[locale][0].contactFormContent
  const apiEndpoint = contact[locale][0].address

  useEffect(() => {
    // Time animation offset
    if (isAnimating) {
      setTimeout(() => setIsAnimating(false), 1000)
    }
  }, [isAnimating])

  return (
    <BodyLockContextProvider>
      <main className="bg-background">
        <Navbar
          toggleMenu={() => {
            if (!isAnimating) setMenuOpen(!menuOpen)
            setIsAnimating(true)
          }}
          isOpen={menuOpen}
          isAnimating={isAnimating}
          Logo={Logo}
          root="marbet"
        />
        {menuContent && (
          <Menu
            isOpen={menuOpen}
            isAnimating={isAnimating}
            backgroundColor={palette.menu}
            toggleMenu={() => {
              if (!isAnimating) setMenuOpen(!menuOpen)
              setIsAnimating(true)
            }}
            content={menuContent}
          />
        )}
        {/* Contact */}
        {contactContent && (
          <div className="relative">
            <ContactOverlay
              root="marbet"
              className={`fixed z-50 transition ease-in-out duration-700 ${
                contactOpen ? "translate-x-0" : "translate-x-full"
              }`}
              content={contactContent}
              open={contactOpen}
              endpoint={apiEndpoint}
            />
          </div>
        )}
        {children}
        {/* @todo: Add Footer */}
      </main>
    </BodyLockContextProvider>
  )
}

export default Layout
