// Dependencies
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";

//Components
import { Slideshow, clipPathMobile, clipPath } from "./MainHero.styles";
import Container from "../../components/containers/Container";
import Overline from "../../components/Overline";
import SliderNavigation from "../../components/SliderNavigation";
import TextLink from "../../components/TextLink";
import RevealText from "../../components/RevealText";

// Helpers
import { parseHeadline } from "../../utils/helpers";
import { Slide } from "../../utils/Slide";
import HeroSlideVideo from "../../components/HeroSlideVideo";
import ExternalVideo from "../../components/ExternalVideo";

let headlineTO;

const MainHero = ({ content }) => {
  const [activeSlide, setActiveSlide] = useState(2);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("next");
  const [revealed, setRevealed] = useState(false);
  const [slideFullVideo, setSlideFullVideo] = useState(false);
  const videoContainer = useRef([]);
  const { current: slideElements } = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const { heroSlide, identifier } = content;

  useEffect(() => {
    // set initial clip path
    gsap.set(slideElements[activeSlide].DOM.imgWrap, {
      clipPath: clipPath.initial,
    });
  }, [slideElements]);

  useEffect(() => {
    setSlideFullVideo(false);

    let current = activeSlide;
    let pathStates = window.innerWidth > 768 ? clipPath : clipPathMobile;

    if (slideElements.length && direction) {
      // if animating do nothing
      if (isAnimating) {
        return;
      }
      setIsAnimating(true);
      // get the current slide
      const currentSlide = slideElements[current];

      // update current
      if (direction === "next") {
        current = activeSlide < slideElements.length - 1 ? activeSlide + 1 : 0;
        setActiveSlide(
          activeSlide < slideElements.length - 1 ? activeSlide + 1 : 0
        );
      } else {
        current = activeSlide > 0 ? activeSlide - 1 : slideElements.length - 1;
        setActiveSlide(
          activeSlide > 0 ? activeSlide - 1 : slideElements.length - 1
        );
      }

      // now get the upcoming slide
      const upcomingSlide = slideElements[current];

      // animate things...
      gsap
        .timeline({
          // add class current to the upcoming slide (pointer events related)
          onStart: () => {
            if (!revealed) setRevealed(true);
            upcomingSlide.DOM.el.classList.add("slide--current");
          },
          // and remove that class from the currentSlide when the animation ends
          onComplete: () => {
            setIsAnimating(false);
            setDirection(null);
            currentSlide.DOM.el.classList.remove("slide--current");
          },
        })
        .addLabel("start", 0)
        // set the initial styles for the upcoming slide imgWrap: clip path and translateY position
        .set(
          upcomingSlide.DOM.imgWrap,
          {
            y: direction === "next" ? "100%" : "-100%",
            clipPath: pathStates.final,
          },
          "start"
        )
        // also set the opacity of the upcoming slide to 1
        .set(upcomingSlide.DOM.el, { opacity: 1 }, "start")
        // set the initial styles for the upcoming slide img: translateY position
        // same for the texts and link elements
        .set(
          upcomingSlide.DOM.img,
          { y: direction === "next" ? "-50%" : "50%" },
          "start"
        )
        .set(
          upcomingSlide.DOM.text,
          { y: direction === "next" ? "100%" : "-100%" },
          "start"
        )
        // animate the clip path from pathStates.initial to pathStates.final
        .to(
          currentSlide.DOM.imgWrap,
          {
            duration: 1,
            ease: "power3",
            clipPath: pathStates.final,
            rotation: 0.001, // bugfix
          },
          "start"
        )
        // animate the current slide texts out
        .to(
          currentSlide.DOM.text,
          {
            duration: 1,
            ease: "power3",
            y: direction === "next" ? "-100%" : "100%",
          },
          "start"
        )
        // move the current slide away
        .to(
          currentSlide.DOM.imgWrap,
          {
            duration: 1,
            ease: "power2.inOut",
            y: direction === "next" ? "-100%" : "100%",
            rotation: 0.001,
          },
          "start+=0.6"
        )
        .to(
          currentSlide.DOM.img,
          {
            duration: 1,
            ease: "power2.inOut",
            y: direction === "next" ? "50%" : "-50%",
          },
          "start+=0.6"
        )
        // and the upcoming slide in
        .to(
          upcomingSlide.DOM.imgWrap,
          {
            duration: 1,
            ease: "power2.inOut",
            y: "0%",
            rotation: 0.001,
          },
          "start+=0.6"
        )
        .to(
          upcomingSlide.DOM.img,
          {
            duration: 1,
            ease: "power2.inOut",
            y: "0%",
          },
          "start+=0.6"
        )
        // animate the upcoming slide clip path to the initial shape
        .to(
          upcomingSlide.DOM.imgWrap,
          {
            duration: 1.5,
            ease: "expo.inOut",
            clipPath: pathStates.initial,
          },
          "start+=1.2"
        )
        // animate the upcoming slide texts in
        .to(
          upcomingSlide.DOM.text,
          {
            duration: 1.5,
            ease: "expo.inOut",
            y: "0%",
            rotation: 0.001,
            stagger: direction === "next" ? 0.1 : -0.1,
          },
          "start+=1.1"
        );
    }
  }, [direction]);

  const createSlide = (element, index) => {
    let slide = new Slide(element);

    if (slide.DOM) slideElements[index] = slide;
  };

  return (
    <Container id={identifier} className="min-h-screen">
      <Slideshow className="min-h-screen w-screen absolute top-0 left-0 z-0 overflow-hidden bg-highlight">
        {heroSlide.map((slide, i) => {
          return (
            <figure
              key={i}
              ref={(ref) => createSlide(ref, i)}
              className={
                "slide px-6 md:px-24 xl:px-48 " +
                (i === activeSlide ? "slide--current" : "")
              }
            >
              <div className="slide__img-wrap">
                <div
                  ref={(ref) => (videoContainer.current[i] = ref)}
                  className="slide__img"
                  style={{ backgroundImage: `url(${slide.media[0].url})` }}
                >
                  {slide.media[0].mimeType.includes("video") &&
                    videoContainer.current.length === heroSlide.length && (
                      <HeroSlideVideo
                        video={slide.media[0].url}
                        container={{ current: videoContainer.current[i] }}
                        visible={activeSlide === i}
                        className={
                          "w-full h-full absolute right-0 top-0 bg-secondary z-10 transition-opacity duration-500 " +
                          (slideFullVideo ? "opacity-25" : "opacity-50")
                        }
                      />
                    )}
                  {slide?.media?.[0]?.mimeType === "application/json" && (
                    <ExternalVideo
                      media={slide.media[0]}
                      togglePlayback={activeSlide === i}
                      className={
                        "w-full h-full absolute right-0 top-0 bg-secondary z-10 transition-opacity duration-500 " +
                        (slideFullVideo ? "opacity-25" : "opacity-50")
                      }
                      asBackground
                      fullScreen
                    />
                  )}
                </div>
              </div>
              <figcaption
                className={
                  "slide__caption text-white mb-52 md:mb-0 " +
                  (revealed ? "" : "hidden ") +
                  (slideFullVideo
                    ? "opacity-0 transition-opacity duration-500"
                    : "transition-opacity duration-500 delay-1000")
                }
              >
                <RevealText>
                  <Overline
                    size="normal"
                    dotColor="bg-white"
                    textColor="text-white"
                    text={slide.overline}
                  />
                </RevealText>
                <h1 className="slides__caption-headline mb-14 font-black">
                  {parseHeadline(slide.text).map((line) => (
                    <RevealText key={line} text={line} />
                  ))}
                </h1>
                <RevealText>
                  <TextLink text={slide.linkText} link={slide.linkAddress} />
                </RevealText>
              </figcaption>
            </figure>
          );
        })}
      </Slideshow>
      <div className="absolute left-6 sm:left-auto sm:right-28 bottom-12">
        <SliderNavigation
          current={activeSlide}
          total={slideElements.length}
          next={() => {
            clearTimeout(headlineTO);
            setDirection("next");
          }}
          previous={() => {
            clearTimeout(headlineTO);
            setDirection("previous");
          }}
        />
      </div>
    </Container>
  );
};

MainHero.propTypes = {
  content: PropTypes.shape({
    identifier: PropTypes.string,
    heroSlide: PropTypes.array,
  }),
};

export default MainHero;
