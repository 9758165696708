import loadable from "@loadable/component";

export const BoldTextHover = loadable(() =>
  import("./modules/BoldTextHover/BoldTextHover")
);
export const PurposeSlider = loadable(() =>
  import("./modules/PurposeSlider/PurposeSlider")
);

// Modules
export { default as Accordion } from "./modules/Accordion/Accordion";
export { default as FaqAccordion } from "./modules/FaqAccordion/FaqAccordion";
export { default as AudioPlayer } from "./modules/AudioPlayer/AudioPlayer";
export { default as BackgroundVideoContent } from "./modules/BackgroundVideoContent/BackgroundVideoContent";
export { default as BenefitSlider } from "./modules/BenefitSlider/BenefitSlider";
export { default as Cake } from "./modules/Cake/Cake";
export { default as CardsSlider } from "./modules/CardsSlider/CardsSlider";
export { default as CasesGrid } from "./modules/CasesGrid/CasesGrid";
export { default as ContactOverlay } from "./modules/ContactOverlay/ContactOverlay";
export { default as Footer } from "./modules/Footer/Footer";
export { default as HeroImage } from "./modules/HeroImage/HeroImage";
export { default as HighlightImage } from "./modules/HighlightImage/HighlightImage";
export { default as NewsHighlightImage } from "./modules/NewsHighlightImage/NewsHighlightImage";
export { default as NewsGallery } from "./modules/NewsGallery/NewsGallery";
export { default as ImageGallery } from "./modules/ImageGallery/ImageGallery";
export { default as ImageQuoteGrid } from "./modules/ImageQuoteGrid/ImageQuoteGrid";
export { default as JobGrid } from "./modules/JobGrid/JobGrid";
export { default as JobHeader } from "./modules/JobHeader/JobHeader";
export { default as LogoSlider } from "./modules/LogoSlider/LogoSlider";
export { default as MainHero } from "./modules/MainHero/MainHero";
export { default as Menu } from "./modules/Menu/Menu";
export { default as MetaIntroText } from "./modules/MetaIntroText/MetaIntroText";
export { default as MetaStats } from "./modules/MetaStats/MetaStats";
export { default as Navbar } from "./modules/NavBar/NavBar";
export { default as News } from "./modules/News/News";
export { default as NewsGrid } from "./modules/NewsGrid/NewsGrid";
export { default as NewsIntroText } from "./modules/NewsIntroText/NewsIntroText";
export { default as OfficesGrid } from "./modules/OfficesGrid/OfficesGrid";
export { default as RichTextMedia } from "./modules/RichTextMedia/RichTextMedia";
export { default as StickyColumn } from "./modules/StickyColumn/StickyColumn";
export { default as StickyVerticalSlider } from "./modules/StickyVerticalSlider/StickyVerticalSlider";
export { default as TwoColumnCards } from "./modules/TwoColumnCards/TwoColumnCards";
export { default as TwoColumnIntro } from "./modules/TwoColumnIntro/TwoColumnIntro";
export { default as PricingTable } from "./modules/PricingTable/PricingTable";

// Components
export { BarSpace } from "./components/BarSpace";
export { default as SEO } from "./components/SEO";
export { default as StickyNavigator } from "./components/StickyNavigator";
export { default as StickyShareBar } from "./components/StickyShareBar";
export { default as NewsFooter } from "./components/NewsFooter";
export { default as PreviewOverlay } from "./components/PreviewOverlay";
export { default as usePreviewClient } from "./components/hooks/usePreviewClient";
export { default as NewsCta } from "./components/NewsCta";
export { default as CheckIcon } from "./components/icons/Check";
