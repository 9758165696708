import React from "react";
import PropTypes from "prop-types";
import LinkChoice from "./LinkChoice";

const FilterMenu = ({
  options,
  // onFilterChange,
  selectedOption,
  locale,
}) => {
  // const onFilterSelect = (option) => {
  //   onFilterChange(option);
  // };

  const translateFilter = () => {
    return {
      es: "Filtrar",
      de: "Filter",
      en: "Filter",
    }[locale];
  };

  return (
    <div className="flex items-center mb-8">
      <p className="uppercase mr-7 tracking-widest font-bold text-b2 leading-3 text-gray-g1">
        {translateFilter()}
      </p>
      <div className="flex justify-between">
        {options.map((option, index) => {
          return (
            <div className="flex items-center" key={index}>
              {/* <p
                className={`cursor-pointer text-p3 transition duration-300 ease-in-out capitalize ${
                  selectedOption.value === option.value
                    ? 'text-black'
                    : 'text-gray-g1'
                } `}
                onClick={() => onFilterSelect(option)}
              >
                {option.label}
              </p> */}
              <LinkChoice
                to={`/cases/#${option.value}`}
                className={`cursor-pointer text-p3 transition duration-300 ease-in-out capitalize ${
                  selectedOption.value === option.value
                    ? "text-black"
                    : "text-gray-g1"
                } `}
              >
                {option.label}
              </LinkChoice>
              <p className="mx-1 xs:mx-3 md:mx-6 text-gray-g1">
                {" "}
                {index != options.length - 1 ? "|" : ""}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterMenu;

FilterMenu.propTypes = {
  options: PropTypes.array,
  onFilterChange: PropTypes.func,
  selectedOption: PropTypes.object,
};
