import React from "react"
import PropTypes from "prop-types"

function LoadMoreIcon({ ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
      <path fill="#D9D9D9" fillOpacity="0.4" d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"></path>
      <circle cx="18" cy="24" r="2" fill="#262626"></circle>
      <circle cx="24" cy="24" r="2" fill="#262626"></circle>
      <circle cx="30" cy="24" r="2" fill="#262626"></circle>
    </svg>
  )
}

LoadMoreIcon.propTypes = {
  color: PropTypes.string,
}

LoadMoreIcon.defaultProps = {
  color: "#fff",
}

export default LoadMoreIcon
