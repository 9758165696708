import React from "react"
import PropTypes from "prop-types"

function Logo({ color, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="163" height="42" fill="none" viewBox="0 0 163 42">
      <path
        fill={color}
        d="M107 28.998c0-5.2-2.582-11-10-11a7.14 7.14 0 00-6 3v-10h-8v29h8v-2c1.065 1.408 1.865 3 6 3 6.267 0 10-6.457 10-12zm-9 0c0 2.643.028 6-3 6s-4-3.357-4-6c0-2.6.974-5 4-5s3 2.4 3 5zM132 31.003c0-8.613-3.216-13-12-13-6.993 0-12 4.56-12 11 0 7.373 4.922 12 12 12 5.031 0 10.294-2.225 12-7h-8a5.532 5.532 0 01-4 2c-2.644 0-3.785-2.486-4-5h16zm-8-4h-8c.383-2.387 1.484-4 4-4a4.133 4.133 0 014 4zM0 40h8V28c0-2.3.868-4 3-4 2.558 0 3 1.911 3 4v12h9V28c0-2.3-.131-4 2-4 2.558 0 3 1.911 3 4v12h9V25c0-3.58-2.327-7-8-7-5.5 0-7.658 2.745-8 3-1.023-2.217-3.611-3-6-3-3.07 0-5.294.443-7 3v-3H0v22zM61 40V26c0-4.262-.427-8-11-8-2.6 0-4.869-.023-7 1a6.572 6.572 0 00-4 6h8c.042-.938.4-2 3-2 1.365 0 3 .509 3 2 0 1.406-.848 1.786-2 2-4.307.809-13 .008-13 7 0 4.646 3.737 7 8 7a9.019 9.019 0 007-3v2h8zm-8-8a4.224 4.224 0 01-4 4 1.805 1.805 0 01-2-2c0-1.494 1.125-1.572 3-2 1.151-.256 1.977-1.487 3-2v2zM136 11v7h-3v5h3v11c0 6.055 4.418 7 8 7 1.79 0 3.295-1 5-1v-6c-.666.044-1.334.044-2 0-2.131 0-2-.953-2-3v-8h4v-3a11.455 11.455 0 01-7-9h-6zM64 40h8v-7c.028-2.16.727-4.256 2-6 .853-1.114 2.471-1.82 5-2h2v-7a13.917 13.917 0 00-3 0c-3.09 0-4.626.338-6 3v-3h-8v22zM163 8a8.116 8.116 0 00-8-8 8.113 8.113 0 00-8 8 8.115 8.115 0 008 8 8.118 8.118 0 008-8zm-5 0c0 1.444-1.556 2-3 2s-2-.556-2-2 .557-3 2-3a3.324 3.324 0 013 3z"
      ></path>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
}

Logo.defaultProps = {
  color: "#FF4619",
}

export default Logo
