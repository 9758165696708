import React from 'react';
import Overline from './Overline';

const NewsFooter = ({ content }) => {
  return (
    <div className='border-t mx-6 md:mx-24 border-gray-g3'>
      <div className='mx-auto mt-20 px-6 md:px-24 max-w-[1080px] xl:px-0 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-32'>
        {content.map((item) =>
          item.data ? (
            <div key={item.title}>
              <Overline
                size='small'
                text={item.title}
                noDot
                textColor=' text-gray-g3'
              />
              <p className=''>{item.data}</p>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default NewsFooter;
