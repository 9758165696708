import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Container from "../../components/containers/Container";
import JobCard from "../../components/JobCard";

const JobGrid = () => {
  let {
    craft: { entries },
  } = useStaticQuery(graphql`
    query JobGridQuery {
      craft {
        entries(section: "jobs") {
          ... on craft_jobs_default_Entry {
            slug
            title
            jobOffices {
              ... on craft_offices_default_Entry {
                title
              }
            }
            highlightImage {
              url
            }
          }
        }
      }
    }
  `);

  /**
   * Filter entries to always set the Initiative Application as the first entry
   */
  let initiative = entries.find((job) => job.title.startsWith("Initiat"));
  if (initiative) {
    var sortedEntries = entries.filter(
      (job) => !job.title.startsWith("Initiat")
    );
    sortedEntries.unshift(initiative);
    entries = sortedEntries;
  }

  return (
    <Container>
      <div className="grid lg:grid-cols-2 auto-rows-fr grid-flow-row gap-6 lg:gap-10">
        {entries.map((job, index) => {
          return <JobCard content={job} key={`${job.title}+${index}`} />;
        })}
      </div>
    </Container>
  );
};

export default JobGrid;
