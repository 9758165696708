import React from "react"
import PropTypes from "prop-types"

function PlusIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 51 50">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M21.669 21.667V0h6.666v21.667h21.667v6.666H28.335V50H21.67V28.334H.002v-6.667h21.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

PlusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

PlusIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default PlusIcon
