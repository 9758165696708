import React from 'react';
import PropTypes from 'prop-types';
import ArrowRight from '../components/icons/ArrowRight';
import LinkChoice from './LinkChoice';
import { useLocation } from '@reach/router';
import intl from '../intl/translations.json';

const StickyNavigator = ({ title, next, prev, theme, parent = 'cases' }) => {
  const locale = useLocation().pathname?.substring(1, 3) || 'de';

  console.log({
    parent,
    prev
  });

  return (
    <div className='invisible lg:visible sticky bottom-2 h-24 flex justify-center items-center'>
      <div
        className={
          `rounded px-6 mx-28 flex items-center justify-between h-12 w-full max-w-wide ` +
          (theme === 'dark' ? 'bg-gray-g4 shadow-custom' : 'bg-white shadow-md')
        }
      >
        <LinkChoice
          to={`/${parent}/` + prev}
          className={prev ? '' : 'opacity-0 pointer-events-none'}
        >
          <div className='flex items-center'>
            <ArrowRight className='rotate-180 mr-2 text-highlight' />
            <p
              className={
                `text-b2 font-medium tracking-widest uppercase ` +
                (theme === 'dark' ? 'text-gray-g1' : 'text-gray-g2')
              }
            >
              {parent === 'cases'
                ? intl.stickyNavigator.prevCase[locale]
                : intl.stickyNavigator.prevNews[locale]}
            </p>
          </div>
        </LinkChoice>
        <p
          className={
            `font-bold font-base ` +
            (theme === 'dark' ? 'text-white' : 'text-black')
          }
        >
          {title}
        </p>
        <LinkChoice
          to={`/${parent}/` + next}
          className={next ? '' : 'opacity-0 pointer-events-none'}
        >
          <div className='flex items-center'>
            <p
              className={
                `text-b2 font-medium tracking-widest uppercase ` +
                (theme === 'dark' ? 'text-gray-g1' : 'text-gray-g2')
              }
            >
              {parent === 'cases'
                ? intl.stickyNavigator.nextCase[locale]
                : intl.stickyNavigator.nextNews[locale]}
            </p>
            <ArrowRight className='ml-2 text-highlight' />
          </div>
        </LinkChoice>
      </div>
    </div>
  );
};

export default StickyNavigator;

StickyNavigator.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  prev: PropTypes.string,
  next: PropTypes.string,
  parent: PropTypes.string
};
