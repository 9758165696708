import React from "react"
import PropTypes from "prop-types"

const TwoColumnLayout = ({ contentLeft, contentRight }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
      {contentLeft}
      {contentRight}
    </div>
  )
}
export default TwoColumnLayout

TwoColumnLayout.propTypes = {
  contentLeft: PropTypes.element,
  contentRight: PropTypes.element,
}
