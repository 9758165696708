// Dependencies
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Container from "../../components/containers/Container";
import GridLayout from "./GridLayouts/GridLayouts";
import LoadMoreIcon from "../../components/icons/LoadMoreIcon";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

const NewsGrid = ({ content }) => {
  const [page, setPage] = useState(0);
  const { pathname } = useLocation();
  const locale = pathname.split("/")[1];

  const {
    craft: {
      marbetEntriesDE,
      marbetEntriesEN,
      marbetEntriesES,
      mAwayEntriesDE,
      mAwayEntriesEN,
      mAwayEntriesES,
      mGuestEntriesDE,
      mGuestEntriesEN,
      mGuestEntriesES,
    },
  } = useStaticQuery(graphql`
    query NewsQuery {
      craft {
        marbetEntriesDE: entries(section: "news", siteId: "1") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        marbetEntriesEN: entries(section: "news", siteId: "2") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        marbetEntriesES: entries(section: "news", siteId: "3") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mAwayEntriesDE: entries(section: "news", siteId: "5") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mAwayEntriesEN: entries(section: "news", siteId: "6") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mAwayEntriesES: entries(section: "news", siteId: "7") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mGuestEntriesDE: entries(section: "news", siteId: "4") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mGuestEntriesEN: entries(section: "news", siteId: "8") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
        mGuestEntriesES: entries(section: "news", siteId: "9") {
          title
          siteId
          slug
          ... on craft_news_post_Entry {
            highlightImage {
              url
              mimeType
            }
            media {
              url
              mimeType
            }
            newsDate
            newsSubheadline
            serviceTags
          }
        }
      }
    }
  `);

  const getRightEntries = () => {
    if (content.root === "marbet") {
      if (locale.includes("de")) return marbetEntriesDE;
      if (locale.includes("en")) return marbetEntriesEN;
      if (locale.includes("es")) return marbetEntriesES;
    } else if (content.root === "mAway") {
      if (locale.includes("de")) return mAwayEntriesDE;
      if (locale.includes("en")) return mAwayEntriesEN;
      if (locale.includes("es")) return mAwayEntriesES;
    } else if (content.root === "mGuest") {
      if (locale.includes("de")) return mGuestEntriesDE;
      if (locale.includes("en")) return mGuestEntriesEN;
      if (locale.includes("es")) return mGuestEntriesES;
    }
  };

  const entries = getRightEntries();

  const cutNews = (list, start, end) => {
    return list.slice(start, end);
  };

  return (
    <Container className="mb-48">
      {page >= 0 && (
        <GridLayout
          paginatedNews={cutNews(entries, 0, 7)}
          layoutNumber={page}
        />
      )}
      {page >= 1 && entries.length > 7 && (
        <GridLayout
          paginatedNews={cutNews(entries, 7, 14)}
          layoutNumber={page}
        />
      )}
      {page >= 2 && entries.length > 14 && (
        <GridLayout
          paginatedNews={cutNews(entries, 14, 21)}
          layoutNumber={page}
        />
      )}
      {page >= 3 && entries.length > 21 && (
        <GridLayout
          paginatedNews={cutNews(entries, 21, 28)}
          layoutNumber={page}
        />
      )}
      {(page + 1) * 7 < entries.length && (
        <div className="w-full relative">
          <LoadMoreIcon
            className="cursor-pointer absolute left-0 right-0 mx-auto mt-3"
            onClick={() => setPage(page + 1)}
          />
        </div>
      )}
    </Container>
  );
};

NewsGrid.propTypes = {
  news: PropTypes.array,
};

export default NewsGrid;
