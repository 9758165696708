import React, { useState, useRef, useLayoutEffect } from 'react'

import PropTypes from 'prop-types'

const QuoteCard = ({ variant, quote, source, role, newsGallery }) => {
  const card = useRef(null)
  const [width, setWidth] = useState(400)

  useLayoutEffect(() => {
    setWidth(card.current.offsetWidth)
  })

  const isVariant = {
    dark: {
      quote: 'white',
      subLine: 'white',
      highlight: 'white',
      bg: 'secondary',
    },
    gray: {
      quote: 'white',
      subLine: 'white',
      highlight: 'white',
      bg: 'secondary-hover',
    },
    primary: {
      quote: 'white',
      subLine: 'secondary',
      highlight: 'secondary',
      bg: 'highlight',
    },
    white: {
      quote: 'black',
      subLine: 'gray-g2',
      highlight: 'highlight',
      bg: 'white',
    },
    green: {
      quote: 'gray-g4',
      subLine: 'gray-g4',
      highlight: 'white',
      bg: 'primary',
    }
  }

  return (
    <div
      ref={card}
      className={
        `p-8 w-full rounded bg-${isVariant[variant].bg} overflow-visible ` +
        (newsGallery ? 'h-full' : 'h-96')
      }
    >
      <p
        style={{ fontSize: `${width <= 560 ? '18px' : '32px'}` }}
        className={`relative italic font-serif w-5/6 leading-tight text-${isVariant[variant].quote}`}
      >
        <span
          style={{ left: `${width <= 560 ? '-8px' : '-15px'}` }}
          className={`text-${isVariant[variant].quote} absolute top-0`}
        >
          &quot;
        </span>
        {quote}
        <span className={`text-${isVariant[variant].quote}`}>&quot;</span>
      </p>
      <p
        style={{ fontSize: '18px' }}
        className={`font-bold text-xs leading-tight mt-3 text-${isVariant[variant].subLine}`}
      >
        {source}
      </p>
      <p
        style={{ fontSize: '18px' }}
        className={`leading-tight text-${isVariant[variant].subLine}`}
      >
        {role}
      </p>
    </div>
  )
}

QuoteCard.propTypes = {
  variant: PropTypes.string,
  quote: PropTypes.string,
  source: PropTypes.string,
  role: PropTypes.string,
}

export default QuoteCard
