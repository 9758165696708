import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import {
  FooterColumn,
  FooterContainer,
  FooterListBlock,
  FooterBlock
} from './Footer.styles';
import Logo from '../../components/icons/Logo';
import Container from '../../components/containers/Container';
import { SocialBar, ImprintBar } from './FooterBars';
import ArrowRight from '../../components/icons/ArrowRight';
import IconButton from '../../components/buttons/IconButton';
import { getSiteLanguageFromLocale, fireEvent } from '../../utils/helpers';
import PlusIcon from '../../components/icons/PlusIcon';
import LinkChoice from '../../components/LinkChoice';
import { useClickOutside } from '../../components/hooks/useClickOutside';
import useLocationGlobal from '../../hooks/useLocationGlobal';

const Footer = ({ content, root }) => {
  const location = useLocation();
  const locale = useLocationGlobal();
  const bubble = useRef(null);
  const [menuContent, setMenuContent] = useState(null);
  const [bubbleContent, setBubbleContent] = useState(null);
  const [socialLinks, setSocialLinks] = useState(null);
  const [bubbleOpen, setBubbleOpen] = useState(false);

  let isAnniversary =
    location.pathname.includes('anniversary') ||
    location.pathname.includes('aniversario');

  useClickOutside(bubble, () => setBubbleOpen(false), bubbleOpen);

  useEffect(() => {
    if (location) {
      let menuOptions = content.filter(
        (e) => e.menuContent && e.language === locale
      );
      let bubbleOptions = content.filter(
        (e) => e.contactBubble && e.language === locale
      );

      setMenuContent(menuOptions?.[0]?.menuContent || null);

      setBubbleContent(bubbleOptions?.[0]?.contactBubble[0] || null);

      setSocialLinks(menuOptions?.[0]?.socialLinks || null);
    }
  }, [location]);

  let hideBtnInAnniversary =
    isAnniversary && (bubbleContent?.hideTwoInAnniversary || false);

  return menuContent && bubbleContent ? (
    <Container
      className={
        'relative flex flex-col pt-20 transition-all ease-in-out duration-500 ' +
        (bubbleOpen ? '' : 'md:mt-52 lg:mt-72')
      }
    >
      {/* Contact button block */}
      <div
        className='relative overflow-hidden hidden md:block transition-all ease-in-out duration-500 '
        style={{ height: bubbleOpen ? 580 : 200 }}
      >
        <IconButton
          className='absolute rounded-full right-0 bottom-16 bg-highlight -rotate-90'
          icon={<ArrowRight />}
          action={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />

        {/* Contact bubble */}
        <div
          ref={bubble}
          className='absolute flex justify-center top-0 border-highlight bg-highlight rounded-full right-1/2 translate-x-1/2'
          style={{ width: 580, height: 580, borderWidth: 54 }}
        >
          <div
            className={
              'flex flex-col items-center text-white transition-all ease-out duration-500 ' +
              (bubbleOpen ? 'text-h3' : 'text-h6 pt-3.5')
            }
          >
            <div
              className='flex flex-col items-center px-8 cursor-pointer'
              onClick={() => setBubbleOpen(true)}
            >
              <span className='italic font-serif'>
                {bubbleContent.bubbleTitle}
              </span>
              <div className='flex items-center justify-center w-12 h-8'>
                <ArrowRight
                  className={
                    'rotate-90 text-white transition duration-300 ' +
                    (bubbleOpen ? 'opacity-0' : 'opacity-100')
                  }
                />
              </div>
            </div>
            <div
              className={
                'flex flex-col h-full font-sans transition duration-300 ' +
                (bubbleOpen ? 'opacity-100' : 'opacity-0 pointer-events-none')
              }
            >
              <div
                className='border-b border-white/40 text-center flex items-center py-6 cursor-pointer'
                onClick={() => fireEvent('contact-overlay', true)}
              >
                <span className='text-h6 font-extrabold' style={{ width: 478 }}>
                  {bubbleContent.buttonOneText}
                </span>
              </div>
              {!hideBtnInAnniversary && (
                <LinkChoice
                  to={bubbleContent.buttonTwoLink}
                  className='border-b border-white/40 text-center flex items-center py-6'
                >
                  <span
                    className='text-h6 font-extrabold'
                    style={{ width: 478 }}
                  >
                    {bubbleContent.buttonTwoText}
                  </span>
                </LinkChoice>
              )}
              <div className='flex flex-grow items-end justify-center'>
                <IconButton
                  className='w-10 h-10 rounded-full bg-background rotate-45'
                  icon={
                    <PlusIcon
                      width={20}
                      height={20}
                      className={
                        root === 'marbet' ? 'text-secondary' : 'text-highlight'
                      }
                    />
                  }
                  action={() => setBubbleOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer block */}
      <div className='flex flex-col lg:flex-row border-t border-gray-g1 pb-12 mt-16 md:mt-0 z-20 pt-12'>
        <FooterColumn>
          <FooterBlock className='text-gray-g4'>
            <Logo className='mb-2' />
            <p className='font-bold'>A Würth Company</p>
            <ImprintBar className='hidden lg:flex' />
          </FooterBlock>
        </FooterColumn>

        <FooterContainer
          className='grid grid-cols-2 lg:flex'
          // isAnniversary={isAnniversary}
        >
          {!isAnniversary &&
            menuContent.map((column, index) => {
              return (
                <FooterColumn key={index}>
                  <FooterListBlock
                    title={column.plainText}
                    list={column.links}
                  />
                </FooterColumn>
              );
            })}

          <FooterColumn className='col-span-2 lg:mt-8 xl:mt-0 max-w-screen-xxs'>
            {!!socialLinks && !!socialLinks.length && (
              <>
                <p className='text-base font-bold'>Social</p>
                <SocialBar className='text-gray-g3' content={socialLinks} />
              </>
            )}

            <ImprintBar className='flex lg:hidden text-gray-g4' />
          </FooterColumn>
        </FooterContainer>
      </div>
    </Container>
  ) : null;
};

Footer.propTypes = {
  propContent: PropTypes.object,
  id: PropTypes.string,
  content: PropTypes.array
};

export default Footer;
