import React from "react";

// components
import Button from "./buttons/Button";
import ArrowRight from "./icons/ArrowRight";
import { navigateTo } from './LinkChoice'

const NewCta = ({content}) => {
   const {buttonLink, buttonName} = content.newsCta[0]
  return (
    <div className="mx-auto px-6 md:px-24 xl:px-0 lg:max-w-[1080px]">

    {/* todo: add quote text above? */}
      <Button
        label= {buttonName}
        size="large"
        iconSuffix={<ArrowRight />}
        action={() => navigateTo(buttonLink)}
      />
    </div>
  );
};

export default NewCta;
