import React from 'react';
import PropTypes from 'prop-types';

const Overline = ({ text, textColor, dotColor, size, noDot, ...props }) => {
  const { className } = props;
  return (
    <div className={'relative ' + className}>
      {!noDot && (
        <span
          className={
            'absolute left-0 top-0 bottom-0 m-auto rounded-full w-3 h-3 ' +
            (dotColor && dotColor)
          }
        ></span>
      )}
      <p
        className={
          'uppercase tracking-widest font-bold leading-6 ' +
          (!noDot && 'ml-7 ') +
          (textColor && textColor) +
          ' ' +
          (size === 'small' ? 'text-b2' : 'text-b2 sm:text-p2')
        }
      >
        {text}
      </p>
    </div>
  );
};

Overline.propTypes = {
  // does this need to be required? It's sometimes coming from the back end as undefined:
  text: PropTypes.string,
  textColor: PropTypes.string,
  dotColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal']),
  noDot: PropTypes.bool
};

Overline.defaultProps = {
  textColor: 'text-highlight',
  dotColor: 'bg-highlight',
  size: 'small',
  noDot: false,
  // I've added a default value here as it was sometimes coming back from the back end as undefined:
  text: ''
};

export default Overline;
