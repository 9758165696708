import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuAccordionItem from './MenuAccordionItem';

const AccordionWrapper = styled.ul`
  li {
    list-style-type: none;
    margin: auto;
  }
`;

const Accordion = ({ items, close, ...props }) => {
  const { className, ...addonProps } = props;
  const [activeItem, setActiveItem] = useState(null);

  return (
    <AccordionWrapper className={className || ''} {...addonProps}>
      {items.map((item, index) => {
        return (
          <MenuAccordionItem
            key={item.plainText + index}
            header={item.plainText}
            links={item.links}
            selectedItem={activeItem}
            toggleContent={setActiveItem}
            index={index}
            close={close}
          />
        );
      })}
    </AccordionWrapper>
  );
};
Accordion.propTypes = {
  items: PropTypes.array,
  close: PropTypes.func
};

export default Accordion;
