import React from "react"
import PropTypes from "prop-types"
import { useWindowSize } from "./hooks/useWindowSize"
import QuoteCard from "./QuoteCard"
import Image from "./Image"

const ImageQuoteGridCard = ({ card, root }) => {
  const { title, rows, columns, imageLink, type, quote, source, role, variant } = card
  const size = useWindowSize()

  const isMobileLayout = () => {
    return columns === 4 && size.width < 1024 ? 2 : columns
  }

  return (
    <div className={`col-span-${isMobileLayout()} bg-${variant} rounded lg:w-full lg:h-full ` + (rows === 2 ? 'lg:aspect-w-4 lg:aspect-h-5 row-span-2' : 'lg:aspect-w-17 lg:aspect-h-10 row-span-1')}>
      {type === "image" ? (
        <Image simple className="h-full w-full object-cover rounded" src={imageLink} alt={title} />
      ) : (
        <QuoteCard className="rounded" variant={variant} quote={quote} source={source} role={role} root={root} />
      )}
    </div>
  )
}

export default ImageQuoteGridCard

ImageQuoteGridCard.propTypes = {
  card: PropTypes.object,
}
