import React from "react";
import PropTypes from "prop-types";

function ChevronDown({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5239 15.2739C10.833 14.9647 11.3343 14.9647 11.6435 15.2739L19.0003 22.6307L26.3572 15.2739C26.6664 14.9647 27.1676 14.9647 27.4768 15.2739C27.786 15.583 27.786 16.0843 27.4768 16.3935L19.5601 24.3101C19.251 24.6193 18.7497 24.6193 18.4405 24.3101L10.5239 16.3935C10.2147 16.0843 10.2147 15.583 10.5239 15.2739Z"
        fill="white"
      />
    </svg>
  );
}

ChevronDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronDown.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
};

export default ChevronDown;
