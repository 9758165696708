import React, { useId, useState, useRef, useEffect } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import styled from "styled-components";
import Vimeo from "@vimeo/player";

const StyledIframeContainer = styled.div`
  overflow: hidden;
  width: 100%;

  & iframe {
    width: 100%;
    height: 100%;
  }
`;

const StyledFullScreenIframeContainer = styled.div`
  overflow: hidden;

  & iframe {
    width: 100vw;
    height: ${(props) => `${(1 / props.$aspectRatio) * 100}vw`};
    min-height: 100vh;
    min-width: ${(props) => `${props.$aspectRatio * 100}vw`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Styled3qContainer = styled.div`
  overflow: hidden;
  width: 100% !important;
  margin: auto;
  height: 100%;

  & .js3q-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
`;

const StyledFullScreen3qContainer = styled.div`
  overflow: visible;

  & .js3q-wrapper {
    width: 100vw;
    height: ${(props) => `${(1 / props.$aspectRatio) * 100}vw`};
    min-height: 100vh;
    min-width: ${(props) => `${props.$aspectRatio * 100}vw`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const VimeoPlayer = ({
  media,
  asBackground,
  className = "",
  style = {},
  togglePlayback,
  fullScreen,
}) => {
  const videoContainer = useRef();

  useEffect(() => {
    const manageVideo = async () => {
      if (!videoContainer.current) {
        return;
      }

      const playerElement = videoContainer.current.querySelector("iframe");

      if (!playerElement) {
        return;
      }

      const player = new Vimeo(playerElement);

      if (!player) {
        return;
      }

      if (togglePlayback) {
        player.play().catch(() => {});
      } else {
        player.pause();
      }
    };

    manageVideo();
  }, [togglePlayback]);

  if (media.mimeType !== "application/json") {
    return null;
  }

  if (!media.embeddedAsset?.iframeCode) {
    return null;
  }

  if (!media.embeddedAsset.isSafe) {
    return null;
  }

  const { aspectRatio } = media.embeddedAsset;
  const calculatedAspectRatio = aspectRatio ? 100 / aspectRatio : 16 / 9;
  const iframeCode = media.embeddedAsset.iframeCode.replace(
    `<iframe `,
    `<iframe sandbox="allow-scripts" `
  );

  if (fullScreen) {
    return (
      <StyledFullScreenIframeContainer
        ref={videoContainer}
        className={className}
        style={{
          aspectRatio: calculatedAspectRatio,
          ...(style ? style : {}),
        }}
        $aspectRatio={calculatedAspectRatio}
        dangerouslySetInnerHTML={{
          __html: asBackground
            ? iframeCode.replace(
                "&amp;dnt=1",
                "&amp;dnt=1&amp;autoplay=1&amp;muted=1&amp;background=1&amp;playsinline=1"
              )
            : iframeCode,
        }}
      />
    );
  }

  return (
    <StyledIframeContainer
      ref={videoContainer}
      className={className}
      style={{
        aspectRatio: calculatedAspectRatio,
        ...(style ? style : {}),
      }}
      dangerouslySetInnerHTML={{
        __html: asBackground
          ? iframeCode.replace(
              "&amp;dnt=1",
              "&amp;dnt=1&amp;autoplay=1&amp;muted=1&amp;background=1&amp;playsinline=1"
            )
          : iframeCode,
      }}
    />
  );
};

const JS3QPlayer = ({
  media,
  asBackground,
  className = "",
  style = {},
  togglePlayback,
  fullScreen,
}) => {
  const videoContainer = useRef();
  const containerId = useId();
  const js3qPlayer = useRef();
  const [isJs3qLoaded, setIsJs3qLoaded] = useState(false);

  const { aspectRatio } = media.embeddedAsset;
  const calculatedAspectRatio = aspectRatio ? 100 / aspectRatio : 16 / 9;
  const videoId = media.embeddedAsset.url.split("/").pop();

  useEffect(() => {
    const manageVideo = async () => {
      if (!isJs3qLoaded) {
        return;
      }

      if (!videoContainer.current) {
        return;
      }

      if (!window.js3q) {
        return;
      }

      if (!js3qPlayer.current) {
        const player = new window.js3q({
          dataid: videoId,
          container: containerId,
          ...(asBackground && {
                loop: true,
                muted: true,
                controls: false,
                autoplay: true,
              }),
        });

        js3qPlayer.current = player;
      }

      if (!js3qPlayer.current || (!js3qPlayer.current.play && !js3qPlayer.current.pause)) {
        return;
      }

      if (togglePlayback) {
        js3qPlayer.current.play();
      } else {
        js3qPlayer.current.pause();
      }
    };

    manageVideo();
  }, [isJs3qLoaded, togglePlayback, asBackground]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if ("js3q" in window) {
        window.clearInterval(interval);
        setIsJs3qLoaded(true);
      }
    }, 100);

    return () => {
      window.clearInterval(interval);
    };
  }, []);
  
  if (fullScreen) {
    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="https://player.3qsdn.com/js3q.latest.js"
            defer
          />
        </Helmet>
        <StyledFullScreen3qContainer
          ref={videoContainer}
          className={className}
          style={{
            aspectRatio: calculatedAspectRatio,
            ...(style ? style : {}),
          }}
          $aspectRatio={calculatedAspectRatio}
        >
          <div id={containerId} />
        </StyledFullScreen3qContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://player.3qsdn.com/js3q.latest.js"
          defer
        />
      </Helmet>
      <Styled3qContainer
        ref={videoContainer}
        className={className}
        style={{
          aspectRatio: calculatedAspectRatio,
          ...(style ? style : {}),
        }}
      >
        <div id={containerId} />
      </Styled3qContainer>
    </>
  );
};

const ExternalVideo = ({
  media,
  asBackground,
  className = "",
  style = {},
  togglePlayback,
  fullScreen,
}) => {
  if (media.mimeType !== "application/json") {
    return null;
  }

  if (!media.embeddedAsset?.iframeCode && !media.embeddedAsset?.url) {
    return null;
  }

  if (!media.embeddedAsset.isSafe) {
    return null;
  }

  const isVimeo = media.embeddedAsset.iframeCode?.includes("vimeo");
  const is3q = media.embeddedAsset.url?.includes("3qsdn");

  if (isVimeo) {
    return (
      <VimeoPlayer
        media={media}
        asBackground={asBackground}
        className={className}
        style={style}
        togglePlayback={togglePlayback}
        fullScreen={fullScreen}
      />
    );
  }

  if (is3q) {
    return (
      <JS3QPlayer
        media={media}
        asBackground={asBackground}
        className={className}
        style={style}
        togglePlayback={togglePlayback}
        fullScreen={fullScreen}
      />
    );
  }

  return null;
};

ExternalVideo.propTypes = {
  media: PropTypes.shape({
    embeddedAsset: PropTypes.shape({
      iframeCode: PropTypes.string,
      aspectRatio: PropTypes.number,
      url: PropTypes.string,
      isSafe: PropTypes.bool,
      title: PropTypes.string,
    }),
    mimeType: PropTypes.string,
    url: PropTypes.string,
  }),
  asBackground: PropTypes.bool,
  className: PropTypes.string,
  togglePlayback: PropTypes.bool,
  style: PropTypes.object,
  fullScreen: PropTypes.bool,
};

export default ExternalVideo;
