import React from "react"
import PropTypes from "prop-types"

function ScaleIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 120 120">
      <path
        className="fill-current"
        fill={color}
        fillRule="evenodd"
        d="M96.87 20.419l22.5 33.75c.409.615.63 1.342.63 2.081 0 10.823-10.035 22.5-26.25 22.5S67.5 67.073 67.5 56.25c0-.739.221-1.466.63-2.081L86.741 26.25H63.75V97.5h-7.5V26.25H33.259L51.87 54.169c.409.615.63 1.342.63 2.081 0 10.823-10.035 22.5-26.25 22.5S0 67.073 0 56.25c0-.739.221-1.466.63-2.081l22.5-33.75a3.755 3.755 0 013.12-1.669h30v-7.5c0-2.074 1.68-3.75 3.75-3.75 2.07 0 3.75 1.676 3.75 3.75v7.5h30c1.252 0 2.422.626 3.12 1.669zM10.759 52.5H41.74L26.25 29.261 10.759 52.5zM93.75 29.261L78.259 52.5h30.982L93.75 29.261zM26.25 112.5V105h67.5v7.5h-67.5z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="60"
          x2="15.5"
          y1="11"
          y2="151.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop className="fill-current" stopColor={color}></stop>
          <stop offset="1" className="fill-current" stopColor={color} stopOpacity="0.2"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

ScaleIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

ScaleIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default ScaleIcon
