import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FaqAccordionItem from './FaqAccordionItem';
import Container from '../../components/containers/Container';

const FaqAccordionWrapper = styled.ul`
  li {
    max-width: 1920px;
    list-style-type: none;
    margin: auto;
  }
`;

const FaqAccordion = ({ content: { plainText, accordion }, ...props }) => {
  const { className, ...addonProps } = props;
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className='py-16 lg:py-28'>
      <Container>
        <h2 className='mb-2.5'>{plainText}</h2>
      </Container>
      <Container>
        <FaqAccordionWrapper className={className || ''} {...addonProps}>
          {accordion.map((item, index) => {
            return (
              <FaqAccordionItem
                key={item.header + index}
                header={item.header}
                body={item.body}
                selectedItem={activeItem}
                toggleContent={setActiveItem}
                index={index}
              />
            );
          })}
        </FaqAccordionWrapper>
      </Container>
    </div>
  );
};

FaqAccordion.propTypes = {
  content: PropTypes.shape({
    accordion: PropTypes.array,
    plainText: PropTypes.string
  })
};

FaqAccordion.defaultProps = {
  items: []
};

export default FaqAccordion;
