import React from "react"
import PropTypes from "prop-types"

function ContactIcon({ color, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="36" height="24" fill="none" viewBox="0 0 36 24">
      <path
        fill={color}
        d="M19.037 6.235H6.328L7.703 4.86a2.82 2.82 0 00.334-3.605A2.806 2.806 0 006.068.03a2.846 2.846 0 00-2.23.632L.308 3.635a.774.774 0 00-.3.594.786.786 0 00.223.67.776.776 0 00.6.3.783.783 0 00.67-.223l3.492-2.973a1.106 1.106 0 01.855-.223c.295.051.56.21.743.446a1.121 1.121 0 01-.112 1.375L4.36 5.715a1.39 1.39 0 00-.3 1.45 1.324 1.324 0 001.225.817h13.753a1.064 1.064 0 011.041 1.04 1.037 1.037 0 01-.3.743 1.039 1.039 0 01-.743.3H9.561a.9.9 0 00-.892.891.9.9 0 00.892.892h1.3a1.04 1.04 0 110 2.081H8.408a.9.9 0 00-.892.892.9.9 0 00.892.892h1.3a1.04 1.04 0 110 2.081h-2.45a.9.9 0 00-.893.892.9.9 0 00.892.892h1.3a1.063 1.063 0 011.04 1.04 1.063 1.063 0 01-1.04 1.041H.937a1.054 1.054 0 00-.63.26.87.87 0 00-.26.632.9.9 0 00.89.892h7.62a2.824 2.824 0 002.823-2.825 2.554 2.554 0 00-.372-1.375 2.838 2.838 0 001.53-2.529 2.89 2.89 0 00-.339-1.379 2.841 2.841 0 001.524-2.522 3.21 3.21 0 00-.186-1.04h5.537c.75 0 1.47-.294 2.007-.819a2.87 2.87 0 00.817-2.006 2.86 2.86 0 00-2.86-2.713zM32.192.81L26.766 2c-2.527.185-2.6 6.465-2.6 7.171 0 .706.074 7.024 2.564 7.172l5.352 1.19h.184c2.12 0 3.048-4.2 3.048-8.362s-.929-8.36-3.122-8.36zm-5.314 13.638a11.6 11.6 0 01-.929-5.277 11.89 11.89 0 01.93-5.277 11.627 11.627 0 01.928 5.277 11.483 11.483 0 01-.929 5.277zm5.351 1.262l-3.6-.818a15.133 15.133 0 00.963-5.758 15.136 15.136 0 00-.966-5.76l3.6-.78c.335.3 1.3 2.378 1.3 6.577.004 4.24-.962 6.24-1.297 6.54z"
      ></path>
    </svg>
  )
}

ContactIcon.propTypes = {
  color: PropTypes.string,
}

ContactIcon.defaultProps = {
  color: "#fff",
}

export default ContactIcon
