import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Container from "../../components/containers/Container";
import { BarSpace } from "../../components/BarSpace";
import { CareersCard } from "../../components/NewsCards";
import { navigateTo } from "../../components/LinkChoice";

const ColumnText = styled.h5`
  position: sticky;
  top: 128px;

  strong {
    color: #ff4619;
  }
  & > * {
    font-size: inherit;
    line-height: inherit;
  }

  @media (max-width: 1279px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 1023px) {
    position: inherit;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #767171;

    strong {
      font-weight: 400;
      color: #767171;
      font-family: sans-serif;
      font-style: normal;
    }
  }
`;

const StickyColumn = ({ content: { headline, stickyColumn, root } }) => {

  return (
    <>
      <Container type="wide" className="my-24">
        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-y-28 lg:gap-x-20 xl:gap-x-40">
          <div>
            <ColumnText dangerouslySetInnerHTML={{ __html: headline }} />
          </div>
          <div className={"flex flex-col " + (root === 'mGuest'? 'gap-y-7 lg:gap-y-18 ' : 'gap-y-14 lg:gap-y-40')}>
            {stickyColumn.map((item, index) => {
              return (
                <CareersCard
                  article={item}
                  buttonAction={() => navigateTo(item.buttonLink)}
                  key={index}
                  className={(root === 'mGuest' && "mb-4")}
                />
              );
            })}
          </div>
        </div>
      </Container>
      <BarSpace />
    </>
  );
};

StickyColumn.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string,
    stickyColumn: PropTypes.array,
  }),
};

export default StickyColumn;
