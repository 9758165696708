import { useState, useEffect } from "react"

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { rootMargin: "-25% 0px -50% 0px", threshold: 0.5})
    observer.observe(ref.current)
    return
  }, [])

  return isIntersecting
}
