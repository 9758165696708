import React from "react"
import LinkChoice from "../LinkChoice"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router";

const CasesGridIcon = ({ news }) => {
  let squares = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const { pathname } = useLocation();
  const locale = pathname.split("/")[1];

  const selectCopy = () => {
  
    if (locale === 'es') {
      return news ? "Todas las noticias" : "Todos"
    }

    if (locale === 'en') {
      return news ? "All stories" : "All cases"
    }

    return news ? "Alle Nachrichten" : "Alle Cases"
  }

  return (
    <LinkChoice to={news ? "/news" : "/cases"}>
      <div className="flex w-auto items-center">
        <div className="h-[24px] w-[24px] grid grid-cols-3 grid-rows-3 gap-0.5" style={{ gridGap: "2px" }}>
          {squares.map((square, index) => {
            return (
              <svg key={index} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0H0V6H6V0Z" fill="#FF4619" />
              </svg>
            )
          })}
        </div>
        <p className="ml-4 font-bold tracking-wider leading-6 text-highlight text-p2">{selectCopy()}</p>
      </div>
    </LinkChoice>
  )
}

CasesGridIcon.propTypes = {
  news: PropTypes.bool,
}

CasesGridIcon.defaultProps = {
  news: false,
}

export default CasesGridIcon
