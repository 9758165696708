import React from "react";
import PropTypes from "prop-types";

// Components
import Container from "../../components/containers/Container";
import Overline from "../../components/Overline";
import RevealText from "../../components/RevealText";
import PlayIcon from "../../components/icons/PlayIcon";
import PauseIcon from "../../components/icons/PauseIcon";

// Custom audio player
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "./audioPlayerStyles.css";

const AudioPlayerModule = ({ content: { audioPlayer } }) => {
  const { overline, headline, textContent, audioFile } = audioPlayer[0];

  return (
    <div className="bg-highlight py-16 lg:py-28">
      <Container fullWidth className="max-w-screen-2xl">
        <RevealText>
          <Overline
            size="normal"
            dotColor="bg-white"
            textColor="text-white"
            text={overline}
          />
        </RevealText>
        <h1 className="mb-14 font-black text-white">{headline}</h1>
        <h6 className="text-white font-bold mb-14">{textContent}</h6>

        {/* Desktop audio player */}
        <div className="hidden md:flex">
          <AudioPlayer
            src={audioFile[0].url}
            showJumpControls={false}
            showFilledVolume={true}
            customAdditionalControls={[]}
            showDownloadProgress={false}
            customProgressBarSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_LEFT_TIME,
              RHAP_UI.VOLUME,
            ]}
            customVolumeControls={[]}
            customControlsSection={[]}
            customIcons={{
              play: <PlayIcon />,
              pause: <PauseIcon />,
            }}
          />
        </div>

        {/* Mobile audio player */}
        <div className="md:hidden">
          <AudioPlayer
            src={audioFile[0].url}
            showJumpControls={false}
            showDownloadProgress={false}
            customProgressBarSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_LEFT_TIME,
            ]}
            customControlsSection={[]}
            customIcons={{
              play: <PlayIcon width={32} height={32} />,
              pause: <PauseIcon width={32} height={32} />,
            }}
          />
        </div>
      </Container>
    </div>
  );
};

AudioPlayerModule.propTypes = {
  content: PropTypes.object.isRequired,
};

export default AudioPlayerModule;
