import React from "react";
import PropTypes from "prop-types";
import Container from "../../components/containers/Container";
import Image from "../../components/Image";

const ImageGallery = ({ content: { imageGallery } }) => {
  return (
    <Container type="wide">
      <div className="flex flex-col xl:grid grid-cols-2 gap-10 mt-40 mb-">
        {imageGallery.map((image, i) => {
          return (
            <div
              key={"marbet-" + "-" + i}
              className="relative w-full h-96 overflow-hidden rounded bg-gray-g1"
            >
              <Image
                simple
                alt={"marbet-" + "-" + i}
                src={image.url}
                className="absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover object-center h-full w-full"
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
};

ImageGallery.propTypes = {
  content: PropTypes.shape({
    imageGallery: PropTypes.array,
  }),
};

export default ImageGallery;
