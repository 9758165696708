import styled from "styled-components"

export const BarSpace = styled.div`
  width: 100%;
  height: ${({ large }) => large ? '14rem' : '7rem'};
  
  @media (max-width: 768px) {
    height: ${({ large }) => large ? '10rem' : '5rem'};
  }
`
