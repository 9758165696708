// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import styled, { css } from 'styled-components';

// Components
import Container from '../../components/containers/Container';
import CasesGridCard from '../../components/CasesGridCard';
import FilterMenu from '../../components/FilterMenu';
import LoadMoreIcon from '../../components/icons/LoadMoreIcon';

// Helpers
import { fireEvent } from '../../utils/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import useLocationGlobal from '../../hooks/useLocationGlobal';
import translations from '../../intl/translations.json';

const Grid = styled.div`
  display: grid;
  grid-template-rows: ${({ $cases }) => ($cases[0] ? 480 : 0)}px ${({
      $cases
    }) => ($cases[2] ? 480 : 0)}px ${({ $cases }) => ($cases[2] ? 480 : 0)}px ${({
      $cases
    }) => ($cases[5] ? 480 : 0)}px ${({ $cases }) => ($cases[5] ? 480 : 0)}px;
  gap: 40px 40px;
  grid-template-areas:
    '. .'
    'Highlight-Vertical .'
    'Highlight-Vertical .'
    'Highlight-Full Highlight-Full'
    'Highlight-Full Highlight-Full';
  ${({ number }) => number % 2 === 0 && css``}

  & .Highlight-Vertical {
    grid-area: Highlight-Vertical;
  }
  & .Highlight-Full {
    grid-area: Highlight-Full;
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

const CaseGridLayout = ({ paginatedCases }) => {
  return (
    <Grid $cases={paginatedCases} className='mt-10'>
      <CasesGridCard card={paginatedCases[0]} />
      {paginatedCases[1] && <CasesGridCard card={paginatedCases[1]} />}
      {paginatedCases[2] && (
        <div className='Highlight-Vertical w-full- h-full'>
          <CasesGridCard card={paginatedCases[2]} />
        </div>
      )}
      {paginatedCases[3] && <CasesGridCard card={paginatedCases[3]} />}
      {paginatedCases[4] && <CasesGridCard card={paginatedCases[4]} />}
      {paginatedCases[5] && (
        <div className='Highlight-Full w-full h-full'>
          <CasesGridCard card={paginatedCases[5]} />
        </div>
      )}
    </Grid>
  );
};

CaseGridLayout.propTypes = {
  paginatedCases: PropTypes.array,
  layoutNumber: PropTypes.number
};

CaseGridLayout.defaultProps = {
  layoutNumber: 0
};

const CasesGrid = ({ content }) => {
  const { filters, casesGrid } = translations;
  const location = useLocation();
  const locale = useLocationGlobal();
  const [filterCategory, setFilterCategory] = useState({
    value: 'all',
    label: filters.all[locale]
  });
  const [page, setPage] = useState(0);
  const [cases, setCases] = useState([]);

  const { craft: casesEntries } = useStaticQuery(graphql`
    query CasesQuery {
      craft {
        de: entries(section: "cases", siteId: "1") {
          ... on craft_cases_default_Entry {
            slug
            branch
            caseYear
            client
            title
            highlightImage {
              url
              mimeType
            }
            newsImageGallery {
              url
            }
          }
        }
        en: entries(section: "cases", siteId: "2") {
          ... on craft_cases_default_Entry {
            slug
            branch
            caseYear
            client
            title
            highlightImage {
              url
              mimeType
            }
            newsImageGallery {
              url
            }
          }
        }
        es: entries(section: "cases", siteId: "3") {
          ... on craft_cases_default_Entry {
            slug
            branch
            caseYear
            client
            title
            highlightImage {
              url
              mimeType
            }
            newsImageGallery {
              url
            }
          }
        }
      }
    }
  `);

  const entries = casesEntries[locale];

  // const handleChangeFilter = (filter) => {
  //   setFilterCategory(filter);
  // };

  const cutCases = (list, start, end) => {
    let cutList = list;

    return cutList.slice(start, end);
  };

  useEffect(() => {
    if (filterCategory.value === 'all') {
      setCases([...entries]);
    } else {
      const filteredArray = entries.filter(
        (entry) => entry.branch === filterCategory.value
      );

      setCases(filteredArray);
    }
  }, [filterCategory]);

  useEffect(() => {
    setCases([...entries]);
    fireEvent('middle-component', null);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const value = location.hash.split('#')[1];

      setFilterCategory({
        value,
        label: filters[value][locale]
      });

      if (value === 'all') {
        setCases([...entries]);
      } else {
        const filteredArray = entries.filter(
          (entry) => entry.branch === value
        );
  
        setCases(filteredArray);
      }
    }
  }, [location]);

  return (
    <Container className='mt-10 m-32'>
      <FilterMenu
        options={[
          { value: 'all', label: filters.all[locale] },
          { value: 'live', label: filters.live[locale] },
          { value: 'digital', label: filters.digital[locale] },
          { value: 'hybrid', label: filters.hybrid[locale] }
        ]}
        selectedOption={filterCategory}
        // onFilterChange={handleChangeFilter}
        locale={locale}
      />
      {cases.length ? (
        <>
          {page >= 0 && (
            <CaseGridLayout paginatedCases={cutCases(cases, 0, 6)} />
          )}
          {page >= 1 && cases.length > 6 && (
            <CaseGridLayout paginatedCases={cutCases(cases, 6, 12)} />
          )}
          {page >= 2 && cases.length > 12 && (
            <CaseGridLayout paginatedCases={cutCases(cases, 12, 18)} />
          )}
          <div className='w-full relative mt-10'>
            {(page + 1) * 6 < cases.length && (
              <LoadMoreIcon
                className={` ${
                  page >= 24 ? 'opacity-10 cursor-auto' : 'cursor-pointer'
                } absolute left-0 right-0 mx-auto mt-3`}
                onClick={() => setPage(page + 1)}
              />
            )}
          </div>
        </>
      ) : (
        <p>{casesGrid.notFound[locale]}</p>
      )}
    </Container>
  );
};

export default CasesGrid;

CasesGrid.propTypes = {
  cardsData: PropTypes.array,
  location: PropTypes.object
};
