import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

import Container from '../../components/containers/Container';
import TwoColumnLayout from '../../components/containers/TwoColumnLayout';
import ContactOverlayText from './ContactOverlayText';
import ContactOverlayForm from './ContactOverlayForm';
import { fireEvent } from '../../utils/helpers';
import { BodyLockContext } from '../../components/contexts/BodyLockContext';

const ScrollContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CloseIcon = styled.div`
  transform: rotate(45deg);
  right: 87px;

  @media (max-width: 550px) {
    right: 20px;
  }
  & > div {
    &:after {
      background: white;
      content: '';
      height: 0.125rem;
      left: 5px;
      position: absolute;
      top: 18px;
      width: 2rem;
    }
  }
`;

const ContactOverlay = ({ content, open, root, endpoint, ...props }) => {
  const [, setBodyLock] = useContext(BodyLockContext);
  const { className = '' } = props;
  const {
    headline,
    redactor,
    imprintAnniversary,
    linkName,
    linkAddress,
    plainText
  } = content[0];
  const formFields = content[1].contactFormField;
  const location = useLocation();

  let imprintText = location.pathname.includes('anniversary')
    ? imprintAnniversary
    : redactor;

  useEffect(() => {
    if (open) {
      setBodyLock(true);
    } else setBodyLock(false);
  }, [open]);

  return (
    <div className={className}>
      <Container
        fullWidth={true}
        className={
          'xxs:h-full xs:h-screen relative overflow-hidden w-screen z-50 ' +
          (root === 'marbet' ? 'bg-highlight ' : 'bg-primary')
        }
      >
        <div className='flex flex-1 flex-row-reverse mt-6 md:mt-8'>
          <div
            className='cursor-pointer'
            style={{ zIndex: 60 }}
            onClick={() => fireEvent('contact-overlay', false)}
          >
            <CloseIcon className='h-10 w-10 absolute flex justify-center items-center'>
              <div className='bg-white h-8 w-0.5' />
            </CloseIcon>
          </div>
        </div>
        <ScrollContainer className='h-full w-full relative overflow-y-scroll pt-24 xs:pt-32 sm:pt-16 flex justify-center items-center '>
          <TwoColumnLayout
            contentLeft={
              <ContactOverlayText headline={headline} imprint={imprintText} />
            }
            contentRight={
              <ContactOverlayForm
                formFields={formFields}
                requiredText={plainText}
                btnName={linkName}
                btnLink={linkAddress}
                root={root}
                endpoint={endpoint}
              />
            }
          />
        </ScrollContainer>
      </Container>
    </div>
  );
};

ContactOverlay.propTypes = {
  content: PropTypes.array,
  open: PropTypes.bool
};

export default ContactOverlay;
