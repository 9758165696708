import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CardContainer = styled.li`
  height: 610px;

  width: 610px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  @media (max-width: 768px) {
    height: 480px;
  }
`;

const SliderCard = ({ data, slideNumber, index, accentColor }) => {
  const { quoteImage, quote, title, role } = data;

  return (
    <CardContainer
      className={
        "flex flex-col items-center justify-around px-10 transition duration-500 ease-in-out " +
        (accentColor ||
          (slideNumber === index ? "bg-highlight" : "bg-transparent"))
      }
    >
      <img
        className="rounded-full h-20 w-20"
        src={
          quoteImage[0]?.url ||
          "https://review.corporate.craft.mar.exu.dev/uploads/img.png"
        }
        alt={title}
      />
      <h6
        style={{ color: "#d9d9d9" }}
        className="my-8 font-bold text-base text-highlight md:text-h7 text-center whitespace-pre-wrap xl:w-8/12 transition duration-500 ease-in-out"
      >
        {quote}
      </h6>
      <div>
        <p className="text-center font-bold text-gray-g0">{title}</p>
        <p className="text-center text-gray-g0">{role}</p>
      </div>
    </CardContainer>
  );
};

export default SliderCard;

SliderCard.propTypes = {
  data: PropTypes.object,
};
