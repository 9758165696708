exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-[slug]-js": () => import("./../../../src/pages/preview/[slug].js" /* webpackChunkName: "component---src-pages-preview-[slug]-js" */),
  "component---src-pages-preview-career-jobs-[slug]-js": () => import("./../../../src/pages/preview/career/jobs/[slug].js" /* webpackChunkName: "component---src-pages-preview-career-jobs-[slug]-js" */),
  "component---src-pages-preview-cases-[slug]-js": () => import("./../../../src/pages/preview/cases/[slug].js" /* webpackChunkName: "component---src-pages-preview-cases-[slug]-js" */),
  "component---src-pages-preview-news-[slug]-js": () => import("./../../../src/pages/preview/news/[slug].js" /* webpackChunkName: "component---src-pages-preview-news-[slug]-js" */),
  "component---src-templates-case-template-case-template-js": () => import("./../../../src/templates/case-template/case-template.js" /* webpackChunkName: "component---src-templates-case-template-case-template-js" */),
  "component---src-templates-holder-js": () => import("./../../../src/templates/holder.js" /* webpackChunkName: "component---src-templates-holder-js" */),
  "component---src-templates-job-template-job-template-js": () => import("./../../../src/templates/job-template/job-template.js" /* webpackChunkName: "component---src-templates-job-template-job-template-js" */),
  "component---src-templates-news-template-news-template-js": () => import("./../../../src/templates/news-template/news-template.js" /* webpackChunkName: "component---src-templates-news-template-news-template-js" */),
  "component---src-templates-page-builder-page-builder-js": () => import("./../../../src/templates/page-builder/page-builder.js" /* webpackChunkName: "component---src-templates-page-builder-page-builder-js" */)
}

