import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { classes } from "../../utils/helpers"
import ValidationMessage from "../ValidationMessage"

const RadioButton = ({ modelValue, radioValue = null, label, disabled, conditions, onBlur, onFocus, onChange, ...props }) => {
  const { className, ...addonProps } = props
  const [focused, setFocused] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (radioValue) {
      if (checked && modelValue !== radioValue) setChecked(false)
    } else if (checked && modelValue !== label) {
      setChecked(false)
    }
  }, [modelValue])

  /**
   * Methods
   */
  const isInvalid = conditions.some(c => c.isInvalid)

  const onBlurAction = () => {
    if (onBlur) onBlur()

    setFocused(false)
  }

  const onFocusAction = () => {
    if (onFocus) onFocus()

    setFocused(true)
  }

  const onKeyUpAction = e => {
    if ((e.key === "Enter" || e.key === "Space") && onChange) {
      toggleValue()
    }
  }

  const toggleValue = event => {
    const isChecked = event.target.checked

    const newValue = radioValue ? (isChecked ? radioValue : "") : isChecked ? label : ""
    setChecked(isChecked)
    return onChange(newValue)
  }

  /**
   * Component
   */
  return (
    <div {...addonProps} className={className}>
      <div className={classes({ "opacity-40 pointer-events-none cursor-not-allowed": disabled }, "group relative flex items-center")}>
        <input
          className="absolute h-4 w-4 outline-none cursor-pointer opacity-0 z-10"
          type="checkbox"
          disabled={disabled}
          checked={checked}
          aria-checked={checked}
          value={label}
          onChange={toggleValue}
          onBlur={onBlurAction}
          onKeyUp={onKeyUpAction}
          onFocus={onFocusAction}
        />

        <span
          className={classes(
            { "border-danger": isInvalid, "ring-4 ring-highlight-ghost-150": focused },
            "relative flex items-center justify-center w-4 h-4 border rounded-full border-secondary group-hover:border-highlight transition-colors"
          )}
        >
          <div className={classes({ "opacity-100": checked }, "w-2.5 h-2.5 bg-highlight rounded-full opacity-0 transition-opacity")} />
        </span>
        <label className="flex-1 group-hover:text-highlight text-secondary cursor-pointer ml-2 select-none transition-colors">{label}</label>
      </div>
      {isInvalid && <ValidationMessage className="my-2" conditions={conditions} />}
    </div>
  )
}

RadioButton.propTypes = {
  /**
   * Parent value controlling the state of the radio
   */
  modelValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Identifier
   */
  label: PropTypes.string,
  /**
   * Whether the component is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * A list of conditions to be met for validty
   */
  conditions: PropTypes.array,
  /**
   * Optional function to execute when the value of the input is toggled
   */
  onChange: PropTypes.func,
  /**
   * Optional function to execute when input is blured
   */
  onBlur: PropTypes.func,
  /**
   * Optional function to execute when the input is focused
   */
  onFocus: PropTypes.func,
}

RadioButton.defaultProps = {
  modelValue: false,
  label: "",
  disabled: false,
  conditions: [],
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
}

export default RadioButton
