import React from 'react';
import PropTypes from 'prop-types';

const RevealText = ({ text, forwardedRef, children }) => {
  return (
    <span className='text-row relative overflow-hidden block'>
      {text ? (
        <span
          ref={(ref) => (forwardedRef ? forwardedRef.push(ref) : null)}
          className='block relative'
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : (
        <span
          ref={(ref) => (forwardedRef ? forwardedRef.push(ref) : null)}
          className='block relative'
        >
          {children}
        </span>
      )}
    </span>
  );
};

RevealText.propTypes = {
  text: PropTypes.string,
  forwardedRef: PropTypes.array
};

export default RevealText;
