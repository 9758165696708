import styled from "styled-components"

export const Headline = styled.h1`
  & p {
    font-size: inherit;
    line-height: inherit;

    & span {
      display: none !important;
    }
  }
`
