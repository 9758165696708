import React from 'react';
import PropTypes from 'prop-types';

function TextInput({ label, onInputChange, root }) {
  const handleChange = (event) => {
    event.preventDefault();
    onInputChange({ [label]: event.target.value });
  };

  return (
    <div>
      <label
        htmlFor={label.replace(', ', '-').toLowerCase()}
        className='sr-only'
      >
        {label}
      </label>

      <input
        type='text'
        name={label.replace(', ', '-').toLowerCase()}
        id={label.replace(', ', '-').toLowerCase()}
        className={
          ' shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-b border-white/40 h-12  placeholder-white text-white ' +
          (root === 'marbet' ? ' bg-highlight ' : 'bg-primary')
        }
        placeholder={label}
        onChange={handleChange}
      />
    </div>
  );
}

export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string,
  onInputChange: PropTypes.func
};
