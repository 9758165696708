import React from "react"
import PropTypes from "prop-types"

function LocationIcon({ color, width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        className="fill-current"
        fill={color}
        d="M15.293 13.783c-.385.529-.774 1.03-1.155 1.495 2.473.358 3.955.98 4.184 1.388-.337.602-3.384 1.667-8.322 1.667-5.036 0-8.106-1.109-8.338-1.632.159-.408 1.653-1.056 4.199-1.423-.381-.465-.77-.967-1.155-1.496C2.167 14.246 0 15.122 0 16.667 0 19.298 6.286 20 10 20c3.714 0 10-.702 10-3.333 0-1.544-2.168-2.421-4.707-2.884z"
      ></path>
      <path
        className="fill-current"
        fill={color}
        d="M9.999 0C6.72 0 3.332 2.493 3.332 6.667c0 3.833 5.453 9.439 6.075 10.066a.834.834 0 001.183 0c.622-.627 6.075-6.233 6.075-10.066C16.665 2.493 13.276 0 10 0zm0 8.333a1.666 1.666 0 11-.001-3.332 1.666 1.666 0 010 3.332z"
      ></path>
    </svg>
  )
}

LocationIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

LocationIcon.defaultProps = {
  color: "#fff",
  width: 16,
  height: 16,
}

export default LocationIcon
