import styled from "styled-components"
import ListBlock from "../../components/containers/ListBlock"

export const FooterColumn = styled.div`
  flex: 0 0 18.75%;
  position: relative;

  @media (max-width: 1027px) {
    flex:0 0 43.5%;
    margin: 1.5rem 0;
  }
`

export const FooterContainer = styled.div`
  @media (max-width: 1454px) {
    justify-content: center;
  }
  
  @media (max-width: 1454px) {
    justify-content: space-between;
  }
  
  @media (min-width: 1028px) {
    margin-left: 70px;
  }

  @media (min-width: 768px) {
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1475px;
  }
`

export const FooterBlock = styled.div`
  max-width: 235px;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 767px) {
    margin-right: unset;
    align-items: flex-start;
  }
`

export const FooterListBlock = styled(ListBlock)`
  @media (max-width: 1027px) {
    display: unset !important;
  }

  @media (max-width: 767px) {
    margin-right: unset;
  }
`
