// Dependencies
import React from "react";
import PropTypes from "prop-types";

// Components
import Container from "../../components/containers/Container";
import NewsGalleryCard from "./NewsGalleryCard";
import { Grid } from "./NewsGallery.styles";
import { useState } from "react";
import { useEffect } from "react";

const NewsGallery = ({ content }) => {
  // References & States
  const { identifier, newsGallery } = content;
  const { quote, quoteOnRight, imageGallery } = newsGallery[0];
  const [sortedMedia, setSortedMedia] = useState([]);

  if (!imageGallery.length) return null;

  const sortMedia = () => {
    if (quote[0]) {
      // let mediaArray = [quote[0], ...imageGallery]
      let mediaArray = imageGallery;
      mediaArray.splice(1, 0, quote[0]);

      // if (quoteOnRight) {
      //   mediaArray.shift()
      //   mediaArray.splice(1, 0, quote[0])
      // }

      setSortedMedia(mediaArray);
    } else setSortedMedia(imageGallery);
  };

  useEffect(() => {
    sortMedia();
  }, [imageGallery]);

  if (!sortedMedia.length) return null;

  // const sortedMedia = sortMedia
  const amount = sortedMedia.length;

  return (
    <Container id={identifier} className="py-10">
      <Grid>
        <NewsGalleryCard
          media={sortedMedia[0]}
          className={amount < 4 || amount === 2 ? "vertical-left" : ""}
          vertical={amount < 4 || amount === 2}
          stretch={amount === 3}
        />
        {sortedMedia[1] && (
          <NewsGalleryCard
            media={sortedMedia[1]}
            className={amount === 2 ? "vertical-right" : ""}
            vertical={amount === 2}
          />
        )}
        {sortedMedia[2] && <NewsGalleryCard media={sortedMedia[2]} />}
        {sortedMedia[3] && <NewsGalleryCard media={sortedMedia[3]} />}
      </Grid>
    </Container>
  );
};

NewsGallery.propTypes = {
  content: PropTypes.object,
};

export default NewsGallery;
