// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// Components
import { OfficeCard } from '../../components/NewsCards';

// Helpers
import Container from '../../components/containers/Container';

const OfficesGrid = ({ content }) => {
  const { officesGrid } = content;

  console.log({ content });

  return (
    <Container className='pt-2 md:pt-10 xl:pt-24'>
      <div className='flex-grow space-y-16 lg:space-y-0 lg:grid grid-cols-3 lg:grid-rows-2 lg:gap-x-8 gap-y-20'>
        {officesGrid.map((office, index) => {
          return <OfficeCard key={index} office={office} />;
        })}
      </div>
    </Container>
  );
};

OfficesGrid.propTypes = {
  offices: PropTypes.object
};

export default OfficesGrid;
