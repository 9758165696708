import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
// import styled from "styled-components"
import gsap from 'gsap';

// Components
import PlusIcon from '../../components/icons/PlusIcon';
import MinusIcon from '../../components/icons/MinusIcon';
import LinkChoice from '../../components/LinkChoice';

// Helpers
// import { useWindowSize } from "../../components/hooks/useWindowSize"

const AccordionItem = ({
  header,
  links,
  selectedItem,
  index,
  close,
  toggleContent,
  ...props
}) => {
  const { className, ...addonProps } = props;
  const [active, setActive] = useState(false);
  const contentRef = useRef();
  const headerRef = useRef();
  // const width = useWindowSize().width

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index;
    setActive(newValue === index);
    toggleContent(newValue);
  };

  const onKeyUpAction = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') toggleItem();
  };
  const onKeyDownAction = (e) => {
    if (e.key === 'Escape') {
      toggleContent(null);
      setActive(false);
    }
  };

  const animate = useCallback(() => {
    const height = active ? 'auto' : 0;
    const opacity = active ? 1 : 0;
    gsap.to(contentRef.current, { height, opacity, duration: 0.3 });
  }, [active, contentRef]);

  /** Run on component mount */
  useEffect(() => {
    if (active) animate();
  }, []);

  /** Run whenever the item is updated */
  useEffect(() => {
    animate();
  }, [active, animate]);

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false);
  }, [selectedItem]);

  /**
   * Component
   */
  return (
    <li className={className} {...addonProps}>
      <div
        ref={headerRef}
        className='font-semibold font-serif italic text-white text-h7 md:text-h5 border-b border-b-white cursor-pointer flex items-center py-5 focus:outline-none'
        role='button'
        tabIndex='0'
        aria-haspopup='listbox'
        aria-expanded={active ? 'true' : 'false'}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <span className='w-full pr-4'>{header}</span>
        {active ? (
          <MinusIcon width={24} height={24} />
        ) : (
          <PlusIcon width={24} height={24} />
        )}
      </div>
      <div
        ref={contentRef}
        className='h-0 opacity-0 overflow-hidden'
        role='listbox'
        onClick={close ? () => close() : null}
      >
        <div className='px-4 pt-5'>
          {links.map((link, index) => {
            return (
              <LinkChoice
                key={link.linkName + index}
                to={link.linkAddress}
                className='flex text-white items-center mb-5 lg:mb-12'
              >
                <p className='text-p2 lg:text-n1 lg:font-bold'>
                  {link.linkName}
                </p>
              </LinkChoice>
            );
          })}
        </div>
      </div>
    </li>
  );
};

AccordionItem.propTypes = {
  /**
   * Item header's text
   */
  header: PropTypes.string.isRequired,
  /**
   * Item list of links
   */
  links: PropTypes.array.isRequired,
  /**
   * The currently selected item of the accordeon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
  close: PropTypes.func
};

AccordionItem.defaultProps = {
  selectedItem: null
};

export default AccordionItem;
