import styled from 'styled-components'
import Image from '../../components/Image'

export const Grid = styled.div`
  display: grid;
  width: 100%;
  margin: 40px 0px;
  gap: 40px 40px;
  grid-template-areas:
    'vertical-left vertical-right'
    'vertical-left vertical-right';

  & .vertical-left {
    grid-area: vertical-left;
  }
  & .vertical-right {
    grid-area: vertical-right;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`

export const GridMediaWrapper = styled.div`
  position: relative;
  aspect-ratio: ${({ vertical, stretch }) =>
    vertical ? (stretch ? 'auto' : '3 / 4') : '3 / 2'};
  border-radius: 0.25rem;
  overflow: hidden;
`

export const GridQuote = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

export const GridImg = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
  object-position: center;
`

export const GridVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
  object-position: center;
`
