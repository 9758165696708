import styled from 'styled-components';

export const Slideshow = styled.div`
  & .slide {
    margin: 0;
  }

  & .slide,
  & .slide__img-wrap,
  & .slide__img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & .slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .slide {
    opacity: 0;
    pointer-events: none;
  }

  & .slide--current {
    opacity: 1;
    pointer-events: auto;
  }

  & .slide__img-wrap {
    will-change: transform;
    overflow: hidden;
  }

  & .slide__img {
    background-size: cover;
    will-change: transform;
    -webkit-backspace-visibility: hidden;
  }

  & .slide__caption {
    position: relative;
    cursor: default;
    width: 100%;
    max-width: 861px;
  }

  .text-row {
    position: relative;
    overflow: hidden;
    display: block;
    /* white-space: nowrap; */
  }

  .text-row > span {
    display: block;
    position: relative;
  }
`;
export const clipPath = {
  initial: 'circle(100% at 70% 50%)',
  final: 'circle(15% at 70% 50%)',
  hover: 'circle(20% at 30% 50%)'
};

export const clipPathMobile = {
  initial: 'circle(100% at 70% 65%)',
  final: 'circle(15% at 70% 65%)',
  hover: 'circle(20% at 30% 65%)'
};
