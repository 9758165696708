import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  FeaturedGridCard,
  StandardNewsCard
} from '../../../components/NewsCards';

const Grid = styled.div`
  ${({ number }) =>
    number % 2 === 0 &&
    css`
      display: grid;
      grid-template-columns: 48% 48%;
      grid-template-rows: 364px 364px 364px 364px;
      gap: 0px 60px;
      grid-template-areas:
        '. .'
        'Featured .'
        'Featured .'
        '. .';
    `}

  ${({ number }) =>
    number % 2 !== 0 &&
    css`
      display: grid;
      grid-template-columns: 48% 48%;
      grid-template-rows: 364px 364px 364px 364px;
      gap: 0px 60px;
      grid-template-areas:
        '. Featured'
        '. Featured'
        '. .'
        '. .';
    `}

  & .Featured {
    grid-area: Featured;
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

const GridLayout = ({ paginatedNews, layoutNumber }) => {
  const { current: savedNumber } = useRef(layoutNumber);

  return (
    <Grid number={savedNumber}>
      <StandardNewsCard article={paginatedNews[0]} />
      {paginatedNews[1] && <StandardNewsCard article={paginatedNews[1]} />}
      {paginatedNews[2] && (
        <div className='Featured'>
          <FeaturedGridCard article={paginatedNews[2]} />
        </div>
      )}
      {paginatedNews[3] && <StandardNewsCard article={paginatedNews[3]} />}
      {paginatedNews[4] && <StandardNewsCard article={paginatedNews[4]} />}
      {paginatedNews[5] && <StandardNewsCard article={paginatedNews[5]} />}
      {paginatedNews[6] && <StandardNewsCard article={paginatedNews[6]} />}
    </Grid>
  );
};

GridLayout.propTypes = {
  paginatedNews: PropTypes.array,
  layoutNumber: PropTypes.number
};

GridLayout.defaultProps = {
  layoutNumber: 0
};

export default GridLayout;
