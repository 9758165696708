import React from 'react';
import PropTypes from 'prop-types';

function Link({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        d='M13.431 10.6332C15.4062 12.6083 15.4062 15.8096 13.431 17.7847L10.5704 20.6454C8.59528 22.6205 5.39397 22.6205 3.41884 20.6454C1.44372 18.6702 1.44372 15.4689 3.41884 13.4938L5.93159 11.0529'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5751 13.4937C8.59997 11.5185 8.59997 8.31722 10.5751 6.34209L13.4357 3.48147C15.4108 1.50634 18.6122 1.50634 20.5873 3.48147C22.5624 5.45659 22.5624 8.6579 20.5873 10.633L18.0745 13.0739'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

Link.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Link.defaultProps = {
  color: '#767171',
  width: 24,
  height: 24
};

export default Link;
