import React from "react"
import PropTypes from "prop-types"
import { useVideo } from "./hooks/useVideo"

const HeroSlideVideo = ({ video, container, visible, className }) => {
  useVideo(container, video, visible, "h-screen w-screen fixed top-0 left-0 object-cover z-0", false)

  return (
    <>
      <div className={className}></div>
    </>
  )
}

HeroSlideVideo.propTypes = {
  video: PropTypes.string,
  container: PropTypes.any,
  visible: PropTypes.bool,
}

export default HeroSlideVideo
