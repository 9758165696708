import * as scrollToElement from 'scroll-to-element';

export const parseHeadline = (string, character) => {
  let divided = string.split(character || '\\n');
  return divided;
};

export const setEventListener = (event, action, remove) => {
  if (remove) {
    window.removeEventListener(event, action);
    return;
  }

  window.addEventListener(event, action);
};

export const fireEvent = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  window.dispatchEvent(event);
};
export function classes(dynamic, classes = '') {
  return Object.entries(dynamic)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ')
    .concat(' ')
    .concat(classes);
}

export const handleScrollToElement = (id, duration, callback) => {
  if (!duration) {
    scrollToElement(id, { ease: 'in-out-sine' });
  } else {
    scrollToElement(id, {
      ease: 'in-out-sine',
      duration: duration
    });

    if (callback) setTimeout(callback, duration);
  }
};

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'de';
  }

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'de';

  switch (lang) {
    case 'en':
      return 'de';
    case 'es':
      return 'de';
    default:
      return 'de';
  }
};

export const parseDate = (date) => {
  return new Date(date).toISOString().split('T')[0].split('-').reverse().join('.');
};

export const getSiteLanguageFromLocale = (locale) => {
  switch (locale) {
    case 'de':
      return 0;
    case 'en':
      return 1;
    case 'es':
      return 2;
    default:
      return 0;
  }
};

export function removeTags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
}

export const trimEntryLink = (link) => {
  if (link.startsWith('http')) {
    let trimmedLink = link.split('/');
    return `/${trimmedLink[trimmedLink.length - 1]}`;
  } else return link;
};

export function getLanguageFromURL(search) {
  let hashes = search.slice(search.indexOf('?') + 1).split('&');
  let params = {};
  hashes.map((hash) => {
    let [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });

  return params.lang || 'en';
}

export const useHighlightText = (text) => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  const regex = /##(.*?)##/g;
  const match = text.match(regex);

  if (match && match.length) {
    const highlightedText = match[0];
    const replacement = `
      <span class="text-highlight">
        ${highlightedText}
      </span>
    `;
    return text.replace(highlightedText, replacement).replace(/##/g, '');
  }

  return text;
};
