import React, { useState, useEffect } from "react";
import Glide from "@glidejs/glide";
import { Controls } from "@glidejs/glide/dist/glide.modular.esm";
import PropTypes from "prop-types";
import useMediaQuery from "../../hooks/useMediaQuery";
import "./Slider.css";

// components
import ArrowRight from "../../components/icons/ArrowRight";

const Slider = ({
  element = "glide",
  options,
  children,
  totalCards,
  root,
  identifier,
}) => {
  const [slideNumber, setSlideNumber] = useState(1);
  const [slider, setSlider] = useState(null);
  const matches = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    if (!slider) {
      return;
    }

    slider.mount({ Controls });

    // subscribe to an event
    slider.on(["mount.after", "run"], () => {
      const currentIndex = slider.index;
      setSlideNumber(currentIndex + 1);
    });

    // cleanup when unmounting component
    return () => slider.destroy();
  }, [slider]);

  useEffect(() => {
    setSlider(new Glide(`.${element}`, options));
  }, [matches, options, element]);

  const accentBorderColor =
    root === "mGuest" && identifier === "home-slider" ? "border-primary" : "";
  const accentTextColor =
    root === "mGuest" && identifier === "home-slider" ? "text-primary" : "";

  return (
    <div className="flex flex-col">
      <div className={element}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {children.map((slide, index) => {
              return React.cloneElement(slide, {
                key: index,
                index: index,
                // slideNumber: slideNumber - 1,
                className: `${slide.props.className} glide__slide card transition duration-500 ease-in-out`,
              });
            })}
          </ul>
        </div>

        {element !== "benefitSlider" ? (
          <div
            className="flex absolute z-10 xl:top-1/2 xl:-translate-y-1/2 w-full justify-evenly xl:justify-around mt-2 xl:mt-0 items-center h-20 "
            data-glide-el="controls"
          >
            <button
              className={`flex justify-center items-center w-12 h-12 rounded-full border rotate-180 ${accentBorderColor} ${
                root === "mGuest" ? "border-white" : "border-highlight"
              }`}
              data-glide-dir="<"
            >
              <ArrowRight
                className={
                  accentTextColor ||
                  (root === "mGuest" ? "text-white" : "text-highlight")
                }
              />
            </button>
            <button
              className={`flex justify-center items-center w-12 h-12 rounded-full border ${accentBorderColor} ${
                root === "mGuest" ? "border-white" : "border-highlight"
              }`}
              data-glide-dir=">"
            >
              <ArrowRight
                className={
                  accentTextColor ||
                  (root === "mGuest" ? "text-white" : "text-highlight")
                }
              />
            </button>
          </div>
        ) : (
          <div
            className="flex items-center justify-center lg:justify-end space-x-4 cursor-pointer text-gray-g3 mt-12"
            data-glide-el="controls"
          >
            <button data-glide-dir="<">
              <ArrowRight className="rotate-180" />
            </button>
            <p className="font-bold tracking-wider text-p3 md:text-p2">
              {"0" + slideNumber + " / " + "0" + totalCards}
            </p>
            <button data-glide-dir=">">
              <ArrowRight />
            </button>
          </div>
        )}
      </div>
      {element !== "benefitSlider" && (
        <div>
          <p className="xl:hidden text-center p-10 tracking-widest font-bold text-b2 leading-3 text-gray-g1">
            {slideNumber}/{totalCards}
          </p>
        </div>
      )}
    </div>
  );
};

export default Slider;

Slider.propTypes = {
  element: PropTypes.string,
  options: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  totalCards: PropTypes.number,
};
