import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const StatTitle = styled.h3`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 16px;
  color: #afabab;

  @media (max-width: 1279px) {
    font-size: 12px;
    color: #767171;
    letter-spacing: 3px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
`;

const StatSubtitle = styled.p`
  @media (min-width: 1660px) {
    font-size: 80px;
    line-height: 96px;
  }
`;

const Stats = ({ title, subtitle, ...props }) => {
  const { className } = props;

  return (
    <div className={className + " flex flex-col"}>
      <StatTitle className="text-gray-g4">{title}</StatTitle>
      <StatSubtitle className="text-h5 xl:text-h3 text-highlight font-black">
        {subtitle}
      </StatSubtitle>
    </div>
  );
};

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Stats;
