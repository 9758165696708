import React, { useRef } from "react";
import PropTypes from "prop-types";
import Container from "../../components/containers/Container";
import Image from "../../components/Image";
import { useVideo } from "../../components/hooks/useVideo";
import ExternalVideo from "../../components/ExternalVideo";

const NewsHighlightImage = ({ content: { highlightImage }, className }) => {
  const videoContainer = useRef();

  if (highlightImage[0].mimeType.includes("video")) {
    useVideo(
      videoContainer,
      highlightImage[0].mimeType.includes("video")
        ? highlightImage[0].url
        : null,
      true,
      "",
      false,
      true
    );
  }
  return (
    <Container type="wide" fullMobile className={className}>
      <div
        ref={videoContainer}
        className="overflow-hidden w-full h-0 aspect-w-17 aspect-h-8"
      >
        {highlightImage[0].mimeType.includes("image") && (
          <Image
            simple
            src={highlightImage[0].url}
            alt="highlight-image"
            className="object-cover md:rounded w-full h-full"
          />
        )}
        {highlightImage?.[0]?.mimeType === "application/json" && (
          <ExternalVideo media={highlightImage[0]} togglePlayback />
        )}
      </div>
    </Container>
  );
};

NewsHighlightImage.propTypes = {
  content: PropTypes.shape({
    highlightImage: PropTypes.array,
  }),
  padding: PropTypes.bool,
};

NewsHighlightImage.defaultProps = {
  padding: true,
};

export default NewsHighlightImage;
