import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import gsap from "gsap";

// Components
import PlusIcon from "../../components/icons/PlusIcon";
import MinusIcon from "../../components/icons/MinusIcon";
import SpaceshipIcon from "../../components/icons/SpaceshipIcon";
import CoinIcon from "../../components/icons/CoinIcon";
import HandHeartIcon from "../../components/icons/HandHeartIcon";
import ScaleIcon from "../../components/icons/ScaleIcon";
import HeartIcon from "../../components/icons/HeartIcon";
import { useWindowSize } from "../../components/hooks/useWindowSize";
import BakerIcon from "../../components/icons/BakerIcon";
import CakeIcon from "../../components/icons/CakeIcon";
import IngredientsIcon from "../../components/icons/IngredientsIcon";
import PinIcon from "../../components/icons/PinIcon";

const AccordionContent = styled.div`
  color: #767171;
  padding: 24px;
  max-width: 1280px;
  font-size: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;

  padding-left: 64px;
  padding-right: 80px;

  @media (max-width: 1919.99px) {
    max-width: 1440px;
    margin-left: 252px;
    margin-right: unset;
    padding-left: 128px;
    padding-right: 192px;
  }

  @media (max-width: 1439.99px) {
    max-width: 1280px;
    margin-left: unset;
    padding-right: 128px;
  }

  @media (max-width: 1023.99px) {
    padding-left: 96px;
    padding-right: 48px;
    max-width: 840px;
    font-size: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: 767.99px) {
    padding-left: 48px;
    padding-right: 24px;
    max-width: 672px;
  }

  @media (max-width: 639.99px) {
    padding-left: 24px;
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style-type: square !important;
    margin-bottom: 12px !important;
    border: none;
  }
  li::marker {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;

    @media (min-width: 1024px) {
      max-width: 62rem;
      font-size: 28px;
      line-height: 41px;
    }
  }
`;

const AccordionItem = ({
  header,
  headerHover,
  body,
  icon,
  selectedItem,
  index,
  toggleContent,
  root,
  ...props
}) => {
  const { className, ...addonProps } = props;
  const [active, setActive] = useState(false);
  const [hovered, setHovered] = useState(false);
  const contentRef = useRef();
  const headerRef = useRef();
  const width = useWindowSize().width;
  const iconSize = width < 768 ? 16 : 48;

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index;
    setActive(newValue === index);
    toggleContent(newValue);
  };

  const onKeyUpAction = (e) => {
    if (e.key === "Enter" || e.key === "Space") toggleItem();
  };
  const onKeyDownAction = (e) => {
    if (e.key === "Escape") {
      toggleContent(null);
      setActive(false);
    }
  };

  const animate = useCallback(() => {
    const height = active ? "auto" : 0;
    const opacity = active ? 1 : 0;
    gsap.to(contentRef.current, { height, opacity, duration: 0.3 });
  }, [active, contentRef]);

  const getIcon = (icon) => {
    let formattedIcon = icon.toLowerCase();
    const icons = {
      baker: <BakerIcon width={iconSize} height={iconSize} />,
      cake: <CakeIcon width={iconSize} height={iconSize} />,
      coin: <CoinIcon width={iconSize} height={iconSize} />,
      "hand heart": <HandHeartIcon width={iconSize} height={iconSize} />,
      heart: <HeartIcon width={iconSize} height={iconSize} />,
      ingredients: <IngredientsIcon width={iconSize} height={iconSize} />,
      pin: <PinIcon width={iconSize} height={iconSize} />,
      scale: <ScaleIcon width={iconSize} height={iconSize} />,
      spaceship: <SpaceshipIcon width={iconSize} height={iconSize} />,
    };

    return icons[formattedIcon];
  };

  /** Run on component mount */
  useEffect(() => {
    if (active) animate();
  }, []);

  /** Run whenever the item is updated */
  useEffect(() => {
    animate();
  }, [active, animate]);

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false);
  }, [selectedItem]);

  /**
   * Component
   */
  return (
    <li className={className} {...addonProps}>
      <div
        ref={headerRef}
        className={
          "font-semibold font-serif italic  text-h7 md:text-h5 lg:text-h4 xl:text-h3 2xl:text-h2 3xl:text-h1 cursor-pointer flex items-center py-6 lg:py-20 px-6 sm:px-12 lg:px-32 2xl:px-48 group focus:outline-none transition " +
          (root === "mAway"
            ? "text-primary hover:bg-primary hover:text-white"
            : "text-highlight hover:bg-highlight hover:text-white")
        }
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {icon && (
          <div
            className={
              "flex justify-center items-center w-8 h-8 md:w-28 md:h-28 rounded-full text-white group-hover:bg-white " +
              (root === "mAway" ? "bg-primary " : "bg-highlight ") +
              (root === "marbet"
                ? "group-hover:text-highlight"
                : "group-hover:text-primary")
            }
            style={{ minWidth: 32 }}
          >
            {getIcon(icon)}
          </div>
        )}
        <span className="flex-grow px-4 md:px-10 lg:px-16">
          {active || hovered ? headerHover : header}
        </span>
        {active ? (
          <MinusIcon width={iconSize} height={iconSize} />
        ) : (
          <PlusIcon width={iconSize} height={iconSize} />
        )}
      </div>
      <div
        ref={contentRef}
        className="h-0 opacity-0 overflow-hidden"
        role="listbox"
      >
        <AccordionContent dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </li>
  );
};

AccordionItem.propTypes = {
  /**
   * Item header's text
   */
  header: PropTypes.string.isRequired,
  /**
   * Item header's text when hovered and active
   */
  headerHover: PropTypes.string.isRequired,
  /**
   * Item body's text
   */
  body: PropTypes.string.isRequired,
  /**
   * Item icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * The currently selected item of the accordeon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
};

AccordionItem.defaultProps = {
  selectedItem: null,
};

export default AccordionItem;
